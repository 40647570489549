const CSS_VARIABLE_PREFIX = '--lf-';

declare global {
  interface LfCssVariables {
    'sidebar-width': string;
    'body-padding': string;
    'content-padding-y': string;
    'progress-card-height': string;
    'conversation-list-width': string;
  }
}

export const setCssVariable = <K extends keyof LfCssVariables>(variable: K, value: string) => {
  document.documentElement.style.setProperty(`${CSS_VARIABLE_PREFIX}${String(variable)}`, value);
};

export const getCssVariable = <K extends keyof LfCssVariables>(variable: K) => {
  return parseInt(
    getComputedStyle(document.documentElement)
      .getPropertyValue(`${CSS_VARIABLE_PREFIX}${String(variable)}`)
      .trim(),
    10,
  );
};
