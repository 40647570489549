import { useTranslation } from 'react-i18next';
import { createFileRoute } from '@tanstack/react-router';

import { Divider } from '@legalfly/ui/divider';
import { Text } from '@legalfly/ui/text';
import { Content } from 'components/common/content/Content';
import { BookAMeeting } from 'components/store/bookAMeeting';
import type { MediaItem } from 'components/store/Carousel';
import { Carousel } from 'components/store/Carousel';
import { CustomAgentCard } from 'components/store/CustomAgentCard';
import { MostPopularAgentCard } from 'components/store/MostPopularAgentCard';
import { routeRedirectWithFeatureFlag } from 'core/analytics';
import { useCustomStoreAgents, useStoreAgents } from 'core/modules/agents';

export const Route = createFileRoute('/_auth/_layout/store/')({
  beforeLoad: async ({ context }) => routeRedirectWithFeatureFlag(context.queryClient, 'store'),
  component: RouteComponent,
});
const banners: MediaItem[] = [
  {
    type: 'image',
    src: '/store/store-banner-word.png',
    alt: 'Word Addin',
    cta: {
      title: 'store.carousel.wordAddin.title',
      text: 'store.carousel.wordAddin.description',
      labelButton: 'store.carousel.wordAddin.labelButton',
      link: 'https://appsource.microsoft.com/nl-nl/product/office/wa200007298',
    },
  },
];

function RouteComponent() {
  const { t } = useTranslation();
  const { stores } = useStoreAgents();
  const { customStoreAgents } = useCustomStoreAgents();

  return (
    <Content className='flex-1 bg-fill-strongest'>
      <Carousel media={banners} />
      <Text variant='subheadingLight' className='mb-8'>
        {t('store.mostPopularAgents')}
      </Text>
      <div className='mb-3 grid auto-rows-[250px] grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
        {stores.map((storeAgent, index) => (
          <MostPopularAgentCard key={index} agent={storeAgent} />
        ))}
      </div>
      <Divider variant='weak' className='my-12' />
      {customStoreAgents?.length ? (
        <>
          <Text variant='subheadingLight' className='mb-3'>
            {t('store.customAgentTitle')}
          </Text>
          <Text variant='bodyLight' className='mb-8'>
            {t('store.customAgentDescription')}
          </Text>
          <div className='mb-16 grid grid-cols-1 gap-x-12  gap-y-4 @container  @lg:grid-cols-1 sm:grid-cols-1 md:grid-cols-1  xl:grid-cols-2 2xl:grid-cols-3'>
            {customStoreAgents.map((customAgent, index) => (
              <div key={index} className='flex flex-col gap-6'>
                <CustomAgentCard {...customAgent} />
                <Divider variant='weak' />
              </div>
            ))}
          </div>
        </>
      ) : null}

      <BookAMeeting />
    </Content>
  );
}
