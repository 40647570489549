import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import type { ApiUserSettings, SettingsApi } from '@legalfly/api/settings';
import { merge } from '@legalfly/utils/merge';

import { settingsQueryOptions } from './settingsQueryOptions';

export const createSettingsModule = ({ settingsApi }: { settingsApi: SettingsApi }) => {
  const queryOptions = settingsQueryOptions({ settingsApi });

  const useCurrentUserSettings = <TData = ApiUserSettings>({
    select,
  }: { select?: (data: ApiUserSettings) => TData } = {}) => {
    const { data, isLoading, error } = useSuspenseQuery(
      queryOptions.currentUserSettings({ select }),
    );

    return {
      data,
      isLoading,
      error,
    };
  };

  const useUpdateCurrentUserSettings = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['userSettings', 'update'],
      mutationFn: settingsApi.updateCurrentUserSettings,
      onSuccess: (newUserSettings) => {
        queryClient.setQueryData(queryOptions.currentUserSettings().queryKey, (userSettings) => {
          return merge(userSettings, newUserSettings) as ApiUserSettings;
        });
      },
    });

    return {
      updateCurrentUserSettings: mutateAsync,
      isLoading: isPending,
    };
  };

  return {
    useCurrentUserSettings,
    useUpdateCurrentUserSettings,
    settingsQueryOptions: queryOptions,
  };
};
