import { useNavigate } from '@tanstack/react-router';

import type { ApiNotification } from '@legalfly/api/notifications';

import { useDownloadZip } from '../documents/helpers/useDownloadZip';
import { useDeleteNotification } from '.';

export const useHandleNotificationClick = () => {
  const navigate = useNavigate();
  const { handleZipNotification, isDownloading } = useHandleZipNotification();

  const handleNotificationClick = (notification: ApiNotification) => {
    const { type, metadata } = notification;
    switch (type) {
      case 'FILE_UPLOADED':
        navigate({
          to: '/anonymisation/file/$uuid',
          params: { uuid: metadata.entityId },
        });
        break;

      case 'MULTI_REVIEW_COMPLETED':
        navigate({
          to: '/multi-review/$uuid',
          params: { uuid: metadata.entityId },
        });
        break;

      case 'FILE_UPLOAD_FAILED': {
        const path = metadata.entityId
          ? {
              to: '/documents/folder/$uuid',
              params: { uuid: metadata.entityId },
            }
          : { to: '/documents/folder' };

        navigate(path);
        break;
      }

      case 'QUEUE_ERROR':
        if (metadata.entityType === 'file') {
          navigate({ to: '/documents/folder' });
        }
        break;

      case 'ZIP_CREATION_FAILED':
        return undefined;

      case 'ZIP_CREATION_FINISHED':
        return handleZipNotification(notification);

      case 'TAGGED_IN_COMMENT':
      case 'NEW_COMMENT': {
        const params = { uuid: metadata.parentUuid };
        const search = { commentUuid: metadata.entityUuid };

        switch (metadata.parentType) {
          case 'review':
            navigate({
              to: '/review/$uuid',
              params,
              search,
              mask: {
                to: '/review/$uuid',
                params,
              },
            });
            break;
          case 'draft':
            navigate({
              to: '/drafting/drafts/$uuid',
              params,
              search,
              mask: {
                to: '/drafting/drafts/$uuid',
                params,
              },
            });
            break;
          case 'multiReview':
            navigate({
              to: '/multi-review/$uuid',
              params,
              search,
              mask: {
                to: '/multi-review/$uuid',
                params,
              },
            });
            break;
        }
        break;
      }
      case 'ENTITY_SHARED': {
        const params = { uuid: metadata.uuid };
        switch (metadata.type) {
          case 'review':
            navigate({ to: '/review/$uuid', params });
            break;
          case 'draft':
            navigate({ to: '/drafting/drafts/$uuid', params });
            break;
          case 'multiReview':
            navigate({ to: '/multi-review/$uuid', params });
            break;
        }
        break;
      }
      case 'FILE_TRANSLATED':
        navigate({ to: '/translate/history' });
        break;
      case 'FILE_TRANSLATION_FAILED':
        navigate({ to: '/translate/files' });
        break;
      default:
        navigate({ to: '/' });
    }
    return undefined;
  };

  return { handleNotificationClick, isLoading: isDownloading };
};

const useHandleZipNotification = () => {
  const { downloadZip, isDownloading } = useDownloadZip();
  const { deleteNotification } = useDeleteNotification();

  const handleZipNotification = async (notification: ApiNotification) => {
    if (notification.type === 'ZIP_CREATION_FINISHED') {
      await downloadZip({ url: notification.metadata.url, filename: 'documents-archive.zip' });
      deleteNotification(notification.uuid);
    }
  };

  return {
    handleZipNotification,
    isDownloading,
  };
};
