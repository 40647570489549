import { createSettingsModule } from '@legalfly/modules/settings';

import { settingsApi } from '../../di';

export const { useCurrentUserSettings, useUpdateCurrentUserSettings, settingsQueryOptions } =
  createSettingsModule({
    settingsApi,
  });

export * from './toasts';
