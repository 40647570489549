import { useTranslation } from 'react-i18next';
import { createFileRoute } from '@tanstack/react-router';

import { Content } from 'components/common/content/Content';
import { ContentHeader } from 'components/common/content/ContentHeader';
import { LegalRadarEntriesList } from 'components/legal-radar/LegalRadarEntriesList';
import { routeRedirectWithFeatureFlag } from 'core/analytics';

export const Route = createFileRoute('/_auth/_layout/legal-radar/')({
  beforeLoad: async ({ context }) =>
    routeRedirectWithFeatureFlag(context.queryClient, 'legal-radar'),
  component: RouteComponent,
});

function RouteComponent() {
  const { t } = useTranslation();

  return (
    <Content>
      <ContentHeader>
        <ContentHeader.Title>{t('legalRadar.title')}</ContentHeader.Title>
        <ContentHeader.SubTitle>{t('legalRadar.subtitle')}</ContentHeader.SubTitle>
      </ContentHeader>

      <LegalRadarEntriesList />
    </Content>
  );
}
