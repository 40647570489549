import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  allowedDocumentMediaTypes,
  type ApiDocument,
  DocumentOrigin,
} from '@legalfly/api/documents';
import type { FetcherError } from '@legalfly/api/fetcher';
import type { ApiKnowledge } from '@legalfly/api/knowledge';
import { useDialog } from '@legalfly/ui/dialog';
import { withToasts } from '@legalfly/ui/toast';
import { FileTypeErrorDialog } from 'components/common/errors/FileTypeErrorDialog';
import { useCreateKnowledgeFile } from 'core/modules/knowledge';

import { useCreateFile } from '..';
import { documentsToasts } from '../toasts';

type DocumentOriginValue = `${DocumentOrigin}`;

export type CreateFileUploadResult =
  | { success: true; document: ApiDocument; knowledgeUuid?: ApiKnowledge['uuid'] }
  | { success: false; error: FetcherError };

const dialogErrorPriority: Record<string, number> = {
  INVALID_FILE: 1,
  // add more errors here as needed
};

const getDialogErrorPriority = (code: string) => dialogErrorPriority[code] || 999;

export const useHandleFileUploadResults = () => {
  const dialog = useDialog();
  const { t } = useTranslation();

  return (uploadResults: CreateFileUploadResult[]) => {
    const errors = uploadResults
      .filter((result) => !result.success)
      .filter((result) => result.error);

    if (errors.length > 0) {
      const highestPriorityError = errors.reduce((prev, curr) =>
        getDialogErrorPriority(curr.error.response.code) <
        getDialogErrorPriority(prev.error.response.code)
          ? curr
          : prev,
      );
      if (highestPriorityError.error.response.code === 'INVALID_FILE') {
        dialog.open(<FileTypeErrorDialog onClose={dialog.close} actionText={t('action.close')} />);
      }
    }

    const documents = uploadResults
      .filter((result) => result.success)
      .map(({ document }) => document);
    const knowledgeUuids = uploadResults
      .filter((result) => result.success)
      .map(({ knowledgeUuid }) => knowledgeUuid);

    return { documents, knowledgeUuids };
  };
};

export const useCreateFiles = (origin: DocumentOriginValue) => {
  const { createFile } =
    // The origin will not dynamically change
    // eslint-disable-next-line react-hooks/rules-of-hooks
    origin === DocumentOrigin.KNOWLEDGE ? useCreateKnowledgeFile() : useCreateFile();
  const dialog = useDialog();
  const [isCreatingFiles, setIsCreatingFiles] = useState(false);
  const [numberOfFilesToUpload, setNumberOfFilesToUpload] = useState(0);
  const { t } = useTranslation();

  const handleFileUploadResults = useHandleFileUploadResults();

  const createFiles = async ({
    uuid,
    files,
    anonymized = true,
  }: {
    uuid: ApiDocument['uuid'] | undefined;
    files: File[];
    anonymized?: boolean;
  }) => {
    if (!files.some((file) => allowedDocumentMediaTypes.includes(file.type))) {
      dialog.open(<FileTypeErrorDialog onClose={dialog.close} actionText={t('action.close')} />);
      return { documents: [], knowledgeUuids: [] };
    }

    setNumberOfFilesToUpload(files.length);
    setIsCreatingFiles(true);

    const uploadResults = await Promise.all<CreateFileUploadResult>(
      files.map(async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('origin', origin);
        formData.append('anonymized', String(anonymized));

        try {
          const { document, knowledgeUuid } = await withToasts(
            createFile({ uuid, file: formData }),
          )(documentsToasts.uploadFile(file.name));
          return { success: true, document, knowledgeUuid };
        } catch (error) {
          return { success: false, error: error as FetcherError };
        }
      }),
    );

    setIsCreatingFiles(false);
    return handleFileUploadResults(uploadResults);
  };

  return {
    createFiles,
    numberOfFilesToUpload,
    isCreatingFiles,
  };
};
