import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { createFileRoute } from '@tanstack/react-router';
import { useKeywordFilter } from 'common/hooks/useKeywordFilter';
import { useSortableTable } from 'common/hooks/useSortableTable';
import { createSortableRoute } from 'common/utils/routeUtils';

import type { ApiTemplate, DraftingSortField } from '@legalfly/api/drafting';
import { DEFAULT_TEMPLATE_SORT } from '@legalfly/api/drafting';
import { trackEvent } from '@legalfly/reporting/tracking';
import { SearchInputForm } from '@legalfly/ui/form';
import { SidePaneProvider } from '@legalfly/ui/sidePane';
import { withToasts } from '@legalfly/ui/toast';
import { Content } from 'components/common/content/Content';
import { ContentHeader } from 'components/common/content/ContentHeader';
import { Instructions } from 'components/drafting/Instructions';
import { CreateDraftingTemplateDialog } from 'components/drafting/templates/CreateDraftingTemplateDialog';
import { TemplatesList } from 'components/drafting/templates/TemplatesList';
import { draftingQueryOptions, useTemplates } from 'core/modules/drafting';
import {
  settingsToasts,
  useCurrentUserSettings,
  useUpdateCurrentUserSettings,
} from 'core/modules/settings';
import { useCurrentUser } from 'core/modules/users';

import { DraftingTabs } from '../-tabs/DraftingTabs';

const { validateSearch, loaderDeps } = createSortableRoute<DraftingSortField>({
  defaultSort: DEFAULT_TEMPLATE_SORT,
});

export const Route = createFileRoute('/_auth/_layout/drafting/templates/')({
  component: DraftingTemplatesRoute,
  validateSearch,
  loaderDeps,
  loader({ context, deps: { sortBy, sortOrder } }) {
    return context.queryClient.ensureQueryData(
      draftingQueryOptions.templates({ sortBy, sortOrder }),
    );
  },
});

function DraftingTemplatesRoute() {
  const { t } = useTranslation();
  const navigate = Route.useNavigate();
  const sorting = Route.useSearch();

  const { currentUser } = useCurrentUser();
  const { data: userSettings } = useCurrentUserSettings();
  const { updateCurrentUserSettings } = useUpdateCurrentUserSettings();

  const { currentSorting, onSort } = useSortableTable<DraftingSortField>({
    sorting,
    defaultSortField: DEFAULT_TEMPLATE_SORT.sortBy,
    navigate,
  });

  const { templates } = useTemplates({
    sortBy: sorting.sortBy,
    sortOrder: sorting.sortOrder,
  });

  const { filteredItems: filteredTemplates, setKeyword } = useKeywordFilter(templates, 'title');

  const handleSelectTemplate = (template: ApiTemplate) => {
    navigate({
      to: '/drafting/templates/$uuid',
      params: { uuid: template.uuid },
    });
  };

  const handleUpdateInstructions = useCallback(
    async (instructions: string[]) => {
      trackEvent({
        action: 'click',
        category: 'templates',
        label: 'updateGeneralInstructions',
      });

      await withToasts(updateCurrentUserSettings({ draftingInstructions: instructions }))(
        settingsToasts.updateUserSettings(),
      );
    },
    [updateCurrentUserSettings],
  );

  return (
    <SidePaneProvider className='h-full'>
      <div className='flex flex-1 flex-col gap-3'>
        <DraftingTabs />
        <Content>
          <ContentHeader>
            <ContentHeader.Title>{t('drafting.templates.title')}</ContentHeader.Title>
            <ContentHeader.SubTitle>{t('drafting.templates.subtitle')}</ContentHeader.SubTitle>
            <ContentHeader.Actions>
              <SearchInputForm
                placeholder={t('label.search')}
                onKeywordChange={setKeyword}
                onBlur={() =>
                  trackEvent({
                    action: 'blur',
                    category: 'templates',
                    label: 'search',
                  })
                }
                disabled={!templates.length}
              />
              <CreateDraftingTemplateDialog />
              <Instructions
                entity={currentUser}
                type='user'
                title={t('drafting.templates.instructions.user.title')}
                defaultInstructions={userSettings.draftingInstructions}
                onSave={handleUpdateInstructions}
              />
            </ContentHeader.Actions>
          </ContentHeader>
          <TemplatesList
            hasAvailableTemplates={templates.length > 0}
            filteredTemplates={filteredTemplates}
            onSelectTemplate={handleSelectTemplate}
            sorting={currentSorting}
            onSort={onSort}
          />
        </Content>
      </div>
    </SidePaneProvider>
  );
}
