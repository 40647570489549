import { legalFlyConfig } from '@legalfly/config';

import type { ParamsOf, QueryOf, ResponseOf } from '../client';
import { createClient } from '../client';
import type { Fetcher } from '../fetcher';

const api = legalFlyConfig.api.paths;

export const createLegalRadarApi = (fetcher: Fetcher) => {
  const client = createClient(fetcher);

  const getLegalRadar = ({
    params,
    query,
  }: {
    params: ParamsOf<'legal-radar/:jurisdiction/get'>;
    query: QueryOf<'legal-radar/:jurisdiction/get'>;
  }) => {
    return client(api.legalRadar.list, { method: 'GET', params, query });
  };

  return {
    getLegalRadar,
  };
};

export type LegalRadarApi = ReturnType<typeof createLegalRadarApi>;
export type ApiLegalRadarEntry = ResponseOf<'legal-radar/:jurisdiction/get'>[number];
