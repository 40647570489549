import i18n from 'i18next';

import type { FetcherError } from '@legalfly/api/fetcher';
import { createToasts } from '@legalfly/ui/toast';

import { CreateUserErrorToastAction } from './toastActions';

export const userToasts = createToasts({
  updateAvatar: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.updateAvatar.description'),
    }),
  }),
  updateUser: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.updateUser.description'),
    }),
  }),
  createUser: () => ({
    errorToast: (err: FetcherError) => {
      switch (err.response.code) {
        case 'INSUFFICIENT_SEATS':
          return {
            title: i18n.t('toast.error.insufficientSeats.title'),
            description: i18n.t('toast.error.insufficientSeats.description'),
            action: <CreateUserErrorToastAction />,
          };
        default:
          return {
            title: i18n.t('toast.error.title'),
            description: i18n.t('toast.error.createUser.description'),
          };
      }
    },
  }),
  deleteUser: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.deleteUser.description'),
    }),
  }),
  activateUser: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.activateUser.description'),
    }),
  }),
  deactivateUser: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.deactivateUser.description'),
    }),
  }),
  updateCurrentUserSettings: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.updateCurrentUserSettings.description'),
    }),
  }),
});
