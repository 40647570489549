import { useEffect } from 'react';

import { createAgentsApi } from '@legalfly/api/agents';
import { createAnonymizationApi } from '@legalfly/api/anonymization';
import { createCollaborationApi } from '@legalfly/api/collaboration';
import { createCommentsApi } from '@legalfly/api/comments';
import { createConfigApi } from '@legalfly/api/config';
import {
  createConversationsAgentApi,
  createConversationsDiscoveryApi,
  createConversationsDraftApi,
  createConversationsReviewApi,
} from '@legalfly/api/conversations';
import { createDocumentsApi } from '@legalfly/api/documents';
import { createDraftingApi } from '@legalfly/api/drafting';
import { createFavoritesApi } from '@legalfly/api/favorites';
import { createFeedbackApi } from '@legalfly/api/feedback';
import { Fetcher } from '@legalfly/api/fetcher';
import { createKnowledgeApi } from '@legalfly/api/knowledge';
import { createLegalRadarApi } from '@legalfly/api/legal-radar';
import { createMultiReviewsApi } from '@legalfly/api/multi-reviews';
import { createNotificationsApi } from '@legalfly/api/notifications';
import { createPlaybooksApi } from '@legalfly/api/playbooks';
import { createReportsApi } from '@legalfly/api/reports';
import { createReviewsApi } from '@legalfly/api/reviews';
import { createSettingsApi } from '@legalfly/api/settings';
import { createSharePointApi } from '@legalfly/api/sharePoint/SharePointApi';
import { createStatisticsApi } from '@legalfly/api/statistics';
import { createTagsApi } from '@legalfly/api/tags';
import { createTranslateApi } from '@legalfly/api/translate';
import { createUsersApi } from '@legalfly/api/users';
import { createWebSocketApi } from '@legalfly/api/websocket';
import { legalFlyConfig } from '@legalfly/config';
import { env } from 'core/env';

import { useAuth } from './auth';

export const fetcher = new Fetcher(
  `${legalFlyConfig.api.url}${legalFlyConfig.api.port === 80 ? '' : `:${legalFlyConfig.api.port}`}`,
  {
    afterResponse: () => {
      // console.log('Api request', request.url, 'responded with status', response.status);
    },
    beforeError: (error) => {
      console.log(error.response);
    },
  },
);

export const useSetFetcherToken = () => {
  const { getAccessTokenSilently } = useAuth();

  useEffect(() => {
    const setAuthHeader = async (request: Request) => {
      const authId = env.auth0.authId;
      if (authId && env.isLocal) {
        request.headers.set('Authorization', `Bearer TEST_USER_${authId}`);
        return request;
      }
      const token = await getAccessTokenSilently();
      request.headers.set('Authorization', `Bearer ${token}`);
      return request;
    };

    fetcher.hooks.beforeRequest = setAuthHeader;
  }, [getAccessTokenSilently]);
};

export const documentsApi = createDocumentsApi(fetcher);
export const usersApi = createUsersApi(fetcher);
export const draftConversationsApi = createConversationsDraftApi(fetcher);
export const reviewConversationsApi = createConversationsReviewApi(fetcher);
export const agentConversationsApi = createConversationsAgentApi(fetcher);
export const discoveryConversationsApi = createConversationsDiscoveryApi(fetcher);
export const websocketApi = createWebSocketApi(fetcher);
export const notificationsApi = createNotificationsApi(fetcher);
export const configApi = createConfigApi(fetcher);
export const playbooksApi = createPlaybooksApi(fetcher);
export const reviewsApi = createReviewsApi(fetcher);
export const draftingApi = createDraftingApi(fetcher);
export const multiReviewsApi = createMultiReviewsApi(fetcher);
export const settingsApi = createSettingsApi(fetcher);
export const feedbackApi = createFeedbackApi(fetcher);
export const anonymizationApi = createAnonymizationApi(fetcher);
export const statisticsApi = createStatisticsApi(fetcher);
export const agentsApi = createAgentsApi(fetcher);
export const favoritesApi = createFavoritesApi(fetcher);
export const collaborationApi = createCollaborationApi(fetcher);
export const commentsApi = createCommentsApi(fetcher);
export const translateApi = createTranslateApi(fetcher);
export const sharePointApi = createSharePointApi(fetcher);
export const reportsApi = createReportsApi(fetcher);
export const knowledgeApi = createKnowledgeApi(fetcher);
export const tagsApi = createTagsApi(fetcher);
export const legalRadarApi = createLegalRadarApi(fetcher);
