import type { ComponentProps } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ColumnDef } from '@tanstack/react-table';

import type { ApiSharePointDocument } from '@legalfly/api/sharePoint/SharePointApi';
import { SelectColumn } from '@legalfly/components/documentPicker/columns/select';
import { getFileIcon } from '@legalfly/components/documents/helpers';
import type { IconName } from '@legalfly/ui/icon';
import { Icon } from '@legalfly/ui/icon';
import { TableCellButton } from '@legalfly/ui/tableCellButton';
import { Text } from '@legalfly/ui/text';
import { formatRelativeTime } from '@legalfly/utils/date';
import { TABLE_COLUMN_SIZES } from 'components/review/constants';

import { ImportSharePointDocumentMenu } from './ImportSharePointDocumentMenu';

interface Props {
  onDocumentClick: (document: ApiSharePointDocument) => void;
}

type CellWrapperProps = Props &
  Omit<ComponentProps<typeof TableCellButton>, 'onClick'> & {
    document: ApiSharePointDocument;
    children?: React.ReactNode;
  };

const CellWrapper = ({ children, id, document, onDocumentClick, ...props }: CellWrapperProps) => {
  const handleOnClick = (e: React.MouseEvent) => {
    if (
      e.target instanceof Element &&
      (e.target.closest('[role="checkbox"]') || e.target.closest('label'))
    ) {
      return;
    }

    onDocumentClick(document);
  };

  return (
    <TableCellButton onClick={handleOnClick} {...props}>
      {children}
    </TableCellButton>
  );
};

export const useSiteDocumentColumns = (props: Props) => {
  const { t, i18n } = useTranslation();

  const columns = useMemo<ColumnDef<ApiSharePointDocument>[]>(
    () => [
      {
        id: 'select',
        size: TABLE_COLUMN_SIZES.SELECT,
        header: ({ table }) => SelectColumn.Header({ allowMultiple: true, table }),
        cell: SelectColumn.Cell,
      },
      {
        id: 'name',
        accessorKey: 'name',
        maxSize: TABLE_COLUMN_SIZES.NAME,
        enableSorting: true,
        header: () => <Text variant='bodySmall'>{t('review.table.name')}</Text>,
        cell: ({ row }) => {
          const document = row.original;

          const icon: IconName =
            document.type === 'folder' ? 'folder' : getFileIcon(document.fileType);

          return (
            <CellWrapper document={document} {...props}>
              <div className='flex flex-1 items-center gap-2 overflow-hidden'>
                <Icon name={icon} className='min-h-5 min-w-5 flex-shrink-0' />
                <div className='flex-1 overflow-hidden'>
                  <div className='flex items-center overflow-hidden'>
                    <span className='truncate'>{document.name}</span>
                  </div>
                </div>
              </div>
            </CellWrapper>
          );
        },
      },
      {
        id: 'createdAt',
        accessorKey: 'createdAt',
        maxSize: TABLE_COLUMN_SIZES.CREATED_AT,
        enableSorting: true,
        header: () => <Text variant='bodySmall'>{t('review.table.createdAt')}</Text>,
        cell: ({ row }) => {
          const document = row.original;
          return (
            <CellWrapper document={document} {...props}>
              {formatRelativeTime(document.createdAt, i18n.language)}
            </CellWrapper>
          );
        },
      },
      {
        id: 'actions',
        enableSorting: false,
        header: () => null,
        maxSize: TABLE_COLUMN_SIZES.ACTIONS,
        cell: ({ row }) => <ImportSharePointDocumentMenu document={row.original} />,
      },
    ],
    [t, props, i18n.language],
  );

  return columns;
};
