import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { createFileRoute } from '@tanstack/react-router';

import { useDocumentCategoryLabel } from '@legalfly/components/documentCategory/useDocumentCategoryLabel';
import { JurisdictionIcon } from '@legalfly/components/jurisdiction/SelectJurisdiction';
import { useTextToParagraphedText } from '@legalfly/components/review/hooks/useTextToParagraphedText';
import { Button } from '@legalfly/ui/button';
import { Icon } from '@legalfly/ui/icon';
import { List, ListItem } from '@legalfly/ui/list';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@legalfly/ui/table';
import { Text } from '@legalfly/ui/text';
import { withToasts } from '@legalfly/ui/toast';
import { cn } from '@legalfly/ui/utils';
import { Content } from 'components/common/content/Content';
import { ContentRow } from 'components/common/content/ContentRow';
import { WordReportClauseDialog } from 'components/reports/word/WordReportClauseDialog';
import { downloadBlobToDesktop } from 'core/modules/documents/helpers/useDownload';
import { reportsQueryOptions, useDownloadWordReport, useWordReport } from 'core/modules/reports';
import { reportsToasts } from 'core/modules/reports/toasts';

export const Route = createFileRoute('/_auth/_layout/reports/word/$documentUuid/$reportUuid')({
  component: RouteComponent,
  loader: async ({ context, params }) => {
    return context.queryClient.ensureQueryData(
      reportsQueryOptions.wordReport({
        documentUuid: params.documentUuid,
        reportUuid: params.reportUuid,
      }),
    );
  },
});

function RouteComponent() {
  const { t } = useTranslation();

  const { documentUuid, reportUuid } = Route.useParams();
  const { report } = useWordReport({ documentUuid, reportUuid });
  const { downloadWordReport, isLoading } = useDownloadWordReport();

  const getDocumentCategoryLabel = useDocumentCategoryLabel();
  const summary = useTextToParagraphedText({
    text: report.summary ?? undefined,
  });

  const handleDownload = useCallback(async () => {
    const { document, filename, type } = await withToasts(
      downloadWordReport({
        reportUuid,
        documentUuid,
        body: {
          labels: {
            documentType: t('report.word.category'),
            parties: t('report.word.parties'),
            jurisdiction: t('report.word.jurisdiction'),
            clause: t('report.word.columns.clause'),
            issues: t('report.word.columns.issues'),
            amendments: t('report.word.columns.amendments'),
            comments: t('report.word.columns.comments'),
          },
        },
      }),
    )(reportsToasts.downloadWordReport());

    const blob = new Blob([new Uint8Array(Object.values(document))], { type });
    downloadBlobToDesktop(blob, filename);
  }, [downloadWordReport, reportUuid, documentUuid, t]);

  const reportHasComments = report.clauses.some(
    (clause) => clause.comments && clause.comments.length > 0,
  );
  const reportHasAmendments = report.clauses.some(
    (clause) => clause.amendments && clause.amendments.length > 0,
  );
  const reportHasIssues = report.clauses.some(
    (clause) => clause.issues && clause.issues.length > 0,
  );

  return (
    <div className='flex h-full flex-col gap-3'>
      <ContentRow>
        <div className='flex flex-1 items-center justify-between gap-2'>
          <div className='flex flex-1 items-center gap-2'>
            <Text>{report.name}</Text>
          </div>
          <Button
            onClick={handleDownload}
            variant='soft'
            isLoading={isLoading}
            renderLeft={<Icon name='download-2' size='sm' />}
          >
            {t('action.downloadReport')}
          </Button>
        </div>
      </ContentRow>
      <Content className='flex flex-1 items-center justify-center'>
        {summary.length > 0 && (
          <div className='flex flex-col gap-2'>
            <Text as='h2' variant='heading'>
              {t('report.word.summary')}
            </Text>
            <div className='mb-6 flex flex-col justify-between gap-6 md:flex-row'>
              <div className='flex flex-col gap-4'>
                <div className='flex flex-col gap-1'>
                  {summary.map((paragraph, index) => (
                    <Text key={index} as='p'>
                      {paragraph}
                    </Text>
                  ))}
                </div>
              </div>
              <List className='w-fit'>
                <ListItem className='gap-8'>
                  <Text variant='bodySemiBold'>{t('report.word.category')}</Text>
                  <Text>{getDocumentCategoryLabel(report.category)}</Text>
                </ListItem>
                <ListItem className='gap-8'>
                  <Text variant='bodySemiBold'>{t('report.word.parties')}</Text>
                  <Text>{report.parties?.join(', ')}</Text>
                </ListItem>
                <ListItem className='gap-8'>
                  <Text variant='bodySemiBold'>{t('report.word.jurisdiction')}</Text>
                  <div className='flex items-center gap-2'>
                    <JurisdictionIcon jurisdiction={report.jurisdiction} />
                    <Text className='capitalize'>{report.jurisdiction}</Text>
                  </div>
                </ListItem>
              </List>
            </div>
          </div>
        )}
        <Table parentClassName='overflow-y-auto relative border-collapse border border-stroke-weaker rounded-sm'>
          <TableHeader className='bg-fill-weak'>
            <TableRow>
              <TableHead className='text-body-semibold text-content-body-strong'>
                {t('report.word.columns.clause')}
              </TableHead>
              {reportHasIssues && (
                <TableHead className='text-body-semibold text-content-body-strong'>
                  {t('report.word.columns.issues')}
                </TableHead>
              )}
              {reportHasAmendments && (
                <TableHead className='text-body-semibold text-content-body-strong'>
                  {t('report.word.columns.amendments')}
                </TableHead>
              )}
              {reportHasComments && (
                <TableHead className='text-body-semibold text-content-body-strong'>
                  {t('report.word.columns.comments')}
                </TableHead>
              )}
            </TableRow>
          </TableHeader>
          <TableBody>
            {report.clauses.map((clause, index) => {
              const previousClause = index > 0 ? report.clauses[index - 1] : null;
              const showClauseName = !previousClause || previousClause.name !== clause.name;

              return (
                <TableRow key={clause.uuid}>
                  <TableCell
                    className={cn(
                      'border-e border-stroke-weaker p-8 text-content-body',
                      showClauseName && 'bg-fill-strongest',
                    )}
                  >
                    {showClauseName ? clause.name : ''}
                  </TableCell>
                  {reportHasIssues && (
                    <TableCell className='border-e border-stroke-weaker p-8 align-top text-content-body'>
                      <ul className='list-inside'>
                        {clause.issues.map((issue) => (
                          <li key={issue.uuid} className='list-disc pt-1'>
                            {issue.content}
                          </li>
                        ))}
                      </ul>
                    </TableCell>
                  )}
                  {reportHasAmendments && (
                    <TableCell className='border-e border-stroke-weaker p-8 align-top text-content-body'>
                      <ul className='list-inside'>
                        {clause.amendments.map((amendment) => (
                          <li key={amendment.uuid} className='list-disc pt-1'>
                            {amendment.content}
                          </li>
                        ))}
                      </ul>
                      <WordReportClauseDialog clause={clause.name} snippet={clause.snippet} />
                    </TableCell>
                  )}
                  {reportHasComments && (
                    <TableCell className='border-e border-stroke-weaker p-8 align-top text-content-body'>
                      <ul className='list-inside'>
                        {clause.comments.map((comment) => (
                          <li key={comment.uuid} className='list-disc pt-1'>
                            {comment.content}
                          </li>
                        ))}
                      </ul>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <div className='mt-8 flex flex-col gap-8'>
          {report.customFields.map((customField) => (
            <div key={customField.title} className='flex flex-col gap-2'>
              <Text as='h2' variant='heading'>
                {customField.title}
              </Text>
              <Text className='whitespace-pre-line'>{customField.answer}</Text>
            </div>
          ))}
        </div>
      </Content>
    </div>
  );
}
