import { useQueryClient } from '@tanstack/react-query';

import type { ApiAgentDetail } from '@legalfly/api/agents';
import { ConversationTypeEnum } from '@legalfly/api/conversations';
import type { ApiDocument } from '@legalfly/api/documents';
import { createAgentsModule } from '@legalfly/modules/agents';
import { useWebSocketSubscriber } from '@legalfly/websockets';

import { agentsApi } from '../../di';
import { useCreateConversationMessage } from '../conversations';

const agentsModule = createAgentsModule({ agentsApi });

export const {
  useAgents,
  useAgent,
  useCreateAgent,
  useAgentConversations,
  useDeleteAgent,
  useUpdateAgent,
  agentsQueryOptions,
  useStoreAgents,
  useToggleStoreAgent,
  useCustomStoreAgents,
  useToggleCustomStoreAgent,
  useStoreAgent,
} = agentsModule;

export const useCreateAgentConversationMessage = () => {
  const queryClient = useQueryClient();
  const { createConversationMessage } = useCreateConversationMessage({
    type: ConversationTypeEnum.AGENT,
  });

  return async (body: Parameters<typeof createConversationMessage>[0]) => {
    const message = await createConversationMessage(body);

    if (body.linkedEntityType === 'agent' && body.linkedEntityUuid) {
      // Refetch the conversation list
      queryClient.invalidateQueries({
        queryKey: agentsQueryOptions.agentConversations({
          uuid: body.linkedEntityUuid,
        }).queryKey,
      });
    }

    return message;
  };
};

const useListenToAgentDocumentStatus = ({
  document,
  agent,
}: {
  document: ApiDocument;
  agent: ApiAgentDetail;
}) => {
  const queryClient = useQueryClient();

  useWebSocketSubscriber(`document_upload_status_${document.uuid}`, (data) => {
    queryClient.setQueryData(agentsQueryOptions.agent({ uuid: agent.uuid }).queryKey, (oldData) => {
      if (!oldData) return oldData;

      const updatedDocuments = oldData.documents.map((doc) =>
        doc.uuid === document.uuid ? { ...doc, status: data.status } : doc,
      );
      return { ...oldData, documents: updatedDocuments };
    });
  });
};

const ListenToAgentDocumentStatus = ({
  document,
  agent,
}: {
  document: ApiDocument;
  agent: ApiAgentDetail;
}) => {
  useListenToAgentDocumentStatus({ document, agent });
  return null;
};

export const ListenToAgentDocumentsStatus = ({ agent }: { agent: ApiAgentDetail }) =>
  agent.documents.map((document) => (
    <ListenToAgentDocumentStatus key={document.uuid} document={document} agent={agent} />
  ));

export * from './toasts';
