import { legalFlyConfig } from '@legalfly/config';

import type { BodyOf, ParamsOf, QueryOf, ResponseOf } from '../client';
import { createClient } from '../client';
import type { Fetcher } from '../fetcher/Fetcher';

export const createReviewsApi = (fetcher: Fetcher) => {
  const client = createClient(fetcher);

  const api = legalFlyConfig.api.paths;

  const getReviews = (query: QueryOf<'reviews/get'>) => {
    return client(api.reviews.list, { method: 'GET', query });
  };

  const getReview = ({ uuid }: ParamsOf<'reviews/:uuid/get'>) => {
    return client(api.reviews.detail, { method: 'GET', params: { uuid } });
  };

  const createReview = ({ body }: { body: BodyOf<'reviews/post'> }) => {
    return client(api.reviews.create, { method: 'POST', body });
  };

  const deleteReview = ({ uuid }: ParamsOf<'reviews/:uuid/delete'>) => {
    return client(api.reviews.delete, { method: 'DELETE', params: { uuid } });
  };

  const updateReview = ({ uuid, body }: { uuid: string; body: BodyOf<'reviews/:uuid/patch'> }) => {
    return client(api.reviews.update, { method: 'PATCH', params: { uuid }, body });
  };

  const startWorkbench = ({
    uuid,
    reviewItemUuid,
    body,
  }: {
    uuid: string;
    reviewItemUuid: string;
    body: BodyOf<'reviews/:uuid/workbench/:reviewItemUuid/start/post'>;
  }) => {
    return client(api.reviews.startWorkbench, {
      method: 'POST',
      params: { uuid, reviewItemUuid },
      body,
    });
  };

  const applyWorkbench = ({
    uuid,
    reviewItemUuid,
    body,
  }: {
    uuid: string;
    reviewItemUuid: string;
    body: BodyOf<'reviews/:uuid/workbench/:reviewItemUuid/apply/post'>;
  }) => {
    return client(api.reviews.applyWorkbench, {
      method: 'POST',
      params: { uuid, reviewItemUuid },
      body,
    });
  };

  return {
    getReviews,
    getReview,
    createReview,
    deleteReview,
    updateReview,
    startWorkbench,
    applyWorkbench,
  };
};

export type ReviewsApi = ReturnType<typeof createReviewsApi>;
export type ApiReviews = ResponseOf<'reviews/get'>;
export type ApiReview = ApiReviews['reviews'][number];
export type ApiReviewStats = ApiReviews['stats'];
export type ApiReviewItem = ResponseOf<'reviews/:uuid/get'>['items'][number];
export type ApiReviewDetail = ResponseOf<'reviews/:uuid/get'>;
