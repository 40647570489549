import { type ComponentProps } from 'react';
import type { SortingState } from '@tanstack/react-table';

import { BaseDocumentPicker } from '@legalfly/components/documentPicker/BaseDocumentPicker';
import { useTableHeight } from '@legalfly/utils/hooks/useTableHeight';

import { useDocumentPickerColumns } from './columns';

type Props = Omit<
  ComponentProps<typeof BaseDocumentPicker>,
  'useColumns' | 'tableHeight' | 'tableRef'
> & {
  sorting?: SortingState;
  onSort?: (updaterOrValue: SortingState | ((old: SortingState) => SortingState)) => void;
  tableHeight?: number;
  tableRef?: React.RefObject<HTMLTableElement | null>;
};

export const DocumentPicker = (props: Props) => {
  const { tableHeight, tableRef } = useTableHeight();

  return (
    <BaseDocumentPicker
      tableRef={tableRef}
      useColumns={useDocumentPickerColumns}
      tableHeight={tableHeight}
      {...props}
    />
  );
};
