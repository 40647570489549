import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createFileRoute, Link } from '@tanstack/react-router';
import { nonEmpty } from 'common/utils/nonEmpty';
import { createSortableRoute } from 'common/utils/routeUtils';

import type { KnowledgeSortField } from '@legalfly/api/knowledge';
import { DEFAULT_KNOWLEDGE_SORT, knowledgeHasDocument } from '@legalfly/api/knowledge';
import {
  DocumentsUploadProgress,
  useDocumentsUploadProgress,
} from '@legalfly/components/documentsUploadProgress';
import { Button, UploadButton } from '@legalfly/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@legalfly/ui/dropdownMenu';
import { Icon } from '@legalfly/ui/icon';
import { cn } from '@legalfly/ui/utils';
import { ContentDropZone } from 'components/common/content/ContentDropZone';
import { KnowledgeTable } from 'components/knowledge/KnowledgeTable';
import { KnowledgeUploadDialog } from 'components/knowledge/KnowledgeUploadDialog';
import { routeRedirectWithFeatureFlag } from 'core/analytics';
import { useCreateFiles } from 'core/modules/documents/helpers/useCreateFiles';
import {
  knowledgeQueryOptions,
  ListenToKnowledgeDocumentUploadStatus,
  useKnowledgeList,
} from 'core/modules/knowledge';

const { validateSearch, loaderDeps } = createSortableRoute<KnowledgeSortField>({
  defaultSort: DEFAULT_KNOWLEDGE_SORT,
});

export const Route = createFileRoute('/_auth/_layout/knowledge/')({
  beforeLoad: async ({ context }) => routeRedirectWithFeatureFlag(context.queryClient, 'knowledge'),
  component: KnowledgeRoute,
  validateSearch,
  loaderDeps,
  loader({ context, deps: { sortBy, sortOrder } }) {
    return context.queryClient.ensureQueryData(
      knowledgeQueryOptions.getKnowledgeList({ sortBy, sortOrder }),
    );
  },
});

function KnowledgeRoute() {
  const { t } = useTranslation();
  const navigate = Route.useNavigate();
  const sorting = Route.useSearch();

  const [filesToUpload, setFilesToUpload] = useState<File[] | null>(null);

  const { createFiles, numberOfFilesToUpload } = useCreateFiles('knowledge');
  const { knowledgeList } = useKnowledgeList({ query: sorting });

  const documents = knowledgeList.map((knowledge) => knowledge.document).filter(nonEmpty);
  const documentUploadProgress = useDocumentsUploadProgress(documents, numberOfFilesToUpload);

  const prepareUpload = (files: File[]) => {
    if (files.length === 0) return;
    setFilesToUpload(files);
  };

  const handleDialogUpload = async (anonymized: boolean) => {
    if (!filesToUpload) return;

    const { documents, knowledgeUuids } = await createFiles({
      uuid: undefined,
      files: filesToUpload,
      anonymized,
    });

    setFilesToUpload(null);

    if (documents.length === 1 && knowledgeUuids[0]) {
      navigate({
        to: '/knowledge/$knowledgeUuid',
        params: {
          knowledgeUuid: knowledgeUuids[0],
        },
      });
    }
  };

  return (
    <div className='flex flex-col gap-3'>
      <ContentDropZone
        parentClassName={cn(
          documentUploadProgress.isUploading ? 'h-content-with-progress' : 'h-content',
        )}
        onDropFiles={prepareUpload}
      >
        <div className='mb-6 flex items-center gap-4'>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button renderLeft={<Icon name='plus' />} variant='invert'>
                {t('knowledge.actions.create.title')}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className='w-64' align='start'>
              <DropdownMenuLabel>
                {t('knowledge.actions.create.organiseAndUpload')}
              </DropdownMenuLabel>
              <DropdownMenuItem>
                <Icon name='upload-1' />
                {t('knowledge.actions.create.uploadDocuments')}
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Icon name='share-point' />
                {t('knowledge.actions.create.importFromSharepoint')}
              </DropdownMenuItem>
              <DropdownMenuSeparator variant='weak' className='my-3' />
              <DropdownMenuLabel>
                {t('knowledge.actions.create.knowledgeCreation')}
              </DropdownMenuLabel>
              <DropdownMenuItem asChild>
                <Link to='/knowledge/new'>
                  <Icon name='edit-4' />
                  {t('knowledge.actions.create.clause')}
                </Link>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <UploadButton onUpload={prepareUpload} renderLeft={<Icon name='upload-1' />}>
            {t('knowledge.actions.uploadOrDrop')}
          </UploadButton>
          <Button variant='soft' className='ml-auto' asChild>
            <Link to='/tags'>
              <Icon name='tag-1' />
              {t('tags.title')}
            </Link>
          </Button>
        </div>

        <KnowledgeTable data={knowledgeList} sorting={sorting} />

        {knowledgeList.filter(knowledgeHasDocument).map((item) => (
          <ListenToKnowledgeDocumentUploadStatus
            key={item.uuid}
            documentUuid={item.document.uuid}
            knowledgeUuid={item.uuid}
          />
        ))}
      </ContentDropZone>
      <KnowledgeUploadDialog
        open={filesToUpload !== null}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            setFilesToUpload(null);
          }
        }}
        onUpload={handleDialogUpload}
      />
      <DocumentsUploadProgress {...documentUploadProgress} />
    </div>
  );
}
