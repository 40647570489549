import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';

import { useToast, withToasts } from '@legalfly/ui/toast';
import {
  useDisconnectFromSharePoint,
  useExchangeSharePointCode,
  useSignInToSharePoint,
} from 'core/modules/integrations/sharePoint';
import { integrationsToasts } from 'core/modules/integrations/toasts';

export type SharePointRedirectParams = {
  code?: string;
  session_state?: string;
};

export const useSharePointAuth = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { t } = useTranslation();

  const { signIn } = useSignInToSharePoint();
  const { disconnect } = useDisconnectFromSharePoint();
  const { exchangeCode } = useExchangeSharePointCode();

  const handleSharePointRedirect = useCallback(
    async ({ code, session_state }: { code: string; session_state: string }) => {
      await exchangeCode({
        code,
        state: session_state,
      });

      toast({
        variant: 'success',
        title: t('toast.success.sharePointConnected.title'),
        description: t('toast.success.sharePointConnected.description'),
      });

      navigate({
        to: '/settings/profile',
        replace: true,
      });
    },
    [exchangeCode, navigate, toast, t],
  );

  const useHandleSharePointRedirectEffect = (searchParams: SharePointRedirectParams) => {
    useEffect(() => {
      if (searchParams.code && searchParams.session_state) {
        handleSharePointRedirect({
          code: searchParams.code,
          session_state: searchParams.session_state,
        });
      }
    }, [searchParams]);
  };

  const connectSharePoint = useCallback(async () => {
    const { redirect } = await signIn();
    window.location.href = redirect;
  }, [signIn]);

  const disconnectSharePoint = useCallback(async () => {
    withToasts(disconnect())(integrationsToasts.disconnectSharePoint());
  }, [disconnect]);

  return {
    connectSharePoint,
    disconnectSharePoint,
    useHandleSharePointRedirectEffect,
  };
};
