import { legalFlyConfig } from '@legalfly/config';

import type { BodyOf, ParamsOf, ResponseOf } from '../client';
import { createClient } from '../client';
import type { Fetcher } from '../fetcher';

const api = legalFlyConfig.api.paths;

export const createTagsApi = (fetcher: Fetcher) => {
  const client = createClient(fetcher);

  const getTags = () => {
    return client(api.tags.list, { method: 'GET' });
  };

  const createTag = ({ body }: { body: BodyOf<'tags/post'> }) => {
    return client(api.tags.create, { method: 'POST', body });
  };

  const updateTag = ({
    params,
    body,
  }: {
    params: ParamsOf<'tags/:uuid/put'>;
    body: BodyOf<'tags/:uuid/put'>;
  }) => {
    return client(api.tags.update, { method: 'PUT', params, body });
  };

  const deleteTag = ({ params }: { params: ParamsOf<'tags/:uuid/delete'> }) => {
    return client(api.tags.delete, { method: 'DELETE', params });
  };

  const createTagGroup = ({ body }: { body: BodyOf<'tag-groups/post'> }) => {
    return client(api.tagGroups.create, { method: 'POST', body });
  };

  const updateTagGroup = ({
    params,
    body,
  }: {
    params: ParamsOf<'tag-groups/:uuid/put'>;
    body: BodyOf<'tag-groups/:uuid/put'>;
  }) => {
    return client(api.tagGroups.update, { method: 'PUT', params, body });
  };

  const deleteTagGroup = ({ params }: { params: ParamsOf<'tag-groups/:uuid/delete'> }) => {
    return client(api.tagGroups.delete, { method: 'DELETE', params });
  };

  return {
    getTags,
    createTag,
    updateTag,
    deleteTag,
    createTagGroup,
    updateTagGroup,
    deleteTagGroup,
  };
};

export type TagsApi = ReturnType<typeof createTagsApi>;
export type ApiTag = Extract<ResponseOf<'tags/get'>[number], { tagGroupUuid?: string }>;
export type ApiTagGroup = Extract<ResponseOf<'tags/get'>[number], { tags: ApiTag[] }>;
export type ApiTagGroupOrTag = ApiTagGroup | ApiTag;
