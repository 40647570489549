import type { ComponentPropsWithRef, ElementType } from 'react';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { fixedForwardRef } from '@legalfly/utils/refs';
import { cn } from 'utils';

const variant = {
  display: 'font-writer text-display',
  title: 'font-writer text-title',
  heading2: 'font-writer text-heading2',

  headingLight: 'text-heading-light',
  heading: 'text-heading',
  headingSemiBold: 'text-heading-semibold',

  subheadingLight: 'text-subheading-light',
  subheading: 'text-subheading',
  subheadingSemiBold: 'text-subheading-semibold',

  bodyLargeLight: 'text-body-large-light',
  bodyLarge: 'text-body-large',
  bodyLargeSemiBold: 'text-body-large-semibold',

  bodyLight: 'text-body-light',
  body: 'text-body',
  bodySemiBold: 'text-body-semibold',

  bodySmall: 'text-body-small',
  bodySmallLight: 'text-body-small-light',
  bodySmallSemibold: 'text-body-small-semibold',

  captionLight: 'text-caption-light',
  caption: 'text-caption',
  captionSemiBold: 'text-caption-semibold',

  captionSmall: 'text-caption-small',
} as const;

const textVariants = Object.keys(variant) as (keyof typeof variant)[];

const chadcnTextVariants = cva('', {
  variants: {
    variant,
  },
  defaultVariants: {
    variant: 'body',
  },
});

type DistributiveOmit<T, TOmitted extends PropertyKey> = T extends any ? Omit<T, TOmitted> : never;

interface Props<TAs extends ElementType> extends VariantProps<typeof chadcnTextVariants> {
  className?: string;
  as?: TAs;
}

type TextProps<TAs extends ElementType> = Props<TAs> &
  DistributiveOmit<ComponentPropsWithRef<ElementType extends TAs ? 'p' : TAs>, keyof Props<TAs>>;

export const Text = fixedForwardRef(function Text<TAs extends ElementType = 'p'>(
  { as, variant, className, ...props }: TextProps<TAs>,
  ref: React.ForwardedRef<Element>,
) {
  const Comp = as || 'p';
  return <Comp ref={ref} className={cn(chadcnTextVariants({ variant, className }))} {...props} />;
}, 'Text');

export { textVariants };
