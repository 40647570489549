import { createSharePointModule } from '@legalfly/modules/sharePoint';

import { documentsApi, settingsApi, sharePointApi } from '../../../di';

export const {
  useSignInToSharePoint,
  useExchangeSharePointCode,
  useSharePointSites,
  useDisconnectFromSharePoint,
  useSharePointSiteDocuments,
  useDownloadSiteDocument,
} = createSharePointModule({
  sharePointApi,
  settingsApi,
  documentsApi,
});
