import { useMutation, useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import type { ApiUser, UsersApi } from '@legalfly/api/users';
import { merge } from '@legalfly/utils/merge';

import { usersQueryOptions } from './usersQueryOptions';

export const createUsersModule = ({ usersApi }: { usersApi: UsersApi }) => {
  const queryOptions = usersQueryOptions({ usersApi });

  const useUsers = ({
    isSuspenseEnabled = true,
    enabled = true,
    usePreviousData = false,
    ...query
  }: Parameters<UsersApi['getUsers']>[0] & {
    isSuspenseEnabled?: boolean;
    enabled?: boolean;
    usePreviousData?: boolean;
  } = {}) => {
    const { data, isLoading, error } = isSuspenseEnabled
      ? useSuspenseQuery(queryOptions.users(query))
      : useQuery({
          ...queryOptions.users(query),
          enabled,
          placeholderData: usePreviousData ? (prevData) => prevData : undefined,
        });

    return {
      users: data?.users ?? [],
      seats: data?.seats ?? 0,
      availableSeats: data?.availableSeats ?? 0,
      userStatistics: data?.userStatistics,
      isLoading,
      error,
    };
  };

  const useUser = (uuid: ApiUser['uuid']) => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.user(uuid));

    return {
      user: data,
      isLoading,
      error,
    };
  };

  const useCurrentUser = () => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.currentUser());

    return {
      currentUser: data,
      isLoading,
      error,
    };
  };

  const useUpdateUser = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['users', 'update'],
      mutationFn: usersApi.updateUser,
      onSuccess(user, { body: { role } }) {
        if (role) {
          queryClient.invalidateQueries(queryOptions.users());
        } else {
          queryClient.setQueryData(queryOptions.currentUser().queryKey, (oldData) =>
            oldData ? merge(user, oldData) : undefined,
          );
        }
      },
    });

    return {
      updateUser: mutateAsync,
      isLoading: isPending,
    };
  };

  const useActivateUser = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['users', 'activate'],
      mutationFn: (uuid: ApiUser['uuid']) => usersApi.activateUser({ uuid }),
      onSuccess: () => {
        queryClient.invalidateQueries(queryOptions.users());
      },
    });

    return {
      activateUser: mutateAsync,
      isLoading: isPending,
    };
  };

  const useDeactivateUser = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['users', 'deactivate'],
      mutationFn: (uuid: ApiUser['uuid']) => usersApi.deactivateUser({ uuid }),
      onSuccess: () => {
        queryClient.invalidateQueries(queryOptions.users());
      },
    });

    return {
      deactivateUser: mutateAsync,
      isLoading: isPending,
    };
  };

  const useDeleteUser = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['users', 'delete'],
      mutationFn: (uuid: ApiUser['uuid']) => usersApi.deleteUser({ uuid }),
      onSuccess: () => {
        queryClient.invalidateQueries(queryOptions.users());
      },
    });

    return {
      deleteUser: mutateAsync,
      isLoading: isPending,
    };
  };

  const useCreateUser = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['users', 'create'],
      mutationFn: (body: Parameters<typeof usersApi.createUser>[0]) => usersApi.createUser(body),
      onSuccess: () => {
        queryClient.invalidateQueries(queryOptions.users());
      },
    });

    return {
      createUser: mutateAsync,
      isLoading: isPending,
    };
  };

  const useUpdateAvatar = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['users', 'update', 'avatar'],
      mutationFn: usersApi.updateUserAvatar,
      onSuccess: ({ avatarUrl }) => {
        queryClient.setQueryData(queryOptions.currentUser().queryKey, (oldData) =>
          oldData ? { ...oldData, avatarUrl } : undefined,
        );
      },
    });

    return {
      updateAvatar: mutateAsync,
      isLoading: isPending,
    };
  };

  return {
    useUsers,
    useUser,
    useCurrentUser,
    useUpdateUser,
    useActivateUser,
    useDeactivateUser,
    useCreateUser,
    useDeleteUser,
    usersQueryOptions: queryOptions,
    useUpdateAvatar,
  };
};
