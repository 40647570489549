import i18n from 'i18next';

import type { FetcherError } from '@legalfly/api/fetcher';
import { createToasts } from '@legalfly/ui/toast';

export const multiReviewToasts = createToasts({
  createMultiReview: () => ({
    errorToast: (error: FetcherError) => {
      return {
        title: i18n.t('toast.error.title'),
        description:
          error.response.code === 'MULTI_REVIEW_LIMIT'
            ? i18n.t('toast.error.createMultiReview.multiReviewLimit.description')
            : i18n.t('toast.error.createMultiReview.description'),
      };
    },
  }),
  deleteMultiReview: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.deleteMultiReview.description'),
    }),
  }),
  updateMultiReview: () => ({
    errorToast: () => ({
      title: i18n.t('toast.error.title'),
      description: i18n.t('toast.error.updateMultiReview.description'),
    }),
  }),
});
