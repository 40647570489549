import { queryOptions } from '@tanstack/react-query';

import type { ApiUserSettings, SettingsApi } from '@legalfly/api/settings';

export const settingsQueryOptions = ({ settingsApi }: { settingsApi: SettingsApi }) => ({
  currentUserSettings: <TData = ApiUserSettings>({
    select,
  }: { select?: (data: ApiUserSettings) => TData } = {}) =>
    queryOptions({
      queryKey: ['user', 'current', 'settings'],
      queryFn: () => settingsApi.getCurrentUserSettings(),
      staleTime: 1000 * 60 * 60 * 24, // 1 day
      select,
    }),
});
