import type { CSSProperties, ForwardedRef } from 'react';
import { useCallback, useState } from 'react';
import type { Column, SortingState, TableOptions } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { fixedForwardRef } from '@legalfly/utils/refs';
import { cn } from 'utils';

import { DndDataTable } from './DndDataTable';
import { DraggableTableRow } from './DraggableTableRow';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './Table';
import './styles.css';

// Add TruncatedCell component
const TruncatedCell = ({ value }: { value: string | undefined }) => (
  <div className='w-full truncate' title={value ?? ''}>
    {value}
  </div>
);

type SortingChangeHandler = (
  updaterOrValue: SortingState | ((old: SortingState) => SortingState),
) => void;

const getColumnStyles = <TData,>(column: Column<TData>): CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');
  const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right');

  // Pinning-related styles
  const pinningStyles: CSSProperties = {
    boxShadow: isLastLeftPinnedColumn
      ? '-4px 0 4px -4px gray inset'
      : isFirstRightPinnedColumn
        ? '4px 0 4px -4px gray inset'
        : undefined,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    opacity: isPinned ? 0.95 : 1,
    position: isPinned ? 'sticky' : 'relative',
    zIndex: isPinned ? 1 : 0,
    maxWidth: column.columnDef.maxSize ? `${column.columnDef.maxSize}px` : undefined,
  };

  return { ...pinningStyles };
};

export interface TableDataRow {
  uuid: string;
  name?: string | undefined | string[];
}

interface Props<TData extends TableDataRow> extends Omit<TableOptions<TData>, 'getCoreRowModel'> {
  tableHeight: number;
  noResultsLabel: string;
  onRowClick?: (rowData: TData) => void;
  isDroppable?: (rowData: TData) => boolean;
  onDrop?: (p: { sourceUuids: string[]; targetUuid: string }) => void;
  tableHeaderClassName?: string;
  onSort?: (updaterOrValue: SortingState | ((old: SortingState) => SortingState)) => void;
  sorting?: SortingState;
  initialSorting?: SortingState;
}

export const DataTable = fixedForwardRef(
  <TData extends TableDataRow>(
    {
      columns,
      data,
      onRowSelectionChange,
      isDroppable,
      onDrop,
      tableHeight,
      noResultsLabel,
      tableHeaderClassName,
      onSort,
      sorting,
      initialSorting = [],
      ...props
    }: Props<TData>,
    ref: ForwardedRef<HTMLTableElement>,
  ) => {
    const [internalSorting, setInternalSorting] = useState<SortingState>(initialSorting);

    const isManualSorting = typeof onSort === 'function';

    const handleSortingChange: SortingChangeHandler = useCallback(
      (updaterOrValue) => {
        if (isManualSorting) {
          onSort(updaterOrValue);
          return;
        }
        setInternalSorting((old) =>
          typeof updaterOrValue === 'function' ? updaterOrValue(old) : updaterOrValue,
        );
      },
      [isManualSorting, onSort],
    );

    const table = useReactTable({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      onSortingChange: handleSortingChange,
      onRowSelectionChange,
      ...props,
      state: {
        sorting: isManualSorting ? sorting ?? [] : internalSorting,
        ...props.state,
      },
      manualSorting: isManualSorting,
      enableColumnResizing: false,
      defaultColumn: {
        cell: (info) => {
          const value = info.getValue();
          return typeof value === 'string' ? <TruncatedCell value={value} /> : value;
        },
      },
    });

    const selectedRows = table.getSelectedRowModel().rows.map((r) => r.original);

    const isDndEnabled = Boolean(onDrop);
    const Row = isDndEnabled ? DraggableTableRow : TableRow;

    return (
      <DndDataTable
        onDrop={onDrop}
        rows={table.getRowModel().rows.map((r) => r.original)}
        selectedRows={selectedRows}
      >
        <Table parentClassName='table-container' ref={ref} style={{ maxHeight: tableHeight }}>
          <colgroup>
            {columns.map((column) => (
              <col key={column.id} style={{ width: `${column.size}px` }} />
            ))}
          </colgroup>

          <TableHeader className={cn('sticky top-0 z-10 bg-fill-maximal', tableHeaderClassName)}>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className='shadow-table-border'>
                {headerGroup.headers.map((header) => (
                  <TableHead
                    key={header.id}
                    colSpan={header.colSpan}
                    className={cn('text-content-body-weak', header.id === 'select' && 'px-0')}
                    style={getColumnStyles(header.column)}
                  >
                    <div className='whitespace-nowrap'>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </div>
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>

          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <Row
                  key={row.id}
                  id={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  className={cn(row.getIsSelected() && 'bg-fill-pressed-weak', 'h-[52px]')}
                  {...(isDndEnabled && { isDroppable: isDroppable?.(row.original) })}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      style={{
                        padding: 0,
                        ...getColumnStyles(cell.column),
                      }}
                      className={cn(cell.column.id === 'select' && 'px-0', 'break-words')}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </Row>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className='h-24 text-center'>
                  {noResultsLabel}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </DndDataTable>
    );
  },
  'DataTable',
);
