import type { ComponentProps, ForwardedRef, ReactNode } from 'react';

import { Button } from '@legalfly/ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from '@legalfly/ui/tooltip';
import { cn } from '@legalfly/ui/utils';
import { fixedForwardRef } from '@legalfly/utils/refs';

interface TableCellButtonProps extends ComponentProps<typeof Button> {
  tooltipText?: string;
  renderContent?: ReactNode;
}

export const TableCellButton = fixedForwardRef<HTMLButtonElement, TableCellButtonProps>(
  function TableCellButton(
    { children, tooltipText, className, renderContent, ...buttonProps }: TableCellButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) {
    const content = renderContent || (
      <Button
        variant='unstyled'
        className={cn(
          'cursor-pointer px-5 py-3 text-body-semibold focus-visible:outline focus-visible:outline-1 focus-visible:-outline-offset-8 focus-visible:outline-stroke-weak [&:has([role=checkbox])]:pr-0',
          'flex h-full w-full items-center justify-start whitespace-nowrap',
          className,
        )}
        ref={ref}
        {...buttonProps}
      >
        <span className='truncate text-caption'>{children}</span>
      </Button>
    );

    if (!tooltipText) {
      return content;
    }

    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>{content}</TooltipTrigger>
          <TooltipPortal>
            <TooltipContent
              side='bottom'
              style={{ maxWidth: 'var(--radix-tooltip-trigger-width)' }}
            >
              <span className='text-caption'>{tooltipText}</span>
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      </TooltipProvider>
    );
  },
  'TableCellButton',
);
