import { legalFlyConfig } from '@legalfly/config';

import type { QueryOf, ResponseOf } from '../client';
import { createClient } from '../client';
import type { Fetcher } from '../fetcher/Fetcher';

export const createConfigApi = (fetcher: Fetcher) => {
  const client = createClient(fetcher);

  const api = legalFlyConfig.api.paths;

  const getConfigValues = (query: ApiConfigValuesQuery) => {
    return client(api.config.values, {
      method: 'GET',
      query,
    });
  };

  return {
    getConfigValues,
  };
};

export type ConfigApi = ReturnType<typeof createConfigApi>;
export type ApiConfigValues = ResponseOf<'config/values/get'>;
export type ApiConfigValuesQuery = QueryOf<'config/values/get'>;
