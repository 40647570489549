import type { ReactNode } from 'react';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from '@tanstack/react-router';

import { useSidebar } from '@legalfly/components/navigationMenu/hooks/useSidebar';
import { NavigationMenu } from '@legalfly/components/navigationMenu/NavigationMenu';
import { NavigationTooltip } from '@legalfly/components/navigationMenu/NavigationTooltip';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button, IconButton } from '@legalfly/ui/button';
import { DialogTrigger } from '@legalfly/ui/dialog';
import { Divider } from '@legalfly/ui/divider';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';
import Notifications from 'components/notifications/Notifications';
import { useFeatureFlag } from 'core/analytics';

import { ManagementNavigationItem } from './navigationItems/ManagementNavigationItem';
import { ChangelogDialog } from '../changelog/ChangelogDialog';
import { FeedbackDialog } from '../feedback/FeedbackDialog';
import { getFeedbackFeatureFromPath } from '../feedback/getFeedbackFeatureFromPath';
import { AgentNavigationSkeleton } from './AgentNavigationSkeleton';
import { LayoutNavigationUserMenu } from './LayoutNavigationUserMenu';

const LazyAgentNavigation = lazy(() =>
  import('./AgentNavigationSection').then((module) => ({
    default: module.AgentNavigationSection,
  })),
);

export const LayoutNavigation = () => {
  const { t } = useTranslation();
  const { pathname: currentPath } = useLocation();
  const storeEnabled = useFeatureFlag('store');

  const { isExpanded, handleExpandToggle } = useSidebar();

  const iconClasses = 'size-6 group-[.open]:size-5 group-[.open]:min-h-5 group-[.open]:min-w-5';
  const textClasses = 'hidden group-[.open]:inline truncate';

  return (
    <aside
      className={cn(
        isExpanded ? 'open w-[250px] min-w-[250px]' : 'w-20 min-w-20',
        'group relative',
        'flex h-navigation flex-col overflow-auto overflow-x-hidden bg-surface-nav px-4 pb-3 pt-6 transition-all duration-300',
      )}
      id='sidebar'
    >
      {isExpanded ? (
        <Text
          variant='title'
          className='mb-6 text-center text-[21px] leading-[21px] tracking-[2px]'
        >
          legalfly
        </Text>
      ) : (
        <div className='mb-6 flex min-h-12 min-w-12 items-center justify-center bg-content-body-constant-dark'>
          <Text className='font-writer text-content-body-constant-light'>LF</Text>
        </div>
      )}

      <NavigationMenu className='mb-6 flex-1'>
        <NavigationTooltip text={t('nav.dashboard')} isExpanded={isExpanded}>
          <NavigationMenu.Item
            asChild
            className={cn(isExpanded ? 'mb-2' : 'mb-4')}
            isActive={currentPath === '/'}
          >
            <Link to='/'>
              <Icon name='home-line' className={iconClasses} />
              <Text className={textClasses}>{t('nav.dashboard')}</Text>
            </Link>
          </NavigationMenu.Item>
        </NavigationTooltip>
        <Notifications isExpanded={isExpanded}>
          <NavigationMenu.Item className='mb-2' asChild>
            <Button
              variant='unstyled'
              size='sm'
              renderLeft={<Icon name='inbox-1' className={iconClasses} />}
            >
              <Text as='span' className={textClasses}>
                {t('nav.inbox')}
              </Text>
            </Button>
          </NavigationMenu.Item>
        </Notifications>

        <Suspense fallback={<AgentNavigationSkeleton isExpanded={isExpanded} />}>
          <LazyAgentNavigation isExpanded={isExpanded} currentPath={currentPath} />
        </Suspense>

        <div>
          <Text
            variant='body'
            className='hidden px-6 pb-3 pt-4 text-content-body-weak group-[.open]:block'
          >
            {t('label.resources')}
          </Text>
          <Divider variant='weak' className='my-3 block group-[.open]:hidden' />
          <ManagementNavigationItem isExpanded={isExpanded} />
        </div>
      </NavigationMenu>

      <div className='flex flex-col gap-2'>
        {storeEnabled && (
          <NavigationTooltip text={t('nav.store')} isExpanded={isExpanded}>
            <NavigationMenu.Item
              asChild
              className={cn(isExpanded ? 'mb-2 border border-stroke-weaker' : 'mb-4')}
              isActive={currentPath === '/store'}
            >
              <Link to='/store'>
                <Icon name='lf-logo' className={iconClasses} />
                <Text className={textClasses}>{t('nav.store')}</Text>
              </Link>
            </NavigationMenu.Item>
          </NavigationTooltip>
        )}
        {isExpanded && <LayoutNavigationUserMenu isExpanded={isExpanded} />}
        <FlexContainer isExpanded={isExpanded}>
          <FlexContainer isExpanded={isExpanded}>
            <ChangelogDialog>
              <NavigationTooltip
                text={t('nav.changelog')}
                isExpanded={false}
                contentProps={{ side: 'top' }}
              >
                <DialogTrigger asChild>
                  <IconButton variant='tertiary' name='help-circle' size='sm' />
                </DialogTrigger>
              </NavigationTooltip>
            </ChangelogDialog>
            <FeedbackDialog
              feature={getFeedbackFeatureFromPath(currentPath)}
              trigger={
                <NavigationTooltip
                  text={t('nav.feedback')}
                  isExpanded={false}
                  contentProps={{ side: 'top' }}
                >
                  <FeedbackDialog.Trigger asChild>
                    <IconButton
                      name='thumbs-up'
                      variant='tertiary'
                      size='sm'
                      onClick={() =>
                        trackEvent({ action: 'click', category: 'feedback', label: 'open' })
                      }
                    />
                  </FeedbackDialog.Trigger>
                </NavigationTooltip>
              }
            />
          </FlexContainer>
          {!isExpanded && <LayoutNavigationUserMenu isExpanded={isExpanded} />}
          <IconButton
            name={isExpanded ? 'chevron-left' : 'chevron-right'}
            onClick={handleExpandToggle}
            className='text-icon-strongest'
            variant='tertiary'
            size='sm'
          />
        </FlexContainer>
      </div>
    </aside>
  );
};

function FlexContainer({ isExpanded, children }: { isExpanded: boolean; children: ReactNode }) {
  return (
    <div
      className={cn(
        'flex gap-2',
        isExpanded ? 'flex-row items-center justify-between' : 'flex-col items-center',
      )}
    >
      {children}
    </div>
  );
}
