import { t } from 'i18next';

export const formatLongDate = (
  date: string | Date,
  locale: Intl.LocalesArgument,
  options?: Intl.DateTimeFormatOptions,
) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const inputDate = date instanceof Date ? date : new Date(date);

  return new Intl.DateTimeFormat(locale, {
    dateStyle: 'full',
    timeStyle: 'medium',
    timeZone: userTimezone,
    ...options,
  }).format(inputDate);
};

export const formatShortDate = (
  date: string | Date,
  locale: Intl.LocalesArgument,
  options?: Intl.DateTimeFormatOptions,
) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const inputDate = date instanceof Date ? date : new Date(date);

  return new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: 'short',
    timeZone: userTimezone,
    ...options,
  }).format(inputDate);
};

export const formatTimeWithDate = (
  date: string | Date,
  locale: Intl.LocalesArgument,
  options?: Intl.DateTimeFormatOptions,
): string => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const inputDate = date instanceof Date ? date : new Date(date);

  return new Intl.DateTimeFormat(locale, {
    dateStyle: 'short',
    timeStyle: 'short',
    hour12: false,
    timeZone: userTimezone,
    ...options,
  }).format(inputDate);
};

export const formatRelativeTime = (date: string | Date, locale: Intl.LocalesArgument): string => {
  const inputDate = date instanceof Date ? date : new Date(date);

  // Calculate the difference in seconds
  const secondsDiff = Math.round((inputDate.getTime() - Date.now()) / 1000);
  const absoluteSeconds = Math.abs(secondsDiff);

  // If less than 60 seconds, return "less than a minute ago"
  if (absoluteSeconds < 60) {
    return t('label.lessThanAMinuteAgo');
  }

  // Array representing one minute, hour, day, week, month, etc. in seconds
  const unitsInSec = [60, 3600, 86400, 86400 * 7, 86400 * 30, 86400 * 365, Infinity];

  // Array equivalent to the above but in the string representation of the units
  const unitStrings = ['minute', 'hour', 'day', 'week', 'month', 'year'] as const;

  // Find the appropriate unit based on the seconds difference
  const unitIndex = unitsInSec.findIndex((cutoff) => cutoff > absoluteSeconds);

  // Get the divisor to convert seconds to the appropriate unit
  const divisor = unitIndex ? unitsInSec[unitIndex - 1] : 1;

  const rtf = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' });

  const relativeTime = rtf.format(Math.floor(secondsDiff / divisor), unitStrings[unitIndex - 1]);

  return relativeTime;
};
