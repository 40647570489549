import { queryOptions } from '@tanstack/react-query';

import type { SharePointApi } from '@legalfly/api/sharePoint/SharePointApi';

export const sharePointQueryOptions = ({ sharePointApi }: { sharePointApi: SharePointApi }) => ({
  sites: () =>
    queryOptions({
      queryKey: ['integrations', 'sharePoint', 'sites'],
      queryFn: () => sharePointApi.getSites(),
    }),
  siteDocuments: (query: Parameters<SharePointApi['getSiteDocuments']>[0]) =>
    queryOptions({
      queryKey: ['integrations', 'sharePoint', 'site', query.siteId, 'documents'].concat(
        query.folderId ? ['folder', query.folderId] : [],
      ),
      queryFn: () => sharePointApi.getSiteDocuments(query),
    }),
});
