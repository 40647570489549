import type { ReactNode } from 'react';
import { Outlet } from '@tanstack/react-router';

import { LayoutNavigation } from './LayoutNavigation';

interface Props {
  children?: ReactNode;
}

export const Layout = ({ children }: Props) => {
  return (
    <main className='flex h-dvh flex-1 p-body'>
      <LayoutNavigation />
      <section className='flex-grow ps-body'>{children || <Outlet />}</section>
    </main>
  );
};
