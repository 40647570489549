import type { PropsWithChildren } from 'react';
import { lazy, Suspense, useMemo } from 'react';
import type { QueryClient } from '@tanstack/react-query';
import { createRouter, RouterProvider } from '@tanstack/react-router';

import { useRouterTracing } from '@legalfly/reporting/tracing';
import { useTrackPageView, useTrackUserOnce } from '@legalfly/reporting/tracking';
import { LegalFlyLoader } from '@legalfly/ui/loader';
import { DefaultError } from 'components/common/errors/DefaultError';
import { DefaultNotFound } from 'components/common/errors/DefaultNotFound';
import { useAuth } from 'core/auth';
import { usersApi, useSetFetcherToken } from 'core/di';

import { FrontendWebSocketProvider } from './FrontendWebSocketProvider';
import { routeTree } from './routeTree.gen';

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null
    : lazy(() =>
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
        })),
      );

const getRouter = (queryClient: QueryClient) =>
  createRouter({
    routeTree,
    context: { queryClient, auth: undefined },
    defaultPendingComponent: () => <LegalFlyLoader />,
    defaultPreload: 'intent',
    defaultNotFoundComponent: DefaultNotFound,
    defaultErrorComponent: DefaultError,
    // Since we're using React Query, we don't want loader calls to ever be stale
    // This will ensure that the loader is always called when the route is preloaded or visited
    defaultPreloadStaleTime: 0,
  });

export type Router = ReturnType<typeof getRouter>;

declare module '@tanstack/react-router' {
  interface Register {
    router: Router;
  }
}

interface AppRouterProps extends PropsWithChildren {
  queryClient: QueryClient;
}

export const AppRouter = ({ queryClient, children }: AppRouterProps) => {
  const router = useMemo(() => getRouter(queryClient), [queryClient]);
  const auth = useAuth();

  useSetFetcherToken();
  useTrackUserOnce({ enabled: auth.isAuthenticated, fetchUserCallback: usersApi.getCurrentUser });
  useTrackPageView(router);
  useRouterTracing(router);

  if (auth.isLoading) {
    return <LegalFlyLoader showSpinner={false} />;
  }

  return (
    <FrontendWebSocketProvider>
      <RouterProvider router={router} context={{ auth }} />
      <Suspense>
        <TanStackRouterDevtools router={router} position='bottom-right' />
      </Suspense>
      {children}
    </FrontendWebSocketProvider>
  );
};
