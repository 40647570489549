import type { HTTPErrorCode } from '../core/types';

export interface ErrorResponse {
  message: string;
  description?: string;
  code: HTTPErrorCode;
  name: string;
  stack?: string;
  cause?: unknown;
  meta?: Record<string, unknown>;
}

export class FetcherError extends Error {
  public response: ErrorResponse;
  public request: Request;
  public options: RequestInit | undefined;
  public status: number;

  constructor(request: Request, response: ErrorResponse, status: number, options?: RequestInit) {
    super();

    this.name = 'FetcherError';
    this.response = response;
    this.request = request;
    this.options = options;
    this.status = status;
  }
}
