import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import type { DocumentsApi } from '@legalfly/api/documents';
import type { ApiKnowledge, KnowledgeApi } from '@legalfly/api/knowledge';

import { knowledgeQueryOptions } from './knowledgeQueryOptions';

export const createKnowledgeModule = ({
  knowledgeApi,
  documentsApi,
}: {
  knowledgeApi: KnowledgeApi;
  documentsApi: DocumentsApi;
}) => {
  const options = knowledgeQueryOptions({ knowledgeApi });

  const useKnowledgeList = ({
    query,
  }: {
    query: Parameters<KnowledgeApi['getKnowledgeList']>[0];
  }) => {
    const { data, isLoading, error } = useSuspenseQuery(options.getKnowledgeList(query));

    return {
      knowledgeList: data,
      isLoading,
      error,
    };
  };

  const useKnowledge = ({ knowledgeUuid }: { knowledgeUuid: ApiKnowledge['uuid'] }) => {
    const { data, isLoading, error } = useSuspenseQuery(options.getKnowledge({ knowledgeUuid }));

    return {
      knowledge: data,
      isLoading,
      error,
    };
  };

  const useDeleteKnowledge = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['knowledge', 'delete'],
      mutationFn: knowledgeApi.deleteKnowledge,
      onSuccess: (_, { knowledgeUuid }) => {
        queryClient.setQueriesData(
          {
            queryKey: options.getKnowledgeList().queryKey.slice(0, 1),
          },
          (data: ApiKnowledge[] | undefined) => {
            if (!data) return data;
            return data.filter((knowledge) => knowledge.uuid !== knowledgeUuid);
          },
        );
      },
    });

    return {
      deleteKnowledge: mutateAsync,
      isLoading: isPending,
    };
  };

  const useCreateKnowledge = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['knowledge', 'create'],
      mutationFn: knowledgeApi.createKnowledge,
      onSuccess: (knowledge) => {
        queryClient.setQueryData(
          options.getKnowledge({ knowledgeUuid: knowledge.uuid }).queryKey,
          knowledge,
        );

        queryClient.setQueriesData(
          { queryKey: options.getKnowledgeList().queryKey.slice(0, 1) },
          (data: ApiKnowledge[] | undefined) => {
            if (!data) return [knowledge];
            return [knowledge, ...data];
          },
        );
      },
    });

    return {
      createKnowledge: mutateAsync,
      isLoading: isPending,
    };
  };

  const useUpdateKnowledge = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['knowledge', 'update'],
      mutationFn: knowledgeApi.updateKnowledge,
      onSuccess: (knowledge) => {
        queryClient.setQueriesData(
          { queryKey: options.getKnowledgeList().queryKey.slice(0, 1) },
          (data: ApiKnowledge[] | undefined) => {
            if (!data) return [knowledge];
            return data.map((k) => (k.uuid === knowledge.uuid ? knowledge : k));
          },
        );
      },
    });

    return {
      updateKnowledge: mutateAsync,
      isLoading: isPending,
    };
  };

  const useCreateKnowledgeFile = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['knowledge', 'createFile'],
      mutationFn: documentsApi.createFile,
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: options.getKnowledgeList().queryKey.slice(0, 1),
        });
      },
    });

    return {
      createFile: mutateAsync,
      isLoading: isPending,
    };
  };

  return {
    useKnowledgeList,
    useKnowledge,
    useDeleteKnowledge,
    useCreateKnowledge,
    useUpdateKnowledge,
    useCreateKnowledgeFile,
    knowledgeQueryOptions: options,
  };
};
