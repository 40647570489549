import { createFileRoute, Link, useNavigate } from '@tanstack/react-router';

import { IconButton } from '@legalfly/ui/button';
import { Divider } from '@legalfly/ui/divider';
import { Content } from 'components/common/content/Content';
import { KnowledgeContentForm } from 'components/knowledge/knowledgeForm/KnowledgeContentForm';
import { KnowledgeFieldsForm } from 'components/knowledge/knowledgeForm/KnowledgeFieldsForm';
import { KnowledgeNameForm } from 'components/knowledge/knowledgeForm/KnowledgeNameForm';
import { useKnowledgeForm } from 'components/knowledge/knowledgeForm/useKnowledgeForm';
import { routeRedirectWithFeatureFlag } from 'core/analytics';

export const Route = createFileRoute('/_auth/_layout/knowledge/new')({
  beforeLoad: async ({ context }) => routeRedirectWithFeatureFlag(context.queryClient, 'knowledge'),
  component: RouteComponent,
});

function RouteComponent() {
  const navigate = useNavigate();

  const form = useKnowledgeForm({
    onSubmit: () => {
      navigate({
        to: '/knowledge',
      });
    },
  });

  return (
    <form
      className='flex gap-3'
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <Content className='flex-2' innerClassName='gap-8 justify-center items-center'>
        <KnowledgeContentForm form={form} />
      </Content>
      <div className='flex h-content flex-1 flex-col overflow-auto bg-fill-maximal p-6'>
        <div className='flex items-center gap-4'>
          <KnowledgeNameForm form={form} />
          <IconButton asChild name='x-close' variant='tertiary' size='sm'>
            <Link to='/knowledge' />
          </IconButton>
        </div>
        <Divider variant='weak' className='mb-5 mt-3' />
        <KnowledgeFieldsForm form={form} />
      </div>
    </form>
  );
}
