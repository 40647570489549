import { FeedbackFeature } from '@legalfly/api/feedback';
import { getKeys } from '@legalfly/utils/object';
import type { FileRouteTypes } from 'app/routeTree.gen';

// we are only interested in the base route path
type BaseRoutePath<T extends string> = T extends `/${infer First}/${string}`
  ? `/${First}`
  : T extends `/${infer First}`
    ? `/${First}`
    : T;
type BaseRoutePaths = BaseRoutePath<FileRouteTypes['fullPaths']>;

const routeToFeatureMap: Partial<Record<BaseRoutePaths, FeedbackFeature>> = {
  '/review': FeedbackFeature.Review,
  '/multi-review': FeedbackFeature.MultiReview,
  '/drafting': FeedbackFeature.Drafts,
  '/discovery': FeedbackFeature.Discovery,
  '/translate': FeedbackFeature.Translate,
  '/anonymisation': FeedbackFeature.Anonymization,
  '/documents': FeedbackFeature.Documents,
  '/settings': FeedbackFeature.Settings,
  '/playbooks': FeedbackFeature.Playbooks,
  '/agents': FeedbackFeature.Agents,
  '/knowledge': FeedbackFeature.Knowledge,
  '/': FeedbackFeature.Dashboard,
} as const;

export function getFeedbackFeatureFromPath(path: string) {
  const matchingRoute = getKeys(routeToFeatureMap).find((route) => path.startsWith(route));
  return matchingRoute ? routeToFeatureMap[matchingRoute] : FeedbackFeature.Dashboard;
}
