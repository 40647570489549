import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '@legalfly/ui/divider';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';

interface SkeletonLineProps {
  className?: string;
  width?: string;
  height?: string;
}

const SkeletonLine = ({ className, width = 'w-full', height = 'h-4' }: SkeletonLineProps) => (
  <div
    className={cn(
      height,
      width,
      'animate-skeleton rounded bg-gradient-to-r from-surface-nav via-stroke-weak to-surface-nav bg-[length:200%_100%]',
      className,
    )}
  />
);

interface Props {
  isExpanded: boolean;
}

// Simple skeleton mimicking the structure of the agent section
export const AgentNavigationSkeleton = ({ isExpanded }: Props) => {
  const { t } = useTranslation();

  const itemHeight = isExpanded ? 'h-5' : 'h-6';
  const containerPadding = isExpanded ? 'px-6' : 'px-2';

  return (
    <Fragment>
      {/* Section 1: Store Agents Skeleton */}
      <Divider variant='weak' className='my-3 block group-[.open]:hidden' />
      <div className='mb-2'>
        {isExpanded && (
          <Text
            variant='body'
            className='hidden px-6 pb-3 pt-4 text-content-body-weak group-[.open]:block'
          >
            {t('label.agents')}
          </Text>
        )}
        <div className={cn('flex flex-col gap-4', containerPadding)}>
          <SkeletonLine height={itemHeight} />
          <SkeletonLine height={itemHeight} />
          <SkeletonLine height={itemHeight} />
        </div>
      </div>

      {/* Section 2: Custom Agents Skeleton */}
      <div>
        {isExpanded && (
          <Text
            variant='body'
            className='hidden truncate px-6 pb-3 pt-4 text-content-body-weak group-[.open]:block'
          >
            {t('nav.agents')}
          </Text>
        )}
        <Divider variant='weak' className='my-3 block group-[.open]:hidden' />
        <div className={cn('flex flex-col gap-2', containerPadding)}>
          <SkeletonLine height={itemHeight} />
        </div>
      </div>
    </Fragment>
  );
};
