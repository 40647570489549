import type { ReactNode } from 'react';
import { Component } from 'react';
import { QueryErrorResetBoundary } from '@tanstack/react-query';

import { reportError } from '@legalfly/reporting/tracing';

interface ErrorBoundaryState {
  error: Error | null;
}

interface ErrorBoundaryProps {
  children: (props: { error: Error | null; resetErrorBoundary: VoidFunction }) => ReactNode;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return {
      error,
    };
  }

  componentDidCatch(error: Error) {
    reportError(error);
  }

  resetError = () => {
    this.setState({
      error: null,
    });
  };

  render(): ReactNode {
    const { error } = this.state;
    const { children } = this.props;

    return (
      <QueryErrorResetBoundary>
        {({ reset }) => {
          const handleResetErrorBoundary = () => {
            this.resetError();
            reset();
          };

          return children({ error, resetErrorBoundary: handleResetErrorBoundary });
        }}
      </QueryErrorResetBoundary>
    );
  }
}
