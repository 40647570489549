import type { ReactNode } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { FeedbackFeature } from '@legalfly/api/feedback';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@legalfly/ui/dialog';
import { VisuallyHidden } from '@legalfly/ui/visuallyHidden';

import { FeedbackForm } from './FeedbackForm';

interface Props {
  trigger: ReactNode;
  feedback?: string;
  feature?: FeedbackFeature;
}

export const FeedbackDialog = ({ trigger, feedback, feature }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      {trigger}
      <DialogContent>
        <VisuallyHidden>
          <DialogDescription>{t('dialog.feedback.description')}</DialogDescription>
        </VisuallyHidden>
        <DialogHeader>
          <DialogTitle>{t('dialog.feedback.title')}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <FeedbackForm
            afterSubmit={() => setIsOpen(false)}
            feedback={feedback}
            feature={feature}
          />
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
};

FeedbackDialog.Trigger = DialogTrigger;
