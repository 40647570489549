// Types and interfaces
export interface ValidationError {
  path: string[];
  message: string;
}

export interface ValidationResult<T> {
  success: boolean;
  data: T | null;
  errors?: ValidationError[];
}

export type Validator<T> = (value: unknown, path: string[]) => ValidationResult<T>;

// Helper functions
function createError(path: string[], message: string): ValidationError[] {
  return [{ path, message }];
}

function getFieldName(path: string[]): string {
  return path[path.length - 1] ?? 'Value';
}

// Validators
export function string({ minLength }: { minLength?: number } = {}): Validator<string> {
  return (value: unknown, path: string[]): ValidationResult<string> => {
    if (typeof value !== 'string') {
      return {
        success: false,
        data: null,
        errors: createError(path, `${getFieldName(path)} must be a string`),
      };
    }

    if (minLength && value.length < minLength) {
      return {
        success: false,
        data: null,
        errors: createError(path, `${getFieldName(path)} must be at least ${minLength} characters`),
      };
    }

    return { success: true, data: value };
  };
}

export function literal<T extends string>(allowedValues: readonly T[]): Validator<T> {
  return (value: unknown, path: string[]): ValidationResult<T> => {
    if (typeof value !== 'string') {
      return {
        success: false,
        data: null,
        errors: createError(path, `${getFieldName(path)} must be a string`),
      };
    }

    if (!allowedValues.includes(value as T)) {
      return {
        success: false,
        data: null,
        errors: createError(
          path,
          `${getFieldName(path)} must be one of: ${allowedValues.join(', ')}`,
        ),
      };
    }

    return { success: true, data: value as T };
  };
}

export function object<T extends Record<string, unknown>>(schema: {
  [K in keyof T]: Validator<T[K]>;
}): Validator<T> {
  return (value: unknown, parentPath: string[] = []): ValidationResult<T> => {
    if (typeof value !== 'object' || value === null) {
      return {
        success: false,
        data: null,
        errors: createError(parentPath, `${getFieldName(parentPath)} must be an object`),
      };
    }

    const errors: ValidationError[] = [];
    const result: Partial<T> = {};

    for (const [key, validator] of Object.entries(schema)) {
      const currentPath = [...parentPath, key];
      const fieldValue = (value as Record<string, unknown>)[key];

      const fieldResult = validator(fieldValue, currentPath);

      if (!fieldResult.success) {
        errors.push(...(fieldResult.errors ?? []));
      } else {
        result[key as keyof T] = fieldResult.data as T[keyof T];
      }
    }

    if (errors.length > 0) {
      return { success: false, data: null, errors };
    }

    return { success: true, data: result as T };
  };
}

export function boolean(): Validator<boolean> {
  return (value: unknown, path: string[]): ValidationResult<boolean> => {
    if (typeof value !== 'boolean' && value !== 'true' && value !== 'false') {
      return {
        success: false,
        data: null,
        errors: createError(
          path,
          `${getFieldName(path)} must be a boolean or 'true'/'false' string`,
        ),
      };
    }

    return { success: true, data: value === true || value === 'true' };
  };
}

export function optional<T>(validator: Validator<T>): Validator<T | undefined> {
  return (value: unknown, path: string[]): ValidationResult<T | undefined> => {
    if (value === undefined || value === '') {
      return { success: true, data: undefined };
    }

    return validator(value, path);
  };
}

export function array<T>(itemValidator: Validator<T>): Validator<T[]> {
  return (value: unknown, path: string[]): ValidationResult<T[]> => {
    if (!Array.isArray(value)) {
      return {
        success: false,
        data: null,
        errors: createError(path, `${getFieldName(path)} must be an array`),
      };
    }

    const errors: ValidationError[] = [];
    const result: T[] = [];

    value.forEach((item, index) => {
      const itemResult = itemValidator(item, [...path, index.toString()]);
      if (!itemResult.success) {
        errors.push(...(itemResult.errors ?? []));
      } else if (itemResult.data !== null) {
        result.push(itemResult.data);
      }
    });

    if (errors.length > 0) {
      return { success: false, data: null, errors };
    }

    return { success: true, data: result };
  };
}

export function number(): Validator<number> {
  return (value: unknown, path: string[]): ValidationResult<number> => {
    if (typeof value === 'number') {
      return { success: true, data: value };
    }

    if (typeof value !== 'string') {
      return {
        success: false,
        data: null,
        errors: createError(path, `${getFieldName(path)} must be a number or numeric string`),
      };
    }

    const parsed = Number(value);
    if (isNaN(parsed)) {
      return {
        success: false,
        data: null,
        errors: createError(path, `${getFieldName(path)} must be a valid number`),
      };
    }

    return { success: true, data: parsed };
  };
}
