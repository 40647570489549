import { useTranslation } from 'react-i18next';
import { createFileRoute } from '@tanstack/react-router';
import { useSortableTable } from 'common/hooks/useSortableTable';
import { createSortableRoute } from 'common/utils/routeUtils';

import type { AnonymizationSortField } from '@legalfly/api/anonymization';
import { DEFAULT_ANONYMIZATION_SORT } from '@legalfly/api/anonymization';
import { trackEvent } from '@legalfly/reporting/tracking';
import { UploadButton } from '@legalfly/ui/button';
import { DataTable } from '@legalfly/ui/data-table/DataTable';
import { Icon } from '@legalfly/ui/icon';
import { withToasts } from '@legalfly/ui/toast';
import { useTableHeight } from '@legalfly/utils/hooks/useTableHeight';
import { useAnonymizationColumns } from 'components/anonymization/useAnonymizationColumns';
import { ContentDropZone } from 'components/common/content/ContentDropZone';
import { ContentHeader } from 'components/common/content/ContentHeader';
import { DropZone } from 'components/common/upload/DropZone';
import {
  anonymizationQueryOptions,
  useAnonymizations,
  useCreateAnonymization,
} from 'core/modules/anonymization';
import { documentsToasts } from 'core/modules/documents';
import { useCreateFiles } from 'core/modules/documents/helpers/useCreateFiles';

const { validateSearch, loaderDeps } = createSortableRoute<AnonymizationSortField>({
  defaultSort: DEFAULT_ANONYMIZATION_SORT,
});

export const Route = createFileRoute('/_auth/_layout/anonymisation/')({
  component: AnonymisationsRoute,
  validateSearch,
  loaderDeps,
  loader({ context, deps: { sortBy, sortOrder } }) {
    return context.queryClient.ensureQueryData(
      anonymizationQueryOptions.anonymizations({ sortBy, sortOrder }),
    );
  },
});

function AnonymisationsRoute() {
  const { t } = useTranslation();
  const navigate = Route.useNavigate();
  const sorting = Route.useSearch();

  const { currentSorting, onSort } = useSortableTable<AnonymizationSortField>({
    sorting,
    defaultSortField: DEFAULT_ANONYMIZATION_SORT.sortBy,
    navigate,
  });

  const { tableHeight, tableRef } = useTableHeight();
  const columns = useAnonymizationColumns();

  const { anonymizations } = useAnonymizations({
    sortBy: sorting.sortBy,
    sortOrder: sorting.sortOrder,
  });

  const { createFiles, isCreatingFiles } = useCreateFiles('anonymization');
  const { createAnonymization, isLoading: isCreatingAnonymization } = useCreateAnonymization();

  const uploadFiles = async (files: File[]) => {
    const { documents } = await createFiles({ uuid: undefined, files });

    if (!documents.length) return;

    const anonymization = await withToasts(
      createAnonymization({
        body: {
          documentUuids: documents.map((document) => document.uuid),
        },
      }),
    )(documentsToasts.createAnonymization());

    navigate({
      to: '/anonymisation/$uuid',
      params: { uuid: anonymization.uuid },
    });
  };

  const hasAnonymizations = anonymizations.length > 0;

  return (
    <ContentDropZone
      onDropFiles={(files: File[]) => {
        trackEvent({
          action: 'drop',
          category: 'anonymisation',
        });
        uploadFiles(files);
      }}
      parentClassName='flex-1'
      disabled={!hasAnonymizations}
    >
      <ContentHeader>
        <ContentHeader.Title>{t('anonymisation.title')}</ContentHeader.Title>
        <ContentHeader.SubTitle>{t('anonymisation.subtitle')}</ContentHeader.SubTitle>
        <ContentHeader.Actions>
          <UploadButton
            onUpload={uploadFiles}
            renderLeft={<Icon name='upload-1' />}
            isLoading={isCreatingFiles || isCreatingAnonymization}
            onClick={() => {
              trackEvent({
                action: 'click',
                category: 'anonymisation',
                label: 'upload',
              });
            }}
          >
            {t('action.uploadOrDrop')}
          </UploadButton>
        </ContentHeader.Actions>
      </ContentHeader>
      {hasAnonymizations ? (
        <DataTable
          columns={columns}
          data={anonymizations}
          sorting={currentSorting}
          onSort={onSort}
          ref={tableRef}
          tableHeight={tableHeight}
          noResultsLabel={t('anonymisation.table.noResults')}
        />
      ) : (
        <DropZone
          withBackground
          onDropFiles={(files: File[]) => {
            trackEvent({
              action: 'drop',
              category: 'anonymisation',
            });
            uploadFiles(files);
          }}
          renderActions={() => (
            <div className='flex flex-col gap-3'>
              <UploadButton
                onUpload={uploadFiles}
                isLoading={isCreatingFiles || isCreatingAnonymization}
                renderLeft={<Icon name='upload-1' />}
                onClick={() => {
                  trackEvent({
                    action: 'click',
                    category: 'anonymisation',
                    label: 'upload',
                  });
                }}
              >
                {t('action.uploadOrDrop')}
              </UploadButton>
            </div>
          )}
        />
      )}
    </ContentDropZone>
  );
}
