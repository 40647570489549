import { createFileRoute, useRouter } from '@tanstack/react-router';

import { SidePaneProvider } from '@legalfly/ui/sidePane';
import { DocumentActions } from 'components/documents/detail/DocumentActions';
import { DocumentProvider } from 'components/documents/detail/DocumentProvider';
import { DocumentViewerWithAnonymisation } from 'components/documents/detail/DocumentViewer';
import { documentsQueryOptions, useFile } from 'core/modules/documents';

export const Route = createFileRoute('/_auth/_layout/anonymisation/file/$uuid')({
  component: AnonymisationFileRoute,
  loader({ context, params }) {
    return context.queryClient.ensureQueryData(documentsQueryOptions.file(params.uuid));
  },
});

function AnonymisationFileRoute() {
  const { uuid } = Route.useParams();
  const { history } = useRouter();

  const { file } = useFile(uuid);

  return (
    <DocumentProvider document={file}>
      <DocumentActions>
        <DocumentActions.BackButton onClick={() => history.go(-1)} />
        <DocumentActions.Container className='justify-end'>
          <DocumentActions.DownloadMenu />
          <DocumentActions.AnonymousButton />
        </DocumentActions.Container>
      </DocumentActions>
      <SidePaneProvider defaultOpen>
        <div className='h-content flex-1 overflow-auto bg-fill-maximal px-content-x py-content-y'>
          <DocumentViewerWithAnonymisation />
        </div>
      </SidePaneProvider>
    </DocumentProvider>
  );
}
