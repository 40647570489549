import { createFileRoute, useRouter } from '@tanstack/react-router';

import { SelectedDocumentsProvider } from '@legalfly/components/documentPicker/SelectedDocumentsProvider';
import { getDocumentIcon } from '@legalfly/components/documents/helpers';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { DocumentActions } from 'components/documents/detail/DocumentActions';
import { DocumentProvider } from 'components/documents/detail/DocumentProvider';
import { DocumentViewer } from 'components/documents/detail/DocumentViewer';
import { DocumentsDetailPane } from 'components/documents/detailPane/DocumentsDetailPane';
import { documentsQueryOptions, useFile } from 'core/modules/documents';

export const Route = createFileRoute('/_auth/_layout/documents/file/$uuid')({
  loader: ({ context, params }) =>
    context.queryClient.ensureQueryData(documentsQueryOptions.file(params.uuid)),
  component: FileRoute,
});

function FileRoute() {
  const { uuid } = Route.useParams();
  const { file } = useFile(uuid);
  const { history } = useRouter();

  return (
    <DocumentProvider document={file}>
      <DocumentActions>
        <DocumentActions.BackButton onClick={() => history.go(-1)} />
        <DocumentActions.Container className='justify-end'>
          <DocumentActions.AnonymousButton />
        </DocumentActions.Container>
      </DocumentActions>
      <div className='flex h-content gap-3'>
        <div className='flex-1 overflow-auto bg-fill-maximal px-content-x py-content-y'>
          <DocumentViewer />
        </div>
        <SelectedDocumentsProvider>
          <div className='w-side-pane-sm overflow-auto bg-fill-maximal p-6'>
            <div className='flex gap-2'>
              <Icon name={getDocumentIcon(file)} />
              <Text as='h3'>{file?.name}</Text>
            </div>
            <DocumentsDetailPane documents={[file]} showOpenDocument={false} />
          </div>
        </SelectedDocumentsProvider>
      </div>
    </DocumentProvider>
  );
}
