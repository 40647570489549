import { Jurisdiction } from '@legalfly/api/core';
import { useCurrentUserSettings } from 'core/modules/settings';

export const useDefaultJurisdiction = () => {
  const { data: jurisdiction } = useCurrentUserSettings({
    select: (data) => data.jurisdiction,
  });

  return jurisdiction ?? Jurisdiction.NONE;
};
