import { useTranslation } from 'react-i18next';
import { createFileRoute, Link, useNavigate } from '@tanstack/react-router';
import { useKeywordFilter } from 'common/hooks/useKeywordFilter';

import type { ApiDocument } from '@legalfly/api/documents';
import { useSelectedDocuments } from '@legalfly/components/documentPicker/SelectedDocumentsProvider';
import { withSelectedDocuments } from '@legalfly/components/documentPicker/withSelectedDocuments';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button, UploadButton } from '@legalfly/ui/button';
import { SearchInputForm } from '@legalfly/ui/form';
import { Icon } from '@legalfly/ui/icon';
import { withToasts } from '@legalfly/ui/toast';
import { ContentDropZone } from 'components/common/content/ContentDropZone';
import { ContentHeader } from 'components/common/content/ContentHeader';
import { DropZone } from 'components/common/upload/DropZone';
import CreateFolderDialog from 'components/documents/createFolder/CreateFolderDialog';
import { DocumentPicker } from 'components/documents/documentPicker/DocumentPicker';
import { EditableFolderName } from 'components/documents/EditableFolderName';
import {
  documentsQueryOptions,
  documentsToasts,
  ListenToDocumentsUploadStatus,
  useDropDocuments,
  useFolder,
} from 'core/modules/documents';
import { useCreateFiles } from 'core/modules/documents/helpers/useCreateFiles';

export const Route = createFileRoute('/_auth/_layout/review/start/')({
  component: withSelectedDocuments(ReviewStartRoute),
  loader({ context }) {
    return context.queryClient.ensureQueryData(documentsQueryOptions.folder());
  },
});

export function ReviewStartRoute({ uuid }: { uuid?: string }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { selectedDocuments, toggleSelectedDocument } = useSelectedDocuments();
  const { dropDocuments } = useDropDocuments();
  const { folder, documents } = useFolder({ uuid });
  const { filteredItems: filteredDocuments, setKeyword } = useKeywordFilter(documents, 'name');

  const { createFiles, isCreatingFiles } = useCreateFiles('review');

  const uploadFiles = async (files: File[]) => {
    const { documents } = await createFiles({ uuid, files });
    const [document] = documents;

    if (document) {
      navigate({
        to: '/review/new',
        search: {
          documentUuid: document.uuid,
        },
      });

      toggleSelectedDocument(document);
    }
  };

  const handleBackPress = () => {
    if (folder?.parent) {
      return navigate({
        to: '/review/start/$uuid',
        params: { uuid: folder.parent.uuid },
      });
    }

    return navigate({
      to: '/review/start',
    });
  };

  const handleSelectFolder = (document: ApiDocument) => {
    navigate({
      to: '/review/start/$uuid',
      params: { uuid: document.uuid },
    });
  };

  const handleDropDocuments = (params: { sourceUuids: string[]; targetUuid: string }) => {
    withToasts(dropDocuments({ body: { uuid: folder?.uuid, ...params } }))(
      documentsToasts.dropDocuments(),
    );
  };

  const selectedDocument = selectedDocuments[0];
  const hasSelectedDocument = Boolean(selectedDocument);
  const hasDocuments = documents.length > 0;
  const canGoBack = Boolean(documents[0]?.parent) || Boolean(!hasDocuments && uuid);

  return (
    <ContentDropZone
      onDropFiles={(files: File[]) => {
        trackEvent({
          action: 'drop',
          category: 'reviewStart',
        });
        uploadFiles(files);
      }}
      disabled={!hasDocuments}
    >
      <ContentHeader>
        <ContentHeader.Title>
          {folder ? (
            <EditableFolderName uuid={folder.uuid} name={folder.name} />
          ) : (
            t('review.start.title')
          )}
        </ContentHeader.Title>
        <ContentHeader.SubTitle>{t('review.start.subtitle')}</ContentHeader.SubTitle>
        <ContentHeader.Actions>
          {canGoBack && (
            <Button onClick={handleBackPress} variant='soft'>
              {t('action.back')}
            </Button>
          )}
          <SearchInputForm
            placeholder={t('label.search')}
            onKeywordChange={setKeyword}
            onBlur={() =>
              trackEvent({
                action: 'blur',
                category: 'reviewStart',
                label: 'search',
              })
            }
            disabled={!hasDocuments}
          />
          <Link
            to='/review/new'
            search={{ documentUuid: selectedDocument?.uuid as string }}
            disabled={!hasSelectedDocument}
          >
            <Button renderLeft={<Icon name='check' />} disabled={!hasSelectedDocument}>
              {t('action.start')}
            </Button>
          </Link>
          <UploadButton
            onUpload={uploadFiles}
            renderLeft={<Icon name='upload-1' />}
            isLoading={isCreatingFiles}
            isSingle
            onClick={() =>
              trackEvent({
                action: 'click',
                category: 'reviewStart',
                label: 'upload',
              })
            }
          >
            {t('review.start.upload')}
          </UploadButton>
          <CreateFolderDialog uuid={uuid} />
        </ContentHeader.Actions>
      </ContentHeader>

      {hasDocuments ? (
        <div className='mb-4 h-full'>
          <ListenToDocumentsUploadStatus documents={documents} />

          <DocumentPicker
            allowMultiple={false}
            documents={filteredDocuments}
            onSelectFolder={handleSelectFolder}
            onDropDocuments={handleDropDocuments}
          />
        </div>
      ) : (
        <DropZone
          onDropFiles={(files: File[]) => {
            trackEvent({
              action: 'drop',
              category: 'reviewStart',
            });
            uploadFiles(files);
          }}
          withBackground
          isSingle
        />
      )}
    </ContentDropZone>
  );
}
