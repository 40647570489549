import { type ComponentProps, type Dispatch, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiSharePointSite } from '@legalfly/api/sharePoint/SharePointApi';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@legalfly/ui/table';
import { Text } from '@legalfly/ui/text';
import { useSheetTableHeight } from '@legalfly/utils/hooks/useTableHeight';
import { useSharePointSites } from 'core/modules/integrations/sharePoint';

import { ImportSharePointSheetDocumentPicker } from './ImportSharePointSheetDocumentPicker';

type Props = Pick<
  ComponentProps<typeof ImportSharePointSheetDocumentPicker>,
  'selectedDocuments' | 'setSelectedDocuments'
> & {
  activeSite: ApiSharePointSite | undefined;
  setActiveSite: Dispatch<SetStateAction<ApiSharePointSite | undefined>>;
};

export const ImportSharePointSheetContent = ({ activeSite, setActiveSite, ...props }: Props) => {
  const { t } = useTranslation();
  const { tableHeight, tableRef } = useSheetTableHeight();

  const { sites } = useSharePointSites();

  if (!sites?.length) {
    return (
      <div className='my-6'>
        <Text>{t('documents.sharePointSheet.sites.empty')}</Text>
      </div>
    );
  }

  if (activeSite) {
    return (
      <ImportSharePointSheetDocumentPicker
        activeSite={activeSite}
        onBackPress={() => setActiveSite(undefined)}
        {...props}
      />
    );
  }

  return (
    <Table style={{ maxHeight: tableHeight }} ref={tableRef} parentClassName='mt-6 h-fit'>
      <TableHeader className='sticky top-0 z-10 bg-fill-maximal'>
        <TableRow className='shadow-table-border'>
          <TableHead className='min-w-64'>
            {t('documents.sharePointSheet.sites.columns.name')}
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {sites.map((site) => {
          const handleOnClick = () => {
            setActiveSite(site);
          };

          return (
            <TableRow key={site.id} className='cursor-pointer'>
              <TableCell onClick={handleOnClick}>{site.name}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
