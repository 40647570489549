import { queryOptions } from '@tanstack/react-query';

import type { ApiKnowledge, KnowledgeApi } from '@legalfly/api/knowledge';

export const knowledgeQueryOptions = ({ knowledgeApi }: { knowledgeApi: KnowledgeApi }) => ({
  getKnowledgeList: (query?: Parameters<KnowledgeApi['getKnowledgeList']>[0]) =>
    queryOptions({
      queryKey: ['knowledge-list', query],
      queryFn: () => knowledgeApi.getKnowledgeList(query),
    }),
  getKnowledge: ({ knowledgeUuid }: { knowledgeUuid: ApiKnowledge['uuid'] }) =>
    queryOptions({
      queryKey: ['knowledge', knowledgeUuid],
      queryFn: () => knowledgeApi.getKnowledge({ knowledgeUuid }),
    }),
});
