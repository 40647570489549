import { legalFlyConfig } from '@legalfly/config';

import type { BodyOf, ParamsOf, ResponseOf } from '../../client';
import { createClient } from '../../client';
import type { Fetcher } from '../../fetcher/Fetcher';

export const createSharePointApi = (fetcher: Fetcher) => {
  const client = createClient(fetcher);

  const api = legalFlyConfig.api.paths;

  const signIn = () => {
    return client(api.integrations.sharePoint.auth.signIn, { method: 'POST' });
  };

  const disconnect = () => {
    return client(api.integrations.sharePoint.auth.disconnect, { method: 'POST' });
  };

  const exchangeCode = (body: BodyOf<'integrations/sharepoint/auth/exchange-code/post'>) => {
    return client(api.integrations.sharePoint.auth.exchangeCode, { method: 'POST', body });
  };

  const getSites = () => {
    return client(api.integrations.sharePoint.sites.list, { method: 'GET' });
  };

  const getSiteDocuments = (
    params: ParamsOf<'integrations/sharepoint/sites/:siteId/documents/get'>,
  ) => {
    const url = params.folderId
      ? api.integrations.sharePoint.sites.documents.folder
      : api.integrations.sharePoint.sites.documents.root;

    return client(url, {
      method: 'GET',
      params,
    });
  };

  const downloadSiteDocument = ({
    body,
    siteId,
  }: ParamsOf<'integrations/sharepoint/sites/:siteId/documents/download/post'> & {
    body: BodyOf<'integrations/sharepoint/sites/:siteId/documents/download/post'>;
  }) => {
    return client(api.integrations.sharePoint.sites.documents.download, {
      method: 'POST',
      params: {
        siteId,
      },
      body,
    });
  };

  return {
    signIn,
    exchangeCode,
    disconnect,
    getSites,
    getSiteDocuments,
    downloadSiteDocument,
  };
};

export type SharePointApi = ReturnType<typeof createSharePointApi>;
export type ApiSharePointSite = ResponseOf<'integrations/sharepoint/sites/get'>[number];
export type ApiSharePointDocument =
  ResponseOf<'integrations/sharepoint/sites/:siteId/documents/get'>['documents'][number];
