import { createFileRoute } from '@tanstack/react-router';

import { TranslateFilesBox } from 'components/translate/files/TranslateFilesBox';
import { routeRedirectWithFeatureFlag } from 'core/analytics';
import { translateQueryOptions } from 'core/modules/translate';

export const Route = createFileRoute('/_auth/_layout/translate/_layout/files/')({
  beforeLoad: async ({ context }) =>
    routeRedirectWithFeatureFlag(context.queryClient, 'translate-file-agent'),
  component: TranslateFilesRoute,
  loader: async ({ context }) =>
    context.queryClient.ensureQueryData(translateQueryOptions.translationUsage()),
});

function TranslateFilesRoute() {
  return <TranslateFilesBox />;
}
