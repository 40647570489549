import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  ColumnDef,
  Row,
  RowSelectionState,
  SortingState,
  Updater,
} from '@tanstack/react-table';

import { type ApiDocument, DocumentStatus } from '@legalfly/api/documents';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button } from '@legalfly/ui/button';
import { DataTable } from '@legalfly/ui/data-table/DataTable';
import { Icon } from '@legalfly/ui/icon';

import { isDocumentFolder } from '../documents/helpers';
import { useSelectedDocuments } from './SelectedDocumentsProvider';

interface ColumnActions {
  allowMultiple?: boolean;
  allowSelection?: boolean;
  allowContextMenu?: boolean;
  allowFavorite?: boolean;
  allowDelete?: boolean;
}

export interface UseColumnsProps extends Required<ColumnActions> {
  onDocumentClick: (document: ApiDocument) => void;
  onDocumentDoubleClick?: (document: ApiDocument) => void;
}

type DocumentsProps = {
  documents: ApiDocument[];
  tableRef: React.RefObject<HTMLTableElement | null>;
  tableHeight: number;
  allowFolderSelection?: boolean;
  useColumns: (props: UseColumnsProps) => ColumnDef<ApiDocument>[];
  onSelectFolder?: (document: ApiDocument) => void;
  onDoubleClickFolder?: (document: ApiDocument) => void;
  onBackPress?: () => void;
  onDropDocuments?: (params: { sourceUuids: string[]; targetUuid: string }) => void;
  sorting?: SortingState;
  onSort?: (updaterOrValue: SortingState | ((old: SortingState) => SortingState)) => void;
} & ColumnActions;

export const BaseDocumentPicker = ({
  documents,
  tableRef,
  tableHeight,
  useColumns,
  onSelectFolder,
  onDoubleClickFolder,
  onDropDocuments,
  onBackPress,
  allowDelete = true,
  allowFavorite = true,
  allowMultiple = true,
  allowSelection = true,
  allowFolderSelection = false,
  allowContextMenu = false,
  sorting,
  onSort,
}: DocumentsProps) => {
  const { t } = useTranslation('components');

  const { selectedDocuments, setDocuments, clearSelectedDocuments, toggleSelectedDocument } =
    useSelectedDocuments();

  const handleDocumentClick = useCallback(
    (document: ApiDocument) => {
      if (isDocumentFolder(document)) {
        if (onSelectFolder) {
          onSelectFolder(document);
          clearSelectedDocuments();
          return;
        }

        toggleSelectedDocument(document);
        return;
      }

      if (document.status !== DocumentStatus.COMPLETE) {
        return;
      }

      if (allowMultiple) {
        toggleSelectedDocument(document);
      } else {
        setDocuments([document]);
      }
    },
    [onSelectFolder, clearSelectedDocuments, toggleSelectedDocument, allowMultiple, setDocuments],
  );

  const handleDocumentDoubleClick = useCallback(
    (document: ApiDocument) => {
      if (isDocumentFolder(document)) {
        onDoubleClickFolder?.(document);
      }
    },
    [onDoubleClickFolder],
  );

  const columns = useColumns({
    onDocumentClick: handleDocumentClick,
    onDocumentDoubleClick: handleDocumentDoubleClick,
    allowMultiple,
    allowSelection,
    allowContextMenu,
    allowDelete,
    allowFavorite,
  });

  // Tanstack table just needs a record of selected documents
  const rowSelection = useMemo(() => {
    return selectedDocuments.reduce((acc, document) => {
      acc[document.uuid] = true;
      return acc;
    }, {} as RowSelectionState);
  }, [selectedDocuments]);

  const handleRowSelectionChange = useCallback(
    (valueFn: Updater<RowSelectionState>) => {
      if (typeof valueFn !== 'function') {
        return;
      }

      const updatedDocumentIds = valueFn(rowSelection);

      const updatedDocuments = Object.keys(updatedDocumentIds)
        .map((uuid) => {
          return documents.find((document) => document.uuid === uuid);
        })
        .filter(Boolean) as ApiDocument[];

      setDocuments(allowMultiple ? updatedDocuments : updatedDocuments.slice(-1));
    },
    [rowSelection, documents, setDocuments, allowMultiple],
  );

  const shouldEnableRowSelection = useCallback(
    (row: Row<ApiDocument>) => {
      if (isDocumentFolder(row.original)) {
        return allowFolderSelection;
      }

      return row.original.status === DocumentStatus.COMPLETE;
    },
    [allowFolderSelection],
  );

  const handleDropDocuments = (params: { sourceUuids: string[]; targetUuid: string }) => {
    trackEvent({
      action: 'move',
      category: 'documents',
      label: 'drop',
    });
    onDropDocuments?.(params);
  };

  return (
    <div className='h-full'>
      {typeof onBackPress === 'function' && (
        <Button className='mb-4' onClick={onBackPress} renderLeft={<Icon name='chevron-left' />}>
          {t('action.back')}
        </Button>
      )}
      <DataTable
        ref={tableRef}
        data={documents}
        columns={columns}
        tableHeight={tableHeight}
        sorting={sorting ?? [{ id: 'name', desc: false }]}
        onSort={onSort}
        state={{
          rowSelection,
        }}
        noResultsLabel={t('label.noResults')}
        onRowSelectionChange={handleRowSelectionChange}
        enableRowSelection={shouldEnableRowSelection}
        getRowId={(row) => row.uuid}
        isDroppable={isDocumentFolder}
        onDrop={handleDropDocuments}
      />
    </div>
  );
};
