import { useEffect } from 'react';
import type { AnyRouter } from '@tanstack/react-router';
import { posthog } from 'posthog-js';

import type { ApiCurrentUser } from '@legalfly/api/users';
import type { LegalFlyEnvironment } from '@legalfly/config';

import type { TrackingEventAction, TrackingEventCategory } from './types';

interface InitAnalyticsProps {
  environment: LegalFlyEnvironment;
  token: string;
  project: string;
}

let trackingOptions: InitAnalyticsProps | undefined;

export const initAnalytics = (options: InitAnalyticsProps) => {
  trackingOptions = options;
  if (!options.token || options.environment === 'local') return;

  posthog.init(options.token, {
    api_host: 'https://eu.i.posthog.com',
    person_profiles: 'identified_only',
    autocapture: true,
    enable_heatmaps: true,
  });

  posthog.register({
    project: options.project,
  });
};

export const resetAnalytics = () => {
  if (!posthog.__loaded) return;

  posthog.reset();
};

export const trackUser = (user: ApiCurrentUser) => {
  if (!posthog.__loaded) return;

  if (trackingOptions?.environment !== 'production') {
    posthog.identify(user.uuid, {
      $email: user.email,
      $name: `${user.firstName} ${user.lastName}`,
      firstName: user.firstName,
      lastName: user.lastName,
      organization: user.organization.uuid,
    });
  } else {
    posthog.identify(user.uuid, {
      organization: user.organization.uuid,
    });
  }
};

export const trackPageView = () => {
  if (!posthog.__loaded) return;

  posthog.capture('$pageview');
};

export interface TrackingEvent {
  action: TrackingEventAction;
  category: TrackingEventCategory;
  label?: string;
}

export const trackEvent = (event: TrackingEvent, options?: Record<string, any>) => {
  if (trackingOptions?.environment === 'local') console.log('🦧', { ...event, ...options });
  if (!posthog.__loaded) return;

  const name = `${event.action}_${event.category}${event.label ? `_${event.label}` : ''}`;
  posthog.capture(name, { ...event, ...options });
};

export const useTrackPageView = (router: AnyRouter) => {
  useEffect(() => {
    const onRouteResolved = () => {
      trackPageView();
    };

    const unsubscribe = router.subscribe('onLoad', onRouteResolved);

    return unsubscribe;
  }, [router]);
};

interface UseTrackUserOnceProps {
  enabled: boolean;
  fetchUserCallback: () => Promise<ApiCurrentUser>;
}

export const useTrackUserOnce = ({ enabled, fetchUserCallback }: UseTrackUserOnceProps) => {
  useEffect(() => {
    if (!posthog.__loaded || !enabled) return;

    fetchUserCallback().then((user) => {
      trackUser(user);
    });
  }, [enabled]);
};

export * from './types';
