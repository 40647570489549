import { Suspense, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiDocument } from '@legalfly/api/documents';
import type {
  ApiSharePointDocument,
  ApiSharePointSite,
} from '@legalfly/api/sharePoint/SharePointApi';
import { ErrorBoundary } from '@legalfly/components/errors/ErrorBoundary';
import { Button } from '@legalfly/ui/button';
import { Divider } from '@legalfly/ui/divider';
import { Icon } from '@legalfly/ui/icon';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@legalfly/ui/sheet';
import { Spinner } from '@legalfly/ui/spinner';
import { Text } from '@legalfly/ui/text';
import { useFeatureFlag } from 'core/analytics';
import { useDownloadSharePointDocuments } from 'core/modules/integrations/sharePoint/useDownloadSharePointDocuments';
import { useCurrentUserSettings } from 'core/modules/settings';

import { ImportSharePointSheetContent } from './ImportSharePointSheetContent';
import { SharePointDisconnected } from './SharePointDisconnected';

interface Props {
  folderUuid: ApiDocument['uuid'] | undefined;
}

export const ImportSharePointSheet = ({ folderUuid }: Props) => {
  const { t } = useTranslation();
  const isSharePointEnabled = useFeatureFlag('sharepoint');
  const { data: isConnectedToSharePoint } = useCurrentUserSettings({
    select: (data) => data.isConnectedToSharePoint,
  });

  const [isOpen, setIsOpen] = useState(false);
  const [activeSite, setActiveSite] = useState<ApiSharePointSite | undefined>(undefined);
  const [selectedDocuments, setSelectedDocuments] = useState<ApiSharePointDocument[]>([]);

  const { downloadDocuments } = useDownloadSharePointDocuments();

  const handleAddDocuments = useCallback(() => {
    setIsOpen(false);
    setSelectedDocuments([]);

    if (!activeSite) return;

    downloadDocuments({
      documents: selectedDocuments,
      destinationFolderUuid: folderUuid,
      siteId: activeSite.id,
    });
  }, [activeSite, downloadDocuments, folderUuid, selectedDocuments]);

  if (!isSharePointEnabled || !isConnectedToSharePoint) {
    return null;
  }

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild>
        <Button renderLeft={<Icon name='share-point' />} size='sm' variant='soft'>
          {t('action.sharePoint')}
        </Button>
      </SheetTrigger>
      <SheetContent
        style={{
          height: 'calc(100% - var(--lf-body-padding) * 2)',
        }}
        className='my-3 max-w-[90%] overflow-visible bg-fill-maximal sm:max-w-[80%] md:max-w-[60%] lg:max-w-[40%]'
      >
        <SheetHeader className='bg-fill-maximal'>
          <SheetTitle>{t('documents.sharePointSheet.title')}</SheetTitle>
          <SheetClose />
        </SheetHeader>
        <div className='px-6'>
          <Divider variant='weak' className='my-3' />
          <Text variant='bodyLight'>{t('documents.sharePointSheet.description')}</Text>
        </div>
        <ErrorBoundary>
          {({ error, resetErrorBoundary }) => {
            if (error) {
              return <SharePointDisconnected resetErrorBoundary={resetErrorBoundary} />;
            }

            return (
              <Suspense fallback={<Spinner size='md' className='mt-10' />}>
                <div className='px-6'>
                  <ImportSharePointSheetContent
                    activeSite={activeSite}
                    setActiveSite={setActiveSite}
                    selectedDocuments={selectedDocuments}
                    setSelectedDocuments={setSelectedDocuments}
                  />
                  <Divider variant='weak' className='mb-3' />
                </div>
                <SheetFooter className='flex gap-2.5 pb-3' id='table-footer'>
                  <SheetClose asChild>
                    <Button variant='soft' className='flex w-full justify-center'>
                      {t('action.cancel')}
                    </Button>
                  </SheetClose>
                  {activeSite && (
                    <Button
                      variant='soft'
                      className='flex w-full justify-center'
                      onClick={handleAddDocuments}
                    >
                      {t('action.addDocuments')}
                    </Button>
                  )}
                </SheetFooter>
              </Suspense>
            );
          }}
        </ErrorBoundary>
      </SheetContent>
    </Sheet>
  );
};
