import type { BaseSorting } from '../core';
import type { ApiKnowledge } from './KnowledgeApi';

export const KNOWLEDGE_SORT_CONFIG = {
  name: 'name',
  type: 'type',
  owner: 'owner.firstName',
  createdAt: 'createdAt',
} as const;

export type KnowledgeSortField = keyof typeof KNOWLEDGE_SORT_CONFIG;
export type MappedKnowledgeSortField = (typeof KNOWLEDGE_SORT_CONFIG)[KnowledgeSortField];

export const DEFAULT_KNOWLEDGE_SORT: BaseSorting<KnowledgeSortField> = {
  sortBy: 'createdAt',
  sortOrder: 'DESC',
} as const;

export const knowledgeTypes = [
  'clause',
  'document',
  'communication',
  'legislation',
  'policy',
] as const;
export type KnowledgeType = (typeof knowledgeTypes)[number];

export const knowledgeOriginTypes = ['document'] as const;
export type KnowledgeOriginType = (typeof knowledgeOriginTypes)[number];

export const documentSubTypes = ['internal_policy', 'contract', 'invoice', 'email'] as const;
export type DocumentSubType = (typeof documentSubTypes)[number];

export const communicationSubTypes = ['email', 'letter'] as const;
export type CommunicationSubType = (typeof communicationSubTypes)[number];

export const legislationSubTypes = ['regulation', 'case_law', 'statute'] as const;
export type LegislationSubType = (typeof legislationSubTypes)[number];

export const policySubTypes = ['internal_policy', 'external_policy'] as const;
export type PolicySubType = (typeof policySubTypes)[number];

export const clauseSubTypes = [
  'amendment',
  'assignment',
  'confidentiality',
  'damages',
  'data_protection_privacy',
  'dispute_resolution',
  'force_majeure',
  'governing_law',
  'indemnification',
  'intellectual_property',
  'limitation_of_liability',
  'non_compete',
  'non_solicitation',
  'notices',
  'payment_terms',
  'renewal',
  'representations_warranties',
  'severability',
  'termination',
] as const;
export type ClauseSubType = (typeof clauseSubTypes)[number];

export const knowledgeHasDocument = (
  knowledge: ApiKnowledge,
): knowledge is ApiKnowledge & { document: NonNullable<ApiKnowledge['document']> } => {
  return knowledge.document !== undefined;
};
