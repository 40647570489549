import { useCallback, useEffect, useRef, useState } from 'react';

import { getCssVariable } from '../cssVariable';
import { useWindowSize } from './useDimensions';

type GetHeight = (params: {
  screenHeight: number;
  tableOffset: number;
  bodyPadding: number;
}) => number;

export const useTableHeight = (getHeight?: GetHeight) => {
  const tableRef = useRef<HTMLTableElement>(null!);
  const size = useWindowSize();
  const [tableHeight, setTableHeight] = useState(0);

  useEffect(() => {
    if (!tableRef.current) return;

    const updateHeight = () => {
      const table = tableRef.current;
      if (!table) return;

      const rect = table.getBoundingClientRect();
      const bodyPadding = getCssVariable('body-padding');
      const contentPaddingY = getCssVariable('content-padding-y');
      const isProgressVisible = document.getElementById('documents-upload-progress') !== null;
      const progressCardHeight = getCssVariable('progress-card-height');

      const progressHeight = isProgressVisible ? Number(progressCardHeight + bodyPadding) : 0;

      let calculatedHeight;
      if (getHeight) {
        calculatedHeight = Math.max(
          0,
          getHeight({ screenHeight: size.height, tableOffset: rect.top, bodyPadding }),
        );
      } else {
        calculatedHeight = Math.max(
          0,
          Number(size.height - rect.top - bodyPadding - contentPaddingY) - progressHeight,
        );
      }
      setTableHeight(calculatedHeight);
    };

    const resizeObserver = new ResizeObserver(updateHeight);
    resizeObserver.observe(tableRef.current);
    updateHeight();

    return () => {
      resizeObserver.disconnect();
    };
  }, [size.height, getHeight]);

  return { tableHeight, tableRef };
};

export const useSheetTableHeight = () => {
  const getHeight: GetHeight = useCallback(
    ({ screenHeight, tableOffset, bodyPadding }) =>
      screenHeight -
      tableOffset -
      bodyPadding * 2 -
      (document.getElementById('table-footer')?.clientHeight || 0),
    [],
  );

  return useTableHeight(getHeight);
};
