import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createFileRoute } from '@tanstack/react-router';

import { Button } from '@legalfly/ui/button';
import { Content } from 'components/common/content/Content';
import { ContentHeader } from 'components/common/content/ContentHeader';
import { TagsList } from 'components/tags/TagsList';
import { routeRedirectWithFeatureFlag } from 'core/analytics';
import { tagsQueryOptions } from 'core/modules/tags';

export const Route = createFileRoute('/_auth/_layout/tags/')({
  beforeLoad: async ({ context }) => routeRedirectWithFeatureFlag(context.queryClient, 'knowledge'),
  component: RouteComponent,
  loader: async ({ context }) => {
    return context.queryClient.ensureQueryData(tagsQueryOptions.getTags());
  },
});

function RouteComponent() {
  const { t } = useTranslation();

  const [isAddingType, setIsAddingType] = useState<'tag' | 'tagGroup'>();

  return (
    <Content>
      <ContentHeader>
        <ContentHeader.Title>{t('tags.title')}</ContentHeader.Title>
        <ContentHeader.SubTitle>{t('tags.subtitle')}</ContentHeader.SubTitle>
        <ContentHeader.Actions>
          <Button variant='soft' onClick={() => setIsAddingType('tagGroup')}>
            {t('tags.actions.newGroup')}
          </Button>
          <Button variant='invert' onClick={() => setIsAddingType('tag')}>
            {t('tags.actions.newTag')}
          </Button>
        </ContentHeader.Actions>
      </ContentHeader>

      <TagsList isAddingType={isAddingType} clearIsAddingType={() => setIsAddingType(undefined)} />
    </Content>
  );
}
