import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import type { DocumentsApi } from '@legalfly/api/documents';
import type { SettingsApi } from '@legalfly/api/settings';
import type { SharePointApi } from '@legalfly/api/sharePoint/SharePointApi';

import { documentsQueryOptions } from '../../documents/documentsQueryOptions';
import { settingsQueryOptions } from '../../settings/settingsQueryOptions';
import { sharePointQueryOptions } from './sharePointQueryOptions';

export const createSharePointModule = ({
  sharePointApi,
  settingsApi,
  documentsApi,
}: {
  sharePointApi: SharePointApi;
  settingsApi: SettingsApi;
  documentsApi: DocumentsApi;
}) => {
  const queryOptionsSettings = settingsQueryOptions({ settingsApi });
  const queryOptionsSharePoint = sharePointQueryOptions({ sharePointApi });
  const queryOptionsDocuments = documentsQueryOptions({ documentsApi });

  const useSignInToSharePoint = () => {
    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['integrations', 'sharePoint', 'signIn'],
      mutationFn: sharePointApi.signIn,
    });

    return {
      signIn: mutateAsync,
      isLoading: isPending,
    };
  };

  const useExchangeSharePointCode = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['integrations', 'sharePoint', 'exchangeCode'],
      mutationFn: sharePointApi.exchangeCode,
      onSuccess: () => {
        queryClient.invalidateQueries(queryOptionsSettings.currentUserSettings());
      },
    });

    return {
      exchangeCode: mutateAsync,
      isLoading: isPending,
    };
  };

  const useSharePointSites = () => {
    const { data, isLoading } = useSuspenseQuery(queryOptionsSharePoint.sites());

    return {
      sites: data,
      isLoading,
    };
  };

  const useSharePointSiteDocuments = (query: Parameters<SharePointApi['getSiteDocuments']>[0]) => {
    const { data, isLoading } = useSuspenseQuery(queryOptionsSharePoint.siteDocuments(query));

    return {
      documents: data.documents,
      folder: data.folder,
      isLoading,
    };
  };

  const useDisconnectFromSharePoint = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['integrations', 'sharePoint', 'disconnect'],
      mutationFn: sharePointApi.disconnect,
      onSuccess: () => {
        queryClient.invalidateQueries(queryOptionsSettings.currentUserSettings());
      },
    });

    return {
      disconnect: mutateAsync,
      isLoading: isPending,
    };
  };

  const useDownloadSiteDocument = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['integrations', 'sharePoint', 'downloadSiteDocument'],
      mutationFn: sharePointApi.downloadSiteDocument,
      onSettled: (_, __, { body }) => {
        queryClient.invalidateQueries({
          queryKey: queryOptionsDocuments
            .folder({ uuid: body.destinationFolderUuid })
            .queryKey.slice(0, 2),
        });
      },
    });

    return {
      downloadSiteDocument: mutateAsync,
      isLoading: isPending,
    };
  };

  return {
    useSignInToSharePoint,
    useExchangeSharePointCode,
    useSharePointSites,
    useDisconnectFromSharePoint,
    useSharePointSiteDocuments,
    useDownloadSiteDocument,
    sharePointQueryOptions,
  };
};
