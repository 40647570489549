import { useTranslation } from 'react-i18next';
import type { SearchSchemaInput } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';

import { Content } from 'components/common/content/Content';
import { ContentHeader } from 'components/common/content/ContentHeader';
import { UserProfileForm } from 'components/users';
import type { SharePointRedirectParams } from 'core/auth/sharePoint';
import { useSharePointAuth } from 'core/auth/sharePoint';
import { settingsQueryOptions } from 'core/modules/settings';
import { usersQueryOptions } from 'core/modules/users';

export const Route = createFileRoute('/_auth/_layout/settings/profile')({
  component: ProfileRoute,
  validateSearch: (search: SharePointRedirectParams & SearchSchemaInput) => {
    return {
      code: search.code,
      session_state: search.session_state,
    };
  },
  loader({ context }) {
    return Promise.all([
      context.queryClient.ensureQueryData(usersQueryOptions.currentUser()),
      context.queryClient.ensureQueryData(settingsQueryOptions.currentUserSettings()),
    ]);
  },
});

function ProfileRoute() {
  const { t } = useTranslation();
  const searchParams = Route.useSearch();

  const { useHandleSharePointRedirectEffect } = useSharePointAuth();
  useHandleSharePointRedirectEffect(searchParams);

  return (
    <Content>
      <div className='mx-auto w-full max-w-screen-md'>
        <ContentHeader>
          <ContentHeader.Title>{t('settings.profile.title')}</ContentHeader.Title>
          <ContentHeader.SubTitle>{t('settings.profile.subtitle')}</ContentHeader.SubTitle>
        </ContentHeader>
        <UserProfileForm />
      </div>
    </Content>
  );
}
