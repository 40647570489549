import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import type { AgentsApi, ApiAgent, ApiStoreAgent } from '@legalfly/api/agents';
import { merge } from '@legalfly/utils/merge';

import { agentsQueryOptions } from './agentsQueryOptions';

export const createAgentsModule = ({ agentsApi }: { agentsApi: AgentsApi }) => {
  const queryOptions = agentsQueryOptions({ agentsApi });

  const useAgents = () => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.agents());

    return {
      agents: data ?? [],
      isLoading,
      error,
    };
  };

  const useAgent = ({ uuid }: { uuid: ApiAgent['uuid'] }) => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.agent({ uuid }));

    return {
      agent: data,
      isLoading,
      error,
    };
  };

  const useCreateAgent = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['agents', 'create'],
      mutationFn: agentsApi.createAgent,
      onSuccess(agent) {
        queryClient.setQueryData(queryOptions.agent({ uuid: agent.uuid }).queryKey, agent);
        queryClient.setQueryData(queryOptions.agents().queryKey, (agents) => {
          return agents ? [...agents, agent] : [agent];
        });
      },
    });

    return {
      createAgent: mutateAsync,
      isCreatingAgent: isPending,
    };
  };

  const useUpdateAgent = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['agents', 'update'],
      mutationFn: agentsApi.updateAgent,
      onSuccess(agent) {
        queryClient.setQueryData(queryOptions.agent({ uuid: agent.uuid }).queryKey, agent);
        queryClient.setQueryData(queryOptions.agents().queryKey, (agents) => {
          return agents?.map((a) => (a.uuid === agent.uuid ? agent : a));
        });
      },
    });

    return {
      updateAgent: mutateAsync,
      isLoading: isPending,
    };
  };

  const useAgentConversations = ({ uuid }: { uuid: ApiAgent['uuid'] }) => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.agentConversations({ uuid }));

    return {
      conversations: data ?? [],
      isLoading,
      error,
    };
  };

  const useDeleteAgent = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['agents', 'delete'],
      mutationFn: agentsApi.deleteAgent,
      onSuccess(_, { uuid }) {
        queryClient.setQueryData(queryOptions.agents().queryKey, (agents) => {
          return agents?.filter((agent) => agent.uuid !== uuid);
        });
        queryClient.removeQueries(queryOptions.agent({ uuid }));
        queryClient.removeQueries(queryOptions.agentConversations({ uuid }));
      },
    });

    return {
      deleteAgent: mutateAsync,
      isLoading: isPending,
    };
  };

  const useStoreAgents = () => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.storeAgents());

    return {
      stores: data,
      isLoading,
      error,
    };
  };

  const useStoreAgent = ({ name }: { name: ApiStoreAgent['name'] }) => {
    const { data } = useSuspenseQuery(queryOptions.storeAgent({ name }));

    return {
      store: data,
    };
  };

  const useToggleStoreAgent = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['agents', 'toggleStoreAgent'],
      mutationFn: agentsApi.toggleStoreAgent,
      onSuccess(data, { body: { storeAgentName } }) {
        console.log('data', data);
        queryClient.setQueryData(queryOptions.storeAgents().queryKey, (stores) => {
          return stores?.map((store) =>
            store.name === storeAgentName ? merge(store, data) : store,
          );
        });
        queryClient.setQueryData(
          queryOptions.storeAgent({ name: storeAgentName }).queryKey,
          (store) => {
            if (!store) return undefined;
            return merge(store, data);
          },
        );
      },
    });

    return {
      toggleStoreAgent: mutateAsync,
      isLoading: isPending,
    };
  };

  const useCustomStoreAgents = () => {
    const { data } = useSuspenseQuery(queryOptions.customStoreAgents());

    return {
      customStoreAgents: data ?? [],
    };
  };

  const useToggleCustomStoreAgent = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['agents', 'toggleCustomStoreAgent'],
      mutationFn: agentsApi.toggleCustomStoreAgent,
      onSuccess(data, { body: { customAgentUuid } }) {
        queryClient.setQueryData(queryOptions.customStoreAgents().queryKey, (customStoreAgents) => {
          return customStoreAgents?.map((customStoreAgent) =>
            customStoreAgent.uuid === customAgentUuid
              ? { ...customStoreAgent, isActive: data.isActive }
              : customStoreAgent,
          );
        });
      },
    });

    return {
      toggleCustomStoreAgent: mutateAsync,
      isLoading: isPending,
    };
  };

  return {
    useAgents,
    useAgent,
    useCreateAgent,
    useUpdateAgent,
    useAgentConversations,
    useDeleteAgent,
    agentsQueryOptions: queryOptions,
    useStoreAgents,
    useToggleStoreAgent,
    useCustomStoreAgents,
    useToggleCustomStoreAgent,
    useStoreAgent,
  };
};
