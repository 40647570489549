import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { RowSelectionState, Updater } from '@tanstack/react-table';

import type {
  ApiSharePointDocument,
  ApiSharePointSite,
} from '@legalfly/api/sharePoint/SharePointApi';
import { Button } from '@legalfly/ui/button';
import { DataTable } from '@legalfly/ui/data-table/DataTable';
import { Icon } from '@legalfly/ui/icon';
import { useSheetTableHeight } from '@legalfly/utils/hooks/useTableHeight';
import { useSharePointSiteDocuments } from 'core/modules/integrations/sharePoint';

import { useSiteDocumentColumns } from './columns';

interface Props {
  activeSite: ApiSharePointSite;
  onBackPress: VoidFunction;
  selectedDocuments: ApiSharePointDocument[];
  setSelectedDocuments: Dispatch<SetStateAction<ApiSharePointDocument[]>>;
}

const isDocumentFolder = (document: ApiSharePointDocument) => document.type === 'folder';

export const ImportSharePointSheetDocumentPicker = ({
  activeSite,
  onBackPress,
  selectedDocuments,
  setSelectedDocuments,
}: Props) => {
  const { t } = useTranslation();
  const { tableHeight, tableRef } = useSheetTableHeight();

  const [activeDocumentUuid, setActiveDocumentUuid] = useState<
    ApiSharePointDocument['uuid'] | undefined
  >(undefined);

  const { documents, folder } = useSharePointSiteDocuments({
    siteId: activeSite.id,
    folderId: activeDocumentUuid,
  });

  const handleBackPress = () => {
    if (!activeDocumentUuid) {
      return onBackPress();
    }

    if (folder?.parentId) {
      setActiveDocumentUuid(folder.parentId);
    } else {
      setActiveDocumentUuid(undefined);
    }
  };

  const handleDocumentClick = (document: ApiSharePointDocument) => {
    if (isDocumentFolder(document)) {
      setActiveDocumentUuid(document.uuid);
      return setSelectedDocuments([]);
    }

    setSelectedDocuments((prevDocuments) => {
      const index = prevDocuments.findIndex((doc) => doc.uuid === document.uuid);
      if (index !== -1) {
        return prevDocuments.filter((doc) => doc.uuid !== document.uuid);
      } else {
        return [...prevDocuments, document];
      }
    });
  };

  const columns = useSiteDocumentColumns({
    onDocumentClick: handleDocumentClick,
  });

  const rowSelection = useMemo(() => {
    return selectedDocuments.reduce((acc, document) => {
      acc[document.uuid] = true;
      return acc;
    }, {} as RowSelectionState);
  }, [selectedDocuments]);

  const handleRowSelectionChange = useCallback(
    (valueFn: Updater<RowSelectionState>) => {
      if (typeof valueFn !== 'function') {
        return;
      }

      const updatedDocumentIds = valueFn(rowSelection);
      const updatedDocuments = Object.keys(updatedDocumentIds)
        .map((uuid) => {
          return documents.find((document) => document.uuid === uuid);
        })
        .filter(Boolean) as ApiSharePointDocument[];

      setSelectedDocuments(updatedDocuments);
    },
    [rowSelection, documents, setSelectedDocuments],
  );

  return (
    <div className='mt-5'>
      <Button className='mb-4' onClick={handleBackPress} renderLeft={<Icon name='chevron-left' />}>
        {t('action.back')}
      </Button>
      <DataTable
        ref={tableRef}
        data={documents}
        columns={columns}
        tableHeight={tableHeight}
        noResultsLabel={t('label.noResults')}
        state={{
          rowSelection,
        }}
        onRowSelectionChange={handleRowSelectionChange}
        getRowId={(row) => row.uuid}
      />
    </div>
  );
};
