import { useTranslation } from 'react-i18next';
import { Link, useLocation } from '@tanstack/react-router';

import { trackEvent } from '@legalfly/reporting/tracking';
import { Button } from '@legalfly/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@legalfly/ui/dropdownMenu';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { changeTheme } from '@legalfly/ui/theme';
import { cn } from '@legalfly/ui/utils';
import { CurrentUserAvatar } from 'components/users/CurrentUserAvatar';
import { useAuth } from 'core/auth';
import { useCurrentUser } from 'core/modules/users';

interface Props {
  isExpanded: boolean;
}

export const LayoutNavigationUserMenu = ({ isExpanded }: Props) => {
  const { t } = useTranslation();

  const { logout } = useAuth();
  const { pathname: currentPath } = useLocation();
  const { currentUser } = useCurrentUser();

  const handleChangeTheme = () => {
    const newTheme = changeTheme();

    trackEvent({
      action: 'click',
      category: 'profile',
      label: newTheme,
    });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant='unstyled'
          size='md'
          className={cn(
            'flex justify-center overflow-visible px-3 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-4 focus-visible:outline-stroke-weak data-[state=open]:bg-fill-focus-strong',
            isExpanded ? 'border border-stroke-weaker' : '',
          )}
        >
          <CurrentUserAvatar size='sm' />
          {isExpanded && (
            <Text as='span' className='flex flex-1 truncate'>
              {currentUser?.firstName} {currentUser?.lastName}
            </Text>
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align='end' side='right' className='min-w-80'>
        <DropdownMenuItem asChild isActive={currentPath === '/settings/profile'}>
          <Link to='/settings/profile' className='flex w-full items-center gap-2'>
            <Icon name='users-2' />
            {t('nav.myProfile')}
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem asChild isActive={currentPath === '/settings/users'}>
          <Link to='/settings/users' className='flex w-full items-center gap-2'>
            <Icon name='users-plus' />
            {t('nav.users')}
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={handleChangeTheme} className='flex items-center gap-2'>
          <Icon name='sun' />
          <p className='light:hidden'>{`${t('label.lightMode')}`}</p>
          <p className='dark:hidden'>{`${t('label.darkMode')}`}</p>
        </DropdownMenuItem>
        <DropdownMenuSeparator variant='weak' className='my-1' />
        <DropdownMenuItem onClick={() => logout()} className='flex items-center gap-2'>
          <Icon name='logout-1' />
          {t('action.logout')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
