import { useTranslation } from 'react-i18next';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { ScrollRestorationProvider } from 'common/hooks/useScrollRestoration';

import {
  useReviewItemsActions,
  useReviewItemsState,
  withReviewItemsContext,
} from '@legalfly/components/review/context/ReviewItemsProvider';
import { IconButton } from '@legalfly/ui/button';
import { useHighlightActiveCommentPopover } from 'components/common/comments/useHighlightActiveCommentsPopover';
import { validateCommentSearch } from 'components/common/comments/validateCommentSearch';
import { ShareDialog } from 'components/common/dialog/share/ShareDialog';
import { DocumentActions } from 'components/documents/detail/DocumentActions';
import { DocumentProvider } from 'components/documents/detail/DocumentProvider';
import { DocumentViewer } from 'components/documents/detail/DocumentViewer';
import ReviewDetailTabs from 'components/review/detail/ReviewDetailTabs';
import { EditableReviewName } from 'components/review/EditableReviewName';
import { useListenForComments } from 'core/modules/comments/useListenForComments';
import { reviewsQueryOptions, useListenToReviewStatus, useReview } from 'core/modules/reviews';

export const Route = createFileRoute('/_auth/_layout/review/$uuid')({
  validateSearch: validateCommentSearch,
  component: withReviewItemsContext(ReviewDetailRoute),
  loader({ context, params }) {
    return context.queryClient.ensureQueryData(reviewsQueryOptions.review({ uuid: params.uuid }));
  },
});

function ReviewDetailRoute() {
  const { t } = useTranslation();
  const { uuid } = Route.useParams();
  const searchParams = Route.useSearch();
  const navigate = useNavigate();

  const { review } = useReview({ uuid });

  const { activeSnippetId, activeSnippetRiskLevel } = useReviewItemsState();
  const { handleHighlightSnippet } = useReviewItemsActions();

  useListenToReviewStatus({ uuid });
  useListenForComments();
  useHighlightActiveCommentPopover({
    commentUuid: searchParams?.commentUuid,
    isEnabled: Boolean(review.items.find((item) => item.uuid === searchParams?.commentUuid)),
  });

  return (
    <DocumentProvider document={review.document}>
      <DocumentActions className='flex items-center gap-2'>
        <IconButton
          name='chevron-left'
          onClick={() => navigate({ to: '/review' })}
          variant='tertiary'
          size='sm'
        />
        <EditableReviewName review={review} />
        <DocumentActions.Container>
          <ShareDialog
            title={t('dialog.share.review.title')}
            description={t('dialog.share.review.description')}
            entityType='review'
            entityUuid={review.uuid}
            readOnly={!review.canEdit}
            owner={review.owner}
          />
          <DocumentActions.AnonymousButton />
          <DocumentActions.DownloadMenu>
            <DocumentActions.DownloadMenu.MenuItemDownloadTsv review={review} />
          </DocumentActions.DownloadMenu>
        </DocumentActions.Container>
      </DocumentActions>

      <div className='flex h-content gap-3'>
        <div className='flex h-full w-3/5 overflow-auto bg-fill-maximal px-content-x py-content-y'>
          <DocumentViewer
            activeSnippetTextId={activeSnippetId}
            activeSnippetRiskLevel={activeSnippetRiskLevel}
          />
        </div>
        <ScrollRestorationProvider>
          <ReviewDetailTabs review={review} onHighlightItem={handleHighlightSnippet} />
        </ScrollRestorationProvider>
      </div>
    </DocumentProvider>
  );
}
