import { useTranslation } from 'react-i18next';
import { createFileRoute } from '@tanstack/react-router';
import { useKeywordFilter } from 'common/hooks/useKeywordFilter';
import { useSortableTable } from 'common/hooks/useSortableTable';
import { createSortableRoute } from 'common/utils/routeUtils';

import type { DraftingSortField } from '@legalfly/api/drafting';
import { DEFAULT_DRAFTING_SORT } from '@legalfly/api/drafting';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button } from '@legalfly/ui/button';
import { SearchInputForm } from '@legalfly/ui/form';
import { Icon } from '@legalfly/ui/icon';
import { Content } from 'components/common/content/Content';
import { ContentHeader } from 'components/common/content/ContentHeader';
import { ContentSidebar } from 'components/common/content/ContentSidebar';
import { DraftsInsights } from 'components/drafting/drafts/DraftsInsights';
import { DraftsList } from 'components/drafting/drafts/DraftsList';
import { draftingQueryOptions, useDrafts, useTemplates } from 'core/modules/drafting';

import { DraftingTabs } from '../-tabs/DraftingTabs';

const { validateSearch, loaderDeps } = createSortableRoute<DraftingSortField>({
  defaultSort: DEFAULT_DRAFTING_SORT,
});

export const Route = createFileRoute('/_auth/_layout/drafting/drafts/')({
  component: DraftingDraftsRoute,
  validateSearch,
  loaderDeps,
  loader({ context, deps: { sortBy, sortOrder } }) {
    return Promise.all([
      context.queryClient.ensureQueryData(draftingQueryOptions.drafts({ sortBy, sortOrder })),
      context.queryClient.ensureQueryData(draftingQueryOptions.templates()),
    ]);
  },
});

function DraftingDraftsRoute() {
  const { t } = useTranslation();

  const navigate = Route.useNavigate();
  const sorting = Route.useSearch();

  const { currentSorting, onSort } = useSortableTable<DraftingSortField>({
    sorting,
    defaultSortField: DEFAULT_DRAFTING_SORT.sortBy,
    navigate,
  });

  const { drafts, stats } = useDrafts({
    sortBy: sorting.sortBy,
    sortOrder: sorting.sortOrder,
  });
  const { templates } = useTemplates();
  const { filteredItems: filteredDrafts, setKeyword } = useKeywordFilter(drafts, 'title');

  return (
    <div className='flex flex-col gap-3'>
      <DraftingTabs />
      <div className='flex gap-3'>
        <Content>
          <ContentHeader>
            <ContentHeader.Title>{t('drafting.drafts.cta.title')}</ContentHeader.Title>
            <ContentHeader.SubTitle>{t('drafting.drafts.cta.subTitle')}</ContentHeader.SubTitle>
            <ContentHeader.Actions>
              <SearchInputForm
                placeholder={t('label.search')}
                onKeywordChange={setKeyword}
                onBlur={() =>
                  trackEvent({
                    action: 'blur',
                    category: 'drafts',
                    label: 'search',
                  })
                }
                disabled={!drafts.length}
              />
              {templates.length > 0 && (
                <Button
                  variant='soft'
                  renderLeft={<Icon name='file-2' className='text-icon-strong' />}
                  onClick={() => {
                    trackEvent({
                      action: 'click',
                      category: 'drafts',
                      label: 'createDraft',
                    });
                    navigate({ to: '/drafting/drafts/choose-template' });
                  }}
                >
                  {t('action.createDraft')}
                </Button>
              )}
            </ContentHeader.Actions>
          </ContentHeader>
          <DraftsList
            filteredDrafts={filteredDrafts}
            hasAvailableDrafts={drafts.length > 0}
            sorting={currentSorting}
            onSort={onSort}
          />
        </Content>
        {drafts.length > 0 && (
          <ContentSidebar>
            <DraftsInsights stats={stats} />
          </ContentSidebar>
        )}
      </div>
    </div>
  );
}
