import { queryOptions } from '@tanstack/react-query';

import type { AgentsApi, ApiAgent, ApiStoreAgent } from '@legalfly/api/agents';

export const agentsQueryOptions = ({ agentsApi }: { agentsApi: AgentsApi }) => ({
  agents: () =>
    queryOptions({
      queryKey: ['agents'],
      queryFn: () => agentsApi.getAgents(),
    }),
  agent: ({ uuid }: { uuid: ApiAgent['uuid'] | undefined }) =>
    queryOptions({
      queryKey: ['agent', uuid],
      queryFn: () => agentsApi.getAgent({ uuid: uuid! }),
    }),
  agentConversations: ({ uuid }: { uuid: ApiAgent['uuid'] }) =>
    queryOptions({
      queryKey: ['agent', 'conversations', uuid],
      queryFn: () => agentsApi.getAgentConversations({ uuid }),
    }),
  storeAgents: () =>
    queryOptions({
      queryKey: ['storeAgents'],
      queryFn: () => agentsApi.getStoreAgents(),
    }),
  customStoreAgents: () =>
    queryOptions({
      queryKey: ['customStoreAgents'],
      queryFn: () => agentsApi.getAllCustomAgentsForUser(),
    }),
  storeAgent: ({ name }: { name: ApiStoreAgent['name'] }) =>
    queryOptions({
      queryKey: ['storeAgent', name],
      queryFn: () => agentsApi.getStoreAgent({ name }),
    }),
});
