import { useState } from 'react';

import { type ApiDocument } from '@legalfly/api/documents';
import type { FetcherError } from '@legalfly/api/fetcher';
import type {
  ApiSharePointDocument,
  ApiSharePointSite,
} from '@legalfly/api/sharePoint/SharePointApi';
import { withToasts } from '@legalfly/ui/toast';
import type { CreateFileUploadResult } from 'core/modules/documents/helpers/useCreateFiles';
import { useHandleFileUploadResults } from 'core/modules/documents/helpers/useCreateFiles';
import { documentsToasts } from 'core/modules/documents/toasts';

import { useDownloadSiteDocument } from './';

export const useDownloadSharePointDocuments = () => {
  const [isCreatingFiles, setIsCreatingFiles] = useState(false);
  const [numberOfFilesToUpload, setNumberOfFilesToUpload] = useState(0);

  const { downloadSiteDocument } = useDownloadSiteDocument();
  const handleFileUploadResults = useHandleFileUploadResults();

  const downloadDocuments = async ({
    documents,
    destinationFolderUuid,
    siteId,
  }: {
    documents: ApiSharePointDocument[];
    destinationFolderUuid: ApiDocument['uuid'] | undefined;
    siteId: ApiSharePointSite['id'];
  }) => {
    setNumberOfFilesToUpload(documents.length);
    setIsCreatingFiles(true);

    const uploadResults = await Promise.all(
      documents.map(async (document): Promise<CreateFileUploadResult[]> => {
        try {
          const { documents: downloadedDocuments } = await withToasts(
            downloadSiteDocument({
              body: {
                document,
                destinationFolderUuid,
              },
              siteId,
            }),
          )(documentsToasts.uploadFile(document.name));

          return downloadedDocuments.map(
            (doc): CreateFileUploadResult => ({
              success: true,
              document: doc,
            }),
          );
        } catch (error) {
          return [
            {
              success: false,
              error: error as FetcherError,
            },
          ];
        }
      }),
    ).then((results) => results.flat());

    setIsCreatingFiles(false);
    return handleFileUploadResults(uploadResults);
  };

  return {
    downloadDocuments,
    numberOfFilesToUpload,
    isCreatingFiles,
  };
};
