import { useTranslation } from 'react-i18next';

import { Button } from '@legalfly/ui/button';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { useSharePointAuth } from 'core/auth/sharePoint';

interface Props {
  resetErrorBoundary: VoidFunction;
}

export const SharePointDisconnected = ({ resetErrorBoundary }: Props) => {
  const { t } = useTranslation();
  const { connectSharePoint } = useSharePointAuth();

  const handleAuthenticateSharePoint = async () => {
    await connectSharePoint();
    resetErrorBoundary();
  };

  return (
    <div className='mt-6 flex flex-col gap-3 px-6'>
      <Text variant='bodySemiBold'>{t('documents.sharePointSheet.disconnected.title')}</Text>
      <Button
        className='w-fit'
        onClick={handleAuthenticateSharePoint}
        renderLeft={<Icon name='share-point' />}
      >
        {t('documents.sharePointSheet.disconnected.action')}
      </Button>
    </div>
  );
};
