import type { BodyOf, ResponseOf } from '../client';
import type { createConversationsAgentApi } from './ConversationsAgentApi';
import type { createConversationsDiscoveryApi } from './ConversationsDiscoveryApi';
import type { createConversationsDraftApi } from './ConversationsDraftApi';
import type { createConversationsDraftWorkbenchApi } from './ConversationsDraftWorkbenchApi';
import type { createConversationsOfficeApi } from './ConversationsOfficeApi';
import type { createConversationsReviewApi } from './ConversationsReviewApi';

export enum ConversationTypeEnum {
  DISCOVERY = 'discovery',
  REVIEW = 'review',
  DRAFTS = 'drafts',
  AGENT = 'agent',
  OFFICE = 'office',
  DRAFT_WORKBENCH = 'draft-workbench',
}

export const ConversationMessageStatusValues = [
  'in_progress',
  'anomalies',
  'completed',
  'failed',
] as const;
export type ConversationMessageStatus = (typeof ConversationMessageStatusValues)[number];

type ExtractTypeFromObject<T> = T extends Record<string, (infer U)[]> ? U : never;

export type ApiConversationGrouped = ResponseOf<`:entityType/conversations/grouped/get`>;
export type ApiConversation = ExtractTypeFromObject<ApiConversationGrouped>;

export type ApiConversationTypeDraftWorkbench = Extract<
  ApiConversation,
  { type: 'draft-workbench' }
>;

export type ApiConversationDetail = ResponseOf<`:entityType/conversations/:uuid/get`>;
export type ApiConversationJurisdiction = ResponseOf<`:entityType/conversations/:uuid/get`>;
export type ApiConversationGroupKey = keyof ResponseOf<`:entityType/conversations/grouped/get`>;
export type ApiConversationMessageBody = BodyOf<`:entityType/conversations/:uuid/patch`>;
export type ApiConversationMessage =
  ResponseOf<`:entityType/conversations/:uuid/get`>['messages'][number];
export type ApiConversationMessageSource = ApiConversationMessage['sources'][number];
export type ApiConversationMessageSourceWeb = Extract<
  ApiConversationMessageSource,
  { type: 'web' }
>;
export type ApiConversationMessageSourceDocument = Extract<
  ApiConversationMessageSource,
  { type: 'document' }
>;
export type ApiConversationMessageSourceFullDocument = Extract<
  ApiConversationMessageSource,
  { type: 'full-document' }
>;
export type ApiConversationMessageSourceSwissCaseLaw = Extract<
  ApiConversationMessageSource,
  { type: 'swiss case law' }
>;

export type ConversationsReviewApi = ReturnType<typeof createConversationsReviewApi>;
export type ConversationsDiscoveryApi = ReturnType<typeof createConversationsDiscoveryApi>;
export type ConversationsDraftApi = ReturnType<typeof createConversationsDraftApi>;
export type ConversationsAgentApi = ReturnType<typeof createConversationsAgentApi>;
export type ConversationsOfficeApi = ReturnType<typeof createConversationsOfficeApi>;
export type ConversationsDraftWorkbenchApi = ReturnType<
  typeof createConversationsDraftWorkbenchApi
>;

export type ConversationApiType =
  | ConversationsReviewApi
  | ConversationsDiscoveryApi
  | ConversationsDraftApi
  | ConversationsAgentApi
  | ConversationsDraftWorkbenchApi
  | ConversationsOfficeApi;

export type ConversationsApi = Partial<{
  reviewApi: ConversationsReviewApi;
  discoveryApi: ConversationsDiscoveryApi;
  draftApi: ConversationsDraftApi;
  agentApi: ConversationsAgentApi;
  officeApi: ConversationsOfficeApi;
  draftWorkbenchApi: ConversationsDraftWorkbenchApi;
}>;

export const modifyMessageToneValues = [
  'moreFormal',
  'lessFormal',
  'diplomatic',
  'assertive',
  'cautious',
] as const;

export type ModifyMessageTone = (typeof modifyMessageToneValues)[number];
export const modifyMessageTypeValues = modifyMessageToneValues;
export type ModifyMessageType = (typeof modifyMessageTypeValues)[number];

export const conversationLinkedEntityTypes = ['document', 'agent', 'review-item'] as const;
export type ConversationLinkedEntityType = (typeof conversationLinkedEntityTypes)[number];
