import type { DeepMergeAllFn, Options } from '@fastify/deepmerge';
import deepmerge from '@fastify/deepmerge';

const replaceByClonedSource: Options['mergeArray'] = (options) => {
  const clone = options.clone;
  return function (_, source) {
    return clone(source);
  };
};

export const merge: DeepMergeAllFn = deepmerge({
  all: true,
  mergeArray: replaceByClonedSource,
});
