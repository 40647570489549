import type { ComponentProps } from 'react';

import { cn } from 'utils';

import { Text } from '../text';
import { ProgressBar } from './ProgressBar';

const ProgressCard = ({
  children,
  title,
  subTitle,
  action,
  className,
  id,
}: {
  children: React.ReactNode;
  title: string;
  action: string;
  subTitle?: string;
  className?: string;
  id?: string;
}) => {
  return (
    <div
      id={id}
      className={cn(
        'flex min-h-[var(--lf-progress-card-height)] min-w-full max-w-min flex-col gap-4 overflow-hidden bg-fill-maximal px-4 py-3',
        className,
      )}
    >
      <div className='z-10 flex justify-between'>
        <div className='flex flex-col gap-1'>
          <Text className='font-writer'>{title}</Text>
          {subTitle && <Text variant='captionLight'>{subTitle}</Text>}
        </div>
        <div className='flex flex-col gap-1'>
          <Text variant='captionLight' className='text-content-body-strong'>
            {action}
          </Text>
        </div>
      </div>
      {children}
    </div>
  );
};

const Bar = ({ className, ...props }: ComponentProps<typeof ProgressBar>) => {
  return <ProgressBar className={cn('overflow-visible', className)} {...props} />;
};

ProgressCard.Bar = Bar;

export { ProgressCard };
