export const agentStatus = {
  PRIVATE: 'private',
  PUBLIC: 'public',
} as const;

export type AgentStatusType = (typeof agentStatus)[keyof typeof agentStatus];

export const AGENT_SORT_CONFIG = {
  createdAt: 'createdAt',
  status: 'status',
  name: 'name',
} as const;

export type AgentSortField = keyof typeof AGENT_SORT_CONFIG;
export type MappedAgentSortField = (typeof AGENT_SORT_CONFIG)[AgentSortField];

export type StoreAgents = (typeof storeAgents)[number];

export const storeAgents = [
  'review',
  'multi-review',
  'discovery',
  'custom-agent',
  'translation',
  'anonymisation',
  'drafting',
  'legal-radar',
  'word-addin',
] as const;
