import { useQueryClient } from '@tanstack/react-query';

import { type ApiDocument, DocumentStatus } from '@legalfly/api/documents';
import type { ApiKnowledge } from '@legalfly/api/knowledge';
import { createKnowledgeModule } from '@legalfly/modules/knowledge';
import { merge } from '@legalfly/utils/merge';
import { useWebSocketSubscriber } from '@legalfly/websockets';

import { documentsApi, knowledgeApi } from '../../di';

export const {
  useKnowledgeList,
  useKnowledge,
  useDeleteKnowledge,
  useCreateKnowledge,
  useUpdateKnowledge,
  useCreateKnowledgeFile,
  knowledgeQueryOptions,
} = createKnowledgeModule({
  knowledgeApi,
  documentsApi,
});

export * from './toasts';

export const ListenToKnowledgeDocumentUploadStatus = ({
  documentUuid,
  knowledgeUuid,
}: {
  documentUuid: ApiDocument['uuid'];
  knowledgeUuid: ApiKnowledge['uuid'];
}) => {
  const queryClient = useQueryClient();

  useWebSocketSubscriber(`document_upload_status_${documentUuid}`, (data) => {
    queryClient.setQueriesData(
      {
        queryKey: knowledgeQueryOptions.getKnowledge({ knowledgeUuid }).queryKey,
      },
      (oldData: ApiKnowledge | undefined) => {
        if (!oldData) return oldData;
        return merge(oldData, { document: { status: data.status } });
      },
    );

    queryClient.setQueriesData(
      {
        queryKey: knowledgeQueryOptions.getKnowledgeList().queryKey.slice(0, 1),
      },
      (oldData: ApiKnowledge[] | undefined) => {
        if (!oldData) return oldData;
        const updatedKnowledge = oldData.map((knowledge) =>
          knowledge.uuid === knowledgeUuid
            ? merge(knowledge, { document: { status: data.status } })
            : knowledge,
        );
        return updatedKnowledge;
      },
    );

    if (data.status === DocumentStatus.COMPLETE) {
      queryClient.invalidateQueries({
        queryKey: knowledgeQueryOptions.getKnowledge({ knowledgeUuid }).queryKey,
      });
    }
  });

  return null;
};
