import * as React from 'react';
import { composeEventHandlers } from '@radix-ui/primitive';
import { useComposedRefs } from '@radix-ui/react-compose-refs';
import type { PortalProps } from '@radix-ui/react-portal';
import { Portal } from '@radix-ui/react-portal';
import { Presence } from '@radix-ui/react-presence';
import { Primitive } from '@radix-ui/react-primitive';
import { useControllableState } from '@radix-ui/react-use-controllable-state';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { cn } from '@legalfly/ui/utils';

import { IconButton } from '../button';

const SidePanePortal = Portal;

const DETAIL_PANE_NAME = 'SidePane';

interface SidePaneContextValue {
  triggerRef: React.RefObject<HTMLButtonElement | null>;
  contentId: string;
  open: boolean;
  activePane: string | null;
  container: PortalProps['container'];
  onOpenChange(open: boolean): void;
  onOpenToggle(): void;
  setActivePane(pane: string): void;
}

const SidePaneContext = React.createContext<SidePaneContextValue | null>(null);

const useSidePaneContext = (componentName: string) => {
  const context = React.useContext(SidePaneContext);
  if (!context) {
    throw new Error(`${componentName} must be used within a SidePaneProvider`);
  }
  return context;
};

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  open?: boolean;
  defaultOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  contentClassName?: string;
}

const SidePaneProvider = ({
  children,
  open: openProp,
  defaultOpen,
  className,
  onOpenChange,
  contentClassName,
  ...props
}: Props) => {
  const [container, setContainer] = React.useState<PortalProps['container']>(null);
  const triggerRef = React.useRef<HTMLButtonElement>(null);
  const [open = false, setOpen] = useControllableState({
    prop: openProp,
    defaultProp: defaultOpen,
    onChange: onOpenChange,
  });
  const [activePane, setActivePane] = React.useState<string | null>(null);
  const contentId = React.useId();

  const handleOpenChange = (open: boolean) => {
    setOpen(open);

    if (!open) {
      setActivePane(null);
    }

    onOpenChange?.(open);
  };

  return (
    <SidePaneContext.Provider
      value={{
        triggerRef,
        contentId,
        open,
        activePane,
        container,
        onOpenChange: handleOpenChange,
        onOpenToggle: () => handleOpenChange(!open),
        setActivePane,
      }}
    >
      <div className={cn('flex', className)} {...props}>
        {children}
        <div ref={setContainer} className={cn(open && contentClassName)} />
      </div>
    </SidePaneContext.Provider>
  );
};

SidePaneProvider.displayName = DETAIL_PANE_NAME;

const TRIGGER_NAME = 'SidePaneTrigger';

type SidePaneTriggerElement = React.ElementRef<typeof Primitive.button>;
type PrimitiveButtonProps = React.ComponentPropsWithoutRef<typeof Primitive.button>;
interface SidePaneTriggerProps extends PrimitiveButtonProps {
  paneId?: string;
}

const SidePaneTrigger = React.forwardRef<SidePaneTriggerElement, SidePaneTriggerProps>(
  ({ paneId, ...props }, forwardedRef) => {
    const context = useSidePaneContext(TRIGGER_NAME);
    const composedTriggerRef = useComposedRefs(forwardedRef, context.triggerRef);

    return (
      <Primitive.button
        type='button'
        aria-expanded={context.open && context.activePane === paneId}
        aria-controls={context.contentId}
        data-state={context.open && context.activePane === paneId ? 'open' : 'closed'}
        {...props}
        ref={composedTriggerRef}
        onClick={composeEventHandlers(props.onClick, () => {
          context.onOpenChange(true);
          if (paneId) {
            context.setActivePane(paneId);
          }
        })}
      />
    );
  },
);

SidePaneTrigger.displayName = TRIGGER_NAME;

const sidePaneContentVariants = cva(
  'overflow-hidden transition-all duration-200 ease-in-out data-[state=open]:ms-[var(--lf-body-padding)] data-[state=closed]:w-0',
  {
    variants: {
      size: {
        sm: 'data-[state=open]:w-side-pane-sm',
        md: 'data-[state=open]:w-side-pane-md',
        flex: 'data-[state=open]:flex-1',
      },
    },
    defaultVariants: {
      size: 'sm',
    },
  },
);

type SidePaneContentProps = React.HTMLAttributes<HTMLDivElement> & {
  onClose?: () => void;
  paneId?: string;
  containerClassName?: string;
} & VariantProps<typeof sidePaneContentVariants>;

const SidePaneContent = React.forwardRef<HTMLDivElement, SidePaneContentProps>(
  ({ children, className, onClose, size, paneId, containerClassName, ...props }, forwardedRef) => {
    const context = useSidePaneContext('SidePaneContent');

    const isActivePane = (!paneId || context.activePane === paneId) && context.open;

    return (
      <SidePanePortal container={context.container}>
        <Presence present={context.open && isActivePane}>
          {({ present }) => (
            <div
              ref={forwardedRef}
              id={context.contentId}
              data-state={isActivePane ? 'open' : 'closed'}
              {...props}
              className={cn(
                sidePaneContentVariants({ size, className: isActivePane ? className : undefined }),
              )}
            >
              {present && (
                <div
                  className={cn(
                    'relative h-full overflow-auto bg-fill-maximal p-6 duration-1000',
                    'animate-in fade-in',
                    containerClassName,
                  )}
                >
                  {children}
                  <SidePaneClose className='absolute right-6 top-[18px]' onClick={onClose} asChild>
                    <IconButton variant='tertiary' size='sm' name='x-close' />
                  </SidePaneClose>
                </div>
              )}
            </div>
          )}
        </Presence>
      </SidePanePortal>
    );
  },
);

SidePaneContent.displayName = 'SidePaneContent';

const CLOSE_NAME = 'SidePaneClose';

type SidePaneCloseElement = React.ElementRef<typeof Primitive.button>;
type SidePaneCloseProps = PrimitiveButtonProps;

const SidePaneClose = React.forwardRef<SidePaneCloseElement, SidePaneCloseProps>(
  (props, forwardedRef) => {
    const context = useSidePaneContext(CLOSE_NAME);

    return (
      <Primitive.button
        type='button'
        {...props}
        ref={forwardedRef}
        onClick={composeEventHandlers(props.onClick, context.onOpenToggle)}
      />
    );
  },
);

SidePaneClose.displayName = CLOSE_NAME;

const SidePaneHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('flex items-center justify-between pe-9', className)} {...props} />
);
SidePaneHeader.displayName = 'SidePaneHeader';

export {
  SidePanePortal,
  SidePaneProvider,
  SidePaneClose,
  SidePaneContent,
  SidePaneHeader,
  SidePaneTrigger,
  useSidePaneContext,
};
