import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiSharePointDocument } from '@legalfly/api/sharePoint/SharePointApi';
import { IconButton } from '@legalfly/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@legalfly/ui/dropdownMenu';
import { Icon } from '@legalfly/ui/icon';
import { cn } from '@legalfly/ui/utils';

interface Props {
  document: ApiSharePointDocument;
}

export const ImportSharePointDocumentMenu = ({ document }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenDocument = () => {
    window.open(document.url, '_blank');
  };

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger asChild>
        <IconButton
          name='dots-horizontal'
          className={cn('rotate-90', isOpen && 'bg-fill-hover-strong')}
          variant='tertiary'
          size='sm'
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent className='flex flex-col gap-2' align='end'>
        <DropdownMenuItem onClick={handleOpenDocument}>
          <Icon name='link-3' className='text-icon-weak' />
          {t('action.openInSharePoint')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
