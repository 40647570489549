import { type ApiPlaybook, PlaybookStatusEnum } from '@legalfly/api/playbooks';
import { Button, IconButton } from '@legalfly/ui/button';
import { Checkbox } from '@legalfly/ui/checkbox';
import { Text } from '@legalfly/ui/text';

import { PlaybookItemsSheet } from './PlaybookItemsSheet';

interface Props {
  playbook: ApiPlaybook;
  checked: boolean;
  sheetTopOffset?: string;
  onCheckedChange: (checked: boolean) => void;
  onRemove: (uuid: string) => void;
}

export const PlaybookListItem = ({
  playbook,
  checked,
  sheetTopOffset = '0',
  onCheckedChange,
  onRemove,
}: Props) => {
  const isSystemPlaybook = playbook.status === PlaybookStatusEnum.SYSTEM;

  return (
    <Button asChild className='size-auto select-none flex-col gap-3 px-4 py-2 lg:flex-row'>
      <label htmlFor={playbook.uuid}>
        <div className='flex flex-1 items-center gap-2'>
          <Checkbox checked={checked} onCheckedChange={onCheckedChange} id={playbook.uuid} />
          <Text className='flex-1'>{playbook.name}</Text>
        </div>
        <div className='flex w-full gap-2 lg:w-auto'>
          <PlaybookItemsSheet playbook={playbook} topOffset={sheetTopOffset} />
          <IconButton
            name='x-close'
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onRemove(playbook.uuid);
            }}
            variant='tertiary'
            size='xs'
            disabled={isSystemPlaybook}
          />
        </div>
      </label>
    </Button>
  );
};
