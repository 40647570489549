import i18n from 'i18next';

import { ToastAction } from '@legalfly/ui/toast';

import { useCurrentUserSettings } from '../settings';

export const CreateUserErrorToastAction = () => {
  const { data: contactEmail } = useCurrentUserSettings({
    select: (settings) => settings.contactEmail,
  });

  return (
    <ToastAction
      altText={i18n.t('settings.contact.label')}
      onClick={() => window.open(`mailto:${contactEmail}`, '_blank')}
      className='!m-0 !mt-2 text-start underline opacity-70'
    >
      {i18n.t('settings.contact.label')}
    </ToastAction>
  );
};
