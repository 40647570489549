import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

import { ChangelogDialog } from 'components/common/changelog/ChangelogDialog';
import { OnboardingDialog } from 'components/common/onboarding/OnboardingDialog';
import { useCurrentUserSettings } from 'core/modules/settings';

export const Route = createFileRoute('/_auth')({
  beforeLoad: async ({ context, location }) => {
    const authErrorPath = '/auth/error';
    if (context.auth?.error && location.pathname !== authErrorPath) {
      throw redirect({
        to: authErrorPath,
      });
    }

    if (!context.auth?.isAuthenticated) {
      await context.auth?.loginWithRedirect({
        // the default OnRedirectCallback will redirect to the returnTo location
        appState: { returnTo: location.pathname + location.searchStr },
      });
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject();
    }
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { i18n } = useTranslation();
  const { data: userSettings } = useCurrentUserSettings();

  useEffect(() => {
    if (userSettings?.language && userSettings.language !== i18n.language) {
      i18n.changeLanguage(userSettings.language);
    }
  }, [userSettings, i18n]);

  const showOnboarding = !userSettings.isOnboardingCompleted;
  const showChangelog = Boolean(
    userSettings.isOnboardingCompleted && userSettings.unviewedChangelogs.length,
  );

  return (
    <>
      <Outlet />
      {showOnboarding && <OnboardingDialog userSettings={userSettings} />}
      {showChangelog && <ChangelogDialog isOpen />}
    </>
  );
}
