import type { ApiPlaybookItem } from '@legalfly/api/playbooks';
import { PlaybookItemTypeEnum } from '@legalfly/api/playbooks';

export function mapStringToPlaybookItems(
  playbookItems: string,
): Pick<ApiPlaybookItem, 'name' | 'question' | 'type'>[] {
  return playbookItems
    .split('\n')
    .filter((item) => item.trim() !== '')
    .map((item) => ({ name: item, question: item, type: PlaybookItemTypeEnum.QUESTION }));
}
