import { legalFlyConfig } from '@legalfly/config';

import type { BodyOf, ResponseOf } from '../client';
import { createClient } from '../client';
import type { Fetcher } from '../fetcher/Fetcher';

export const createAgentsApi = (fetcher: Fetcher) => {
  const client = createClient(fetcher);

  const api = legalFlyConfig.api.paths;

  const getAgents = () => {
    return client(api.agents.list, { method: 'GET' });
  };

  const getAgent = ({ uuid }: { uuid: ApiAgent['uuid'] }) => {
    return client(api.agents.get, { method: 'GET', params: { uuid } });
  };

  const createAgent = ({ body }: { body: BodyOf<'agents/post'> }) => {
    return client(api.agents.create, { method: 'POST', body });
  };

  const updateAgent = ({
    uuid,
    body,
  }: {
    uuid: ApiAgent['uuid'];
    body: BodyOf<'agents/:uuid/patch'>;
  }) => {
    return client(api.agents.update, { method: 'PATCH', params: { uuid }, body });
  };

  const getAgentConversations = ({ uuid }: { uuid: ApiAgent['uuid'] }) => {
    return client(api.agents.conversations, { method: 'GET', params: { uuid } });
  };

  const deleteAgent = ({ uuid }: { uuid: ApiAgent['uuid'] }) => {
    return client(api.agents.delete, { method: 'DELETE', params: { uuid } });
  };

  const getStoreAgents = () => {
    return client(api.agents.agentStores, { method: 'GET' });
  };

  const getStoreAgent = ({ name }: { name: ApiStoreAgent['name'] }) => {
    return client(api.agents.agentStore, { method: 'GET', params: { name } });
  };

  const toggleStoreAgent = ({ body }: { body: BodyOf<'agents/toggle-store-agent/post'> }) => {
    return client(api.agents.toggleStoreAgent, { method: 'POST', body });
  };

  const getAllCustomAgentsForUser = () => {
    return client(api.agents.customStoreAgents, { method: 'GET' });
  };

  const toggleCustomStoreAgent = ({
    body,
  }: {
    body: BodyOf<'agents/toggle-custom-agent/post'>;
  }) => {
    return client(api.agents.toggleCustomAgent, { method: 'POST', body });
  };

  return {
    getAgents,
    getAgent,
    createAgent,
    updateAgent,
    getAgentConversations,
    deleteAgent,
    getStoreAgents,
    getStoreAgent,
    toggleStoreAgent,
    getAllCustomAgentsForUser,
    toggleCustomStoreAgent,
  };
};

export type AgentsApi = ReturnType<typeof createAgentsApi>;
export type ApiAgent = ResponseOf<'agents/get'>[number];
export type ApiAgentDetail = ResponseOf<'agents/:uuid/get'>;
export type ApiAgentConversations = ResponseOf<'agents/:uuid/conversations/get'>;
export type ApiUserCustomAgent = ResponseOf<'agents/custom-store-agents/get'>[number];
export type ApiStoreAgent = ResponseOf<'agents/agent-stores/get'>[number];
export type ApiStoreAgentName = ApiStoreAgent['name'];
