import type { ComponentProps } from 'react';
import { memo } from 'react';

import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from '@legalfly/ui/tooltip';

interface NavigationTooltipProps {
  children: React.ReactNode;
  text: string;
  isExpanded: boolean;
  contentProps?: ComponentProps<typeof TooltipContent>;
}

export const NavigationTooltip = memo(
  ({ children, text, isExpanded, contentProps }: NavigationTooltipProps) => {
    if (isExpanded) {
      return children;
    }

    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>{children}</TooltipTrigger>
          <TooltipPortal>
            <TooltipContent side='right' {...contentProps}>
              <span className='text-body-small'>{text}</span>
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      </TooltipProvider>
    );
  },
);

NavigationTooltip.displayName = 'NavigationTooltip';
