import type { CSSProperties, ReactNode } from 'react';

import { cn } from '@legalfly/ui/utils';

interface Props {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
  innerClassName?: string;
  innerStyle?: CSSProperties;
}

export const Content = ({ children, className, innerClassName, innerStyle, style }: Props) => {
  return (
    <div className={cn('h-content flex-1 bg-fill-maximal', className)} style={style}>
      <div
        className={cn(
          'relative flex h-full flex-col overflow-auto px-content-x py-content-y',
          innerClassName,
        )}
        style={innerStyle}
      >
        {children}
      </div>
    </div>
  );
};
