import { useTranslation } from 'react-i18next';
import { createFileRoute, Link, redirect } from '@tanstack/react-router';

import type { ApiStoreAgentName } from '@legalfly/api/agents';
import { storeAgents } from '@legalfly/api/agents';
import { UserAvatars } from '@legalfly/components/users/UserAvatars';
import { Badge } from '@legalfly/ui/badge';
import { Button, IconButton } from '@legalfly/ui/button';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { Content } from 'components/common/content/Content';
import { ContentRow } from 'components/common/content/ContentRow';
import { getAgentIcon } from 'components/dashboard/helpers/agentIcon';
import { DetailImage } from 'components/store/detailImage';
import { useStoreAgent, useToggleStoreAgent } from 'core/modules/agents';

const INVALID_PARAM_ERROR_PREFIX = 'Invalid agent name parameter:';

export const Route = createFileRoute('/_auth/_layout/store/$uuid')({
  parseParams: (params: { uuid: string }): { uuid: ApiStoreAgentName } => {
    if (!(storeAgents as readonly string[]).includes(params.uuid)) {
      throw new Error(`${INVALID_PARAM_ERROR_PREFIX} ${params.uuid}`);
    }
    return {
      uuid: params.uuid as ApiStoreAgentName,
    };
  },
  onError: (error: Error) => {
    if (error instanceof Error && error.message.startsWith(INVALID_PARAM_ERROR_PREFIX)) {
      throw redirect({ to: '/store', replace: true });
    }
  },
  component: AgentDetailRoute,
});

function AgentDetailRoute() {
  const { t } = useTranslation();
  const { uuid: agentName } = Route.useParams();

  const { store } = useStoreAgent({ name: agentName });

  const activeUsers = store.activeUsers;
  const isActive = store.active;
  const image = getAgentIcon(agentName);
  const isComingSoon = store.comingSoon;

  const { toggleStoreAgent } = useToggleStoreAgent();

  const onToggleStoreAgent = () => {
    toggleStoreAgent({ body: { storeAgentName: agentName } });
  };

  return (
    <div className='flex flex-1 flex-col gap-3'>
      <ContentRow>
        <div className='flex items-center gap-2'>
          <Link to='/store'>
            <IconButton name='chevron-left' variant='tertiary' size='sm' />
          </Link>
          <Text>{t(`store.detail.${agentName}.title`)}</Text>
        </div>
      </ContentRow>

      <Content className='flex-none bg-fill-strongest'>
        <div className='mt-4 grid grid-cols-1 gap-8 md:grid-cols-2'>
          <div className='flex flex-col gap-4'>
            <div className='shadow flex justify-between border border-stroke-weaker bg-fill-maximal px-7 py-4'>
              <div className='flex flex-wrap items-center gap-2'>
                <img src={image} className='w-[55px]' alt='agent' loading='lazy' />
                <Text variant='subheadingSemiBold'>{t(`store.detail.${agentName}.title`)}</Text>
              </div>
              <div className='flex flex-wrap items-center gap-4'>
                <UserAvatars users={activeUsers} visibleUsers={3} />
                <div className='flex items-center justify-end gap-2'>
                  {isComingSoon ? (
                    <Badge variant='inverted'>{t('store.comingSoon')}</Badge>
                  ) : isActive ? (
                    <Button
                      variant='brand'
                      renderLeft={<Icon name='check' className='mr-1' />}
                      onClick={onToggleStoreAgent}
                    >
                      {t('store.added')}
                    </Button>
                  ) : (
                    <Button
                      variant='activate'
                      renderLeft={<Icon name='plus' className='mr-1' />}
                      leftClassName='flex items-center px-2'
                      onClick={onToggleStoreAgent}
                    >
                      {t('store.activate')}
                    </Button>
                  )}
                </div>
              </div>
            </div>

            <DetailImage
              imagePosition='left'
              agentKey={agentName}
              className='my-10 h-auto w-full'
            />
          </div>

          <div className='flex flex-col gap-4'>
            <Text variant='subheadingLight'>{t(`store.detail.${agentName}.upperText`)}</Text>

            <DetailImage
              imagePosition='right'
              agentKey={agentName}
              className='my-10 h-auto w-full'
            />

            <Text variant='subheadingLight'>{t(`store.detail.${agentName}.lowerText`)}</Text>
          </div>
        </div>
      </Content>
    </div>
  );
}
