import type { ComponentPropsWithoutRef, ElementRef, ForwardedRef, ReactNode } from 'react';
import { createContext, forwardRef, useCallback, useContext, useMemo, useState } from 'react';
import { Slot } from '@radix-ui/react-slot';
import { useControllableState } from '@radix-ui/react-use-controllable-state';

import { fixedForwardRef } from '@legalfly/utils/refs';
import { cn } from 'utils';

import { Button, IconButton } from '../button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../command';
import { Icon } from '../icon';
import { Popover, PopoverContent, PopoverTrigger } from '../popover';

interface ComboboxContextValue<T> {
  setInternalValue: (value: T) => void;
  internalValue: T;
  onSelect: (value: T) => void;
  asDialog: boolean;
  clearValue: VoidFunction;
}

const ComboboxContext = createContext<ComboboxContextValue<any> | null>(null);

function useComboboxContext<T>() {
  const context = useContext(ComboboxContext);
  if (context === null) {
    throw new Error('useComboboxContext must be used within a Combobox');
  }
  return context as ComboboxContextValue<T>;
}

interface Props<T> extends ComponentPropsWithoutRef<typeof Popover> {
  onChange: ComboboxContextValue<T>['onSelect'];
  value: ComboboxContextValue<T>['internalValue'] | undefined;
  asDialog?: boolean;
}

function Combobox<T>({ children, onChange, value, asDialog = false, ...props }: Props<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const [internalValue, setInternalValue] = useState(value);

  // sync external value with internal value
  const [syncedValue, setSyncedValue] = useControllableState({
    prop: internalValue,
    defaultProp: value,
    onChange: setInternalValue,
  });

  const onSelect = useCallback(
    (value: T) => {
      onChange(value);
      setIsOpen(false);
    },
    [onChange],
  );

  const clearValue = useCallback(() => {
    onChange('' as T);
    setSyncedValue('' as T);
    setIsOpen(false);
  }, [onChange, setSyncedValue]);

  const contextValue = useMemo(
    () => ({
      onSelect,
      internalValue: syncedValue,
      setInternalValue: setSyncedValue,
      asDialog,
      clearValue,
    }),
    [onSelect, syncedValue, setSyncedValue, asDialog, clearValue],
  );

  return (
    <ComboboxContext.Provider value={contextValue}>
      {asDialog ? (
        children
      ) : (
        <Popover
          open={isOpen}
          onOpenChange={(open) => {
            if (open) {
              // hovering over an item will change the internal value
              // when closing the popover, set the internal value again to the external value
              setSyncedValue(value);
            }
            setIsOpen(open);
          }}
          {...props}
        >
          {children}
        </Popover>
      )}
    </ComboboxContext.Provider>
  );
}
Combobox.displayName = 'Combobox';

interface ComboboxContentProps<TData>
  extends ComponentPropsWithoutRef<typeof PopoverContent>,
    Pick<ComponentPropsWithoutRef<typeof Command>, 'shouldFilter' | 'filter' | 'loop'> {
  filterKey?: keyof TData;
  displayKey: keyof TData;
  valueKey?: keyof TData;
  hideSearch?: boolean;
  emptyMessage?: string;
  items: readonly TData[];
  comboboxListProps?: ComponentPropsWithoutRef<typeof CommandList>;
  comboboxEmptyProps?: ComponentPropsWithoutRef<typeof CommandEmpty>;
  comboboxInputProps?: ComponentPropsWithoutRef<typeof CommandInput>;
  renderItem?: (item: TData, index: number, items: readonly TData[]) => ReactNode;
  showClearButton?: boolean;
}

type ComboboxContentRef = ForwardedRef<ElementRef<typeof PopoverContent>>;

const ComboboxContent = fixedForwardRef(
  <TData extends object>(
    {
      shouldFilter,
      loop,
      filterKey,
      items,
      displayKey,
      valueKey,
      hideSearch,
      comboboxListProps,
      comboboxEmptyProps,
      comboboxInputProps,
      filter,
      renderItem,
      showClearButton = false,
      ...props
    }: ComboboxContentProps<TData>,
    ref: ComboboxContentRef,
  ) => {
    const context = useComboboxContext<string>();

    const defaultFilter = useCallback(
      (value: string, search: string) => {
        if (!filterKey && !displayKey) return 1;

        const item = items?.find((v) => {
          const keys = Object.keys(v) as (keyof typeof v)[];
          return keys.some((key) => v[key] === value);
        });

        if (!item) return 0;

        const itemValue = item[filterKey || displayKey];
        if (typeof itemValue !== 'string') return 0;

        return itemValue.toLowerCase().includes(search.toLowerCase()) ? 1 : 0;
      },
      [items, filterKey, displayKey],
    );

    const content = (
      <Command
        value={context.internalValue}
        onValueChange={context.setInternalValue}
        shouldFilter={shouldFilter}
        filter={filter || defaultFilter}
        loop={loop}
      >
        {!hideSearch && (
          <div className='relative'>
            <ComboboxInput {...comboboxInputProps} />
            {showClearButton && context.internalValue && (
              <IconButton
                type='button'
                variant='tertiary'
                name='x-close'
                size='sm'
                className='absolute right-2 top-1/2 -translate-y-1/2'
                onClick={context.clearValue}
              />
            )}
          </div>
        )}
        <ComboboxList {...comboboxListProps}>
          <ComboboxEmpty {...comboboxEmptyProps} />
          {renderItem
            ? items.map(renderItem)
            : items.map((item) => {
                const filterValue = filterKey ? String(item[filterKey]) : String(item[displayKey]);
                const displayValue = String(item[displayKey]);
                const value = valueKey ? String(item[valueKey]) : filterValue;
                return (
                  <ComboboxListItem key={value} value={value}>
                    {displayValue}
                  </ComboboxListItem>
                );
              })}
        </ComboboxList>
      </Command>
    );

    if (context.asDialog) {
      return content;
    }

    return (
      <PopoverContent className='border-t-0 p-0' {...props} sideOffset={10} ref={ref}>
        {content}
      </PopoverContent>
    );
  },
  'ComboboxContent',
);

const ComboboxTrigger = forwardRef<
  ElementRef<typeof PopoverTrigger>,
  ComponentPropsWithoutRef<typeof PopoverTrigger> & ComponentPropsWithoutRef<typeof Button>
>(({ className, children, asChild, ...props }, ref) => {
  const Comp = asChild ? Slot : Button;

  return (
    <PopoverTrigger {...props} ref={ref} asChild>
      <Comp
        size={props.size || 'sm'}
        variant='soft'
        renderRight={<Icon name='chevron-down' />}
        className={cn(
          'flex w-full items-center justify-between bg-fill-strongest',
          'data-[state=open]:outline data-[state=open]:outline-1 data-[state=open]:outline-offset-4 data-[state=open]:outline-stroke-weak',
          className,
        )}
      >
        {children}
      </Comp>
    </PopoverTrigger>
  );
});
ComboboxTrigger.displayName = 'ComboboxTrigger';

const ComboboxInput = forwardRef<
  ElementRef<typeof CommandInput>,
  ComponentPropsWithoutRef<typeof CommandInput>
>(({ className, ...props }, ref) => {
  return (
    <CommandInput className={cn('border-x-0 !outline-none', className)} {...props} ref={ref} />
  );
});
ComboboxInput.displayName = 'ComboboxInput';

const ComboboxEmpty = forwardRef<
  ElementRef<typeof CommandEmpty>,
  ComponentPropsWithoutRef<typeof CommandEmpty>
>(({ className, ...props }, ref) => {
  return <CommandEmpty className={cn('h-12 px-4 py-3', className)} {...props} ref={ref} />;
});
ComboboxEmpty.displayName = 'ComboboxEmpty';

const ComboboxList = forwardRef<
  ElementRef<typeof CommandList>,
  ComponentPropsWithoutRef<typeof CommandList>
>((props, ref) => {
  return <CommandList {...props} ref={ref} />;
});
ComboboxList.displayName = 'ComboboxList';

const ComboboxListItem = forwardRef<
  ElementRef<typeof CommandItem>,
  ComponentPropsWithoutRef<typeof CommandItem>
>(({ onSelect, children, ...props }, ref) => {
  const context = useComboboxContext();

  return (
    <CommandItem
      ref={ref}
      onSelect={(value) => {
        onSelect?.(value);
        context.onSelect(value);
      }}
      {...props}
    >
      {children}
    </CommandItem>
  );
});
ComboboxListItem.displayName = 'ComboboxListItem';

const ComboboxGroup = forwardRef<
  ElementRef<typeof CommandGroup>,
  ComponentPropsWithoutRef<typeof CommandGroup>
>((props, ref) => {
  return <CommandGroup {...props} ref={ref} />;
});
ComboboxGroup.displayName = 'ComboboxGroup';

export {
  Combobox,
  ComboboxContent,
  ComboboxInput,
  ComboboxTrigger,
  ComboboxEmpty,
  ComboboxList,
  ComboboxListItem,
  ComboboxGroup,
};
