import type { ReactNode } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';

import { type ChangelogName, changelogNames } from '@legalfly/api/settings';
import { ALL_LANGUAGE_CODES, type AppLanguageCode } from '@legalfly/config/languages';
import { Button } from '@legalfly/ui/button';
import {
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@legalfly/ui/dialog';
import type { IconName } from '@legalfly/ui/icon';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { withToasts } from '@legalfly/ui/toast';
import { VisuallyHidden } from '@legalfly/ui/visuallyHidden';
import type { Router } from 'app/AppRouter';
import { useCurrentUserSettings, useUpdateCurrentUserSettings } from 'core/modules/settings';
import { userToasts } from 'core/modules/users/toasts';

import translationDe from './translation/translation-de.jpg';
import translationEn from './translation/translation-en.jpg';
import translationFr from './translation/translation-fr.jpg';
import translationNl from './translation/translation-nl.jpg';

interface Props {
  isOpen?: boolean;
  children?: ReactNode;
}

const changelogInfo: Record<
  ChangelogName,
  {
    images: Record<AppLanguageCode, string>;
    goto: Parameters<Router['navigate']>[0];
    icon: IconName;
  }
> = {
  translation: {
    images: {
      en: translationEn,
      fr: translationFr,
      de: translationDe,
      nl: translationNl,
    },
    goto: {
      to: '/translate/files',
    },
    icon: 'translate-1',
  },
};

export const ChangelogDialog = ({ children, isOpen: initialIsOpen }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(initialIsOpen);

  const navigate = useNavigate();
  const { data: userSettings } = useCurrentUserSettings();
  const { updateCurrentUserSettings } = useUpdateCurrentUserSettings();

  const [changelogName] = useState(
    userSettings.unviewedChangelogs[0] ?? changelogNames[changelogNames.length - 1],
  );
  const info = changelogInfo[changelogName];

  const handleChangelogViewed = async () => {
    if (userSettings.unviewedChangelogs.includes(changelogName)) {
      await withToasts(
        updateCurrentUserSettings({
          viewedChangelog: changelogName,
        }),
      )(userToasts.updateCurrentUserSettings());
    }
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      {children}
      <DialogContent className='max-w-[80%] lg:max-w-[40%]' preventClose={initialIsOpen}>
        <DialogHeader closeButtonEnabled={false}>
          <DialogTitle>{t('label.whatsNew')}</DialogTitle>
          <VisuallyHidden>
            <DialogDescription>{t('changelog.description')}</DialogDescription>
          </VisuallyHidden>
        </DialogHeader>
        <DialogBody>
          <img
            src={info.images[userSettings.language]}
            alt='background'
            className='inset-0 mb-6 h-full w-full object-cover'
          />
          <Text variant='bodyLargeSemiBold' className='mb-2'>
            {t(`changelog.${changelogName}.title`, {
              count: ALL_LANGUAGE_CODES.length,
            })}
          </Text>
          <Text variant='bodyLight' className='mb-6'>
            {t(`changelog.${changelogName}.subTitle`)}
          </Text>
        </DialogBody>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant='ghost' onClick={handleChangelogViewed}>
              {t('action.close')}
            </Button>
          </DialogClose>
          <Button
            variant='brand'
            onClick={() => {
              handleChangelogViewed();
              navigate(info.goto);
            }}
            renderLeft={<Icon name={info.icon} />}
          >
            {t(`changelog.${changelogName}.cta`)}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
