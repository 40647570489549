import { useForm } from '@tanstack/react-form';

import { FormItem } from '@legalfly/ui/form';
import { Icon } from '@legalfly/ui/icon';
import { Input } from '@legalfly/ui/input';

const defaultValues = {
  keyword: '',
};

interface Props {
  onKeywordChange: (keyword: string) => void;
  placeholder?: string;
  onBlur?: VoidFunction;
  disabled?: boolean;
}

export const SearchInputForm = ({
  onKeywordChange,
  onBlur,
  placeholder,
  disabled = false,
}: Props) => {
  const form = useForm({
    defaultValues,
  });

  return (
    <form
      className='flex flex-col gap-4'
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <FormItem>
        <form.Field name='keyword'>
          {(field) => (
            <Input
              id={field.name}
              renderLeft={<Icon name='search-md' size='xs' />}
              className='h-10 min-w-80 py-3'
              leftClassName='top-3'
              name={field.name}
              value={field.state.value}
              placeholder={placeholder}
              onBlur={() => {
                field.handleBlur();
                onBlur?.();
              }}
              onChange={(e) => {
                field.handleChange(e.target.value);
                onKeywordChange(e.target.value);
              }}
              disabled={disabled}
            />
          )}
        </form.Field>
      </FormItem>
    </form>
  );
};
