import { getEntries } from '@legalfly/utils/object';

export interface Language {
  code: string;
  name: string;
  appLanguage: boolean;
  textSource: boolean;
  textTarget: boolean;
  documentSource: boolean;
  documentTarget: boolean;
}

export const ALL_LANGUAGES = {
  ar: {
    code: 'ar',
    name: 'Arabic',
    appLanguage: false,
    textSource: false,
    textTarget: true,
    documentSource: false,
    documentTarget: true,
  },
  bg: {
    code: 'bg',
    name: 'Bulgarian',
    appLanguage: false,
    textSource: true,
    textTarget: true,
    documentSource: true,
    documentTarget: true,
  },
  cs: {
    code: 'cs',
    name: 'Czech',
    appLanguage: false,
    textSource: true,
    textTarget: true,
    documentSource: true,
    documentTarget: true,
  },
  da: {
    code: 'da',
    name: 'Danish',
    appLanguage: false,
    textSource: true,
    textTarget: true,
    documentSource: true,
    documentTarget: true,
  },
  de: {
    code: 'de',
    name: 'German',
    appLanguage: true,
    textSource: true,
    textTarget: true,
    documentSource: true,
    documentTarget: true,
  },
  el: {
    code: 'el',
    name: 'Greek',
    appLanguage: false,
    textSource: true,
    textTarget: true,
    documentSource: true,
    documentTarget: true,
  },
  en: {
    code: 'en',
    name: 'English',
    appLanguage: true,
    textSource: true,
    textTarget: false,
    documentSource: true,
    documentTarget: false,
  },
  'en-gb': {
    code: 'en-gb',
    name: 'British English',
    appLanguage: false,
    textSource: false,
    textTarget: true,
    documentSource: false,
    documentTarget: true,
  },
  'en-us': {
    code: 'en-us',
    name: 'American English',
    appLanguage: false,
    textSource: false,
    textTarget: true,
    documentSource: false,
    documentTarget: true,
  },
  es: {
    code: 'es',
    name: 'Spanish',
    appLanguage: false,
    textSource: true,
    textTarget: true,
    documentSource: true,
    documentTarget: true,
  },
  et: {
    code: 'et',
    name: 'Estonian',
    appLanguage: false,
    textSource: true,
    textTarget: true,
    documentSource: true,
    documentTarget: true,
  },
  fi: {
    code: 'fi',
    name: 'Finnish',
    appLanguage: false,
    textSource: true,
    textTarget: true,
    documentSource: true,
    documentTarget: true,
  },
  fr: {
    code: 'fr',
    name: 'French',
    appLanguage: true,
    textSource: true,
    textTarget: true,
    documentSource: true,
    documentTarget: true,
  },
  hu: {
    code: 'hu',
    name: 'Hungarian',
    appLanguage: false,
    textSource: true,
    textTarget: true,
    documentSource: true,
    documentTarget: true,
  },
  id: {
    code: 'id',
    name: 'Indonesian',
    appLanguage: false,
    textSource: true,
    textTarget: true,
    documentSource: true,
    documentTarget: true,
  },
  it: {
    code: 'it',
    name: 'Italian',
    appLanguage: false,
    textSource: true,
    textTarget: true,
    documentSource: true,
    documentTarget: true,
  },
  ja: {
    code: 'ja',
    name: 'Japanese',
    appLanguage: false,
    textSource: true,
    textTarget: true,
    documentSource: true,
    documentTarget: true,
  },
  ko: {
    code: 'ko',
    name: 'Korean',
    appLanguage: false,
    textSource: true,
    textTarget: true,
    documentSource: true,
    documentTarget: true,
  },
  lt: {
    code: 'lt',
    name: 'Lithuanian',
    appLanguage: false,
    textSource: true,
    textTarget: true,
    documentSource: true,
    documentTarget: true,
  },
  lv: {
    code: 'lv',
    name: 'Latvian',
    appLanguage: false,
    textSource: true,
    textTarget: true,
    documentSource: true,
    documentTarget: true,
  },
  nb: {
    code: 'nb',
    name: 'Norwegian Bokmål',
    appLanguage: false,
    textSource: true,
    textTarget: true,
    documentSource: true,
    documentTarget: true,
  },
  nl: {
    code: 'nl',
    name: 'Dutch',
    appLanguage: true,
    textSource: true,
    textTarget: true,
    documentSource: true,
    documentTarget: true,
  },
  pl: {
    code: 'pl',
    name: 'Polish',
    appLanguage: false,
    textSource: true,
    textTarget: true,
    documentSource: true,
    documentTarget: true,
  },
  pt: {
    code: 'pt',
    name: 'Portuguese',
    appLanguage: false,
    textSource: true,
    textTarget: false,
    documentSource: true,
    documentTarget: false,
  },
  'pt-br': {
    code: 'pt-br',
    name: 'Brazilian Portuguese',
    appLanguage: false,
    textSource: false,
    textTarget: true,
    documentSource: false,
    documentTarget: true,
  },
  'pt-pt': {
    code: 'pt-pt',
    name: 'European Portuguese',
    appLanguage: false,
    textSource: false,
    textTarget: true,
    documentSource: false,
    documentTarget: true,
  },
  ro: {
    code: 'ro',
    name: 'Romanian',
    appLanguage: false,
    textSource: true,
    textTarget: true,
    documentSource: true,
    documentTarget: true,
  },
  ru: {
    code: 'ru',
    name: 'Russian',
    appLanguage: false,
    textSource: true,
    textTarget: true,
    documentSource: true,
    documentTarget: true,
  },
  sk: {
    code: 'sk',
    name: 'Slovak',
    appLanguage: false,
    textSource: true,
    textTarget: true,
    documentSource: true,
    documentTarget: true,
  },
  sl: {
    code: 'sl',
    name: 'Slovenian',
    appLanguage: false,
    textSource: true,
    textTarget: true,
    documentSource: true,
    documentTarget: true,
  },
  sv: {
    code: 'sv',
    name: 'Swedish',
    appLanguage: false,
    textSource: true,
    textTarget: true,
    documentSource: true,
    documentTarget: true,
  },
  tr: {
    code: 'tr',
    name: 'Turkish',
    appLanguage: false,
    textSource: true,
    textTarget: true,
    documentSource: true,
    documentTarget: true,
  },
  uk: {
    code: 'uk',
    name: 'Ukrainian',
    appLanguage: false,
    textSource: true,
    textTarget: true,
    documentSource: true,
    documentTarget: true,
  },
  zh: {
    code: 'zh',
    name: 'Chinese',
    appLanguage: false,
    textSource: true,
    textTarget: true,
    documentSource: true,
    documentTarget: true,
  },
  'zh-hans': {
    code: 'zh-hans',
    name: 'Simplified Chinese',
    appLanguage: false,
    textSource: false,
    textTarget: true,
    documentSource: false,
    documentTarget: true,
  },
  'zh-hant': {
    code: 'zh-hant',
    name: 'Traditional Chinese',
    appLanguage: false,
    textSource: false,
    textTarget: true,
    documentSource: false,
    documentTarget: false,
  },
} as const satisfies Record<string, Language>;

export type LanguageCode = keyof typeof ALL_LANGUAGES;

function filterLanguagesByProperty<K extends keyof Language, T extends LanguageCode>(
  property: K,
): { [P in T]: (typeof ALL_LANGUAGES)[P][K] extends true ? P : never }[T][] {
  return getEntries(ALL_LANGUAGES)
    .filter(([_, lang]) => lang[property])
    .map(([code]) => code) as any;
}

export const ALL_LANGUAGE_CODES = getEntries(ALL_LANGUAGES).map(([code]) => code);

export const ALL_LANGUAGE_NAMES = Object.values(ALL_LANGUAGES).map((lang) => lang.name);
export type LanguageName = (typeof ALL_LANGUAGE_NAMES)[number];

export const APP_LANGUAGE_CODES = filterLanguagesByProperty<'appLanguage', LanguageCode>(
  'appLanguage',
);
export const TEXT_SOURCE_LANGUAGE_CODES = filterLanguagesByProperty<'textSource', LanguageCode>(
  'textSource',
);
export const TEXT_TARGET_LANGUAGE_CODES = filterLanguagesByProperty<'textTarget', LanguageCode>(
  'textTarget',
);
export const DOCUMENT_SOURCE_LANGUAGE_CODES = filterLanguagesByProperty<
  'documentSource',
  LanguageCode
>('documentSource');
export const DOCUMENT_TARGET_LANGUAGE_CODES = filterLanguagesByProperty<
  'documentTarget',
  LanguageCode
>('documentTarget');

export const APP_LANGUAGE_NAMES = Object.values(ALL_LANGUAGES)
  .filter((lang) => lang.appLanguage)
  .map((lang) => lang.name);

export const TEXT_SOURCE_LANGUAGE_NAMES = Object.values(ALL_LANGUAGES)
  .filter((lang) => lang.textSource)
  .map((lang) => lang.name);

export const TEXT_TARGET_LANGUAGE_NAMES = Object.values(ALL_LANGUAGES)
  .filter((lang) => lang.textTarget)
  .map((lang) => lang.name);

export const DOCUMENT_SOURCE_LANGUAGE_NAMES = Object.values(ALL_LANGUAGES)
  .filter((lang) => lang.documentSource)
  .map((lang) => lang.name);

export const DOCUMENT_TARGET_LANGUAGE_NAMES = Object.values(ALL_LANGUAGES)
  .filter((lang) => lang.documentTarget)
  .map((lang) => lang.name);

export const DEFAULT_LANGUAGE_CODE: LanguageCode = 'en';
export const DEFAULT_APP_LANGUAGE_CODE: AppLanguageCode = 'en';

export type TextSourceLanguageCode = (typeof TEXT_SOURCE_LANGUAGE_CODES)[number];
export type TextTargetLanguageCode = (typeof TEXT_TARGET_LANGUAGE_CODES)[number];
export type DocumentSourceLanguageCode = (typeof DOCUMENT_SOURCE_LANGUAGE_CODES)[number];
export type DocumentTargetLanguageCode = (typeof DOCUMENT_TARGET_LANGUAGE_CODES)[number];
export type AppLanguageCode = (typeof APP_LANGUAGE_CODES)[number];
export type AppLanguageName = (typeof APP_LANGUAGE_NAMES)[number];
export type AllLanguageName = (typeof ALL_LANGUAGE_NAMES)[number];
export type AllLanguageCode = (typeof ALL_LANGUAGE_CODES)[number];
export type TextSourceLanguageName = (typeof TEXT_SOURCE_LANGUAGE_NAMES)[number];
export type TextTargetLanguageName = (typeof TEXT_TARGET_LANGUAGE_NAMES)[number];
export type DocumentSourceLanguageName = (typeof DOCUMENT_SOURCE_LANGUAGE_NAMES)[number];
export type DocumentTargetLanguageName = (typeof DOCUMENT_TARGET_LANGUAGE_NAMES)[number];

export const supportedTranslateAgentLanguages = {
  text: {
    sourceLanguages: TEXT_SOURCE_LANGUAGE_CODES,
    targetLanguages: TEXT_TARGET_LANGUAGE_CODES,
  },
  document: {
    sourceLanguages: DOCUMENT_SOURCE_LANGUAGE_CODES,
    targetLanguages: DOCUMENT_TARGET_LANGUAGE_CODES,
  },
  supportedLanguages: APP_LANGUAGE_CODES,
} as const;

// Merge and deduplicate source languages from both text and document translations
export const supportedLanguagesList = supportedTranslateAgentLanguages.supportedLanguages;
