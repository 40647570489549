import type { BaseSorting } from '../core/types';

export enum PlaybookStatusEnum {
  PRIVATE = 'private',
  PUBLIC = 'public',
  SYSTEM = 'system',
}

export enum PlaybookItemTypeEnum {
  QUESTION = 'question',
  RISK_ASSESSMENT = 'risk_assessment',
  ANOMALY = 'anomaly',
  YES_NO = 'yes_no',
  MULTIPLE_CHOICE = 'multiple_choice',
}

export enum PlaybookItemRiskLevelEnum {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
  NEUTRAL = 'neutral',
}

export type PlaybookItemRiskLevel = `${PlaybookItemRiskLevelEnum}`;

export const PLAYBOOK_SORT_CONFIG = {
  createdAt: 'createdAt',
  name: 'name',
  status: 'status',
  owner: 'owner',
} as const;

export type PlaybookSortField = keyof typeof PLAYBOOK_SORT_CONFIG;
export type MappedPlaybookSortField = (typeof PLAYBOOK_SORT_CONFIG)[PlaybookSortField];

export const DEFAULT_PLAYBOOK_SORT: BaseSorting<PlaybookSortField> = {
  sortBy: 'createdAt',
  sortOrder: 'DESC',
} as const;

export type DefaultPlaybookSort = typeof DEFAULT_PLAYBOOK_SORT;
