type FontSize = [
  fontSize: string,
  configuration: Partial<{
    lineHeight: string;
    letterSpacing: string;
    fontWeight: string | number;
  }>,
];

const calculateSize = (size: string) => {
  // Use pixel values directly
  return `round(calc(var(--font-scale) * ${size}px), 1px)`;
};

export const fontSizes: Record<string, FontSize> = {
  display: [
    calculateSize('64'),
    {
      lineHeight: calculateSize('72'),
      fontWeight: '400',
    },
  ],
  title: [
    calculateSize('48'),
    {
      lineHeight: calculateSize('56'),
      fontWeight: '400',
    },
  ],
  heading2: [
    calculateSize('32'),
    {
      lineHeight: calculateSize('40'),
      fontWeight: '400',
    },
  ],

  heading: [
    calculateSize('24'),
    {
      lineHeight: calculateSize('32'),
      fontWeight: '400',
    },
  ],
  'heading-semibold': [
    calculateSize('24'),
    {
      lineHeight: calculateSize('32'),
      fontWeight: '600',
    },
  ],
  'heading-light': [
    calculateSize('24'),
    {
      lineHeight: calculateSize('32'),
      fontWeight: '300',
    },
  ],

  subheading: [
    calculateSize('18'),
    {
      lineHeight: calculateSize('28'),
      fontWeight: '400',
    },
  ],
  'subheading-semibold': [
    calculateSize('18'),
    {
      lineHeight: calculateSize('28'),
      fontWeight: '600',
    },
  ],
  'subheading-light': [
    calculateSize('18'),
    {
      lineHeight: calculateSize('28'),
      fontWeight: '300',
    },
  ],

  'body-large': [
    calculateSize('18'),
    {
      lineHeight: calculateSize('24'),
      fontWeight: '400',
    },
  ],
  'body-large-semibold': [
    calculateSize('18'),
    {
      lineHeight: calculateSize('24'),
      fontWeight: '600',
    },
  ],
  'body-large-light': [
    calculateSize('18'),
    {
      lineHeight: calculateSize('24'),
      fontWeight: '300',
    },
  ],

  'body-small': [
    calculateSize('14'),
    {
      lineHeight: calculateSize('18'),
      fontWeight: '400',
    },
  ],
  'body-small-light': [
    calculateSize('14'),
    {
      lineHeight: calculateSize('18'),
      fontWeight: '300',
    },
  ],
  'body-small-semibold': [
    calculateSize('14'),
    {
      lineHeight: calculateSize('18'),
      fontWeight: '600',
    },
  ],

  body: [
    calculateSize('16'),
    {
      lineHeight: calculateSize('20'),
      fontWeight: '400',
    },
  ],
  'body-semibold': [
    calculateSize('16'),
    {
      lineHeight: calculateSize('20'),
      fontWeight: '600',
    },
  ],
  'body-light': [
    calculateSize('16'),
    {
      lineHeight: calculateSize('20'),
      fontWeight: '300',
    },
  ],

  caption: [
    calculateSize('14'),
    {
      lineHeight: calculateSize('16'),
      fontWeight: '400',
    },
  ],
  'caption-semibold': [
    calculateSize('14'),
    {
      lineHeight: calculateSize('16'),
      fontWeight: '600',
    },
  ],
  'caption-light': [
    calculateSize('14'),
    {
      lineHeight: calculateSize('16'),
      fontWeight: '300',
    },
  ],
  'caption-small': [
    calculateSize('12'),
    {
      lineHeight: calculateSize('12'),
      fontWeight: '400',
    },
  ],
};
