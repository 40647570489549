import { useLayoutEffect, useRef, useState } from 'react';

interface Size {
  width: number;
  height: number;
}

interface Props {
  element: HTMLElement | null | undefined;
  onResize: (size: Size) => void;
}

function useResizeObserver({ element, onResize }: Props) {
  const [size, setSize] = useState<Size | undefined>(undefined);
  const onResizeRef = useRef<Props['onResize'] | undefined>(undefined);
  onResizeRef.current = onResize;

  useLayoutEffect(() => {
    if (element) {
      const initialSize = { width: element.offsetWidth, height: element.offsetHeight };
      setSize(initialSize);
      onResizeRef.current?.(initialSize);

      const resizeObserver = new ResizeObserver((entries) => {
        if (!Array.isArray(entries)) {
          return;
        }

        if (!entries.length) {
          return;
        }

        const entry = entries[0];
        let width: number;
        let height: number;

        if ('borderBoxSize' in entry) {
          const borderSizeEntry = entry['borderBoxSize'];
          const borderSize = Array.isArray(borderSizeEntry) ? borderSizeEntry[0] : borderSizeEntry;
          width = borderSize['inlineSize'];
          height = borderSize['blockSize'];
        } else {
          width = element.offsetWidth;
          height = element.offsetHeight;
        }

        const newSize = { width, height };
        setSize(newSize);
        onResizeRef.current?.(newSize);
      });

      resizeObserver.observe(element, { box: 'border-box' });

      return () => resizeObserver.unobserve(element);
    } else {
      setSize(undefined);
      return () => undefined;
    }
  }, [element]);

  return size;
}

export { useResizeObserver };
