import { legalFlyConfig } from '@legalfly/config';

import type { BodyOf, ParamsOf, QueryOf, ResponseOf } from '../client';
import { createClient } from '../client';
import type { Fetcher } from '../fetcher';

const api = legalFlyConfig.api.paths;

export const createKnowledgeApi = (fetcher: Fetcher) => {
  const client = createClient(fetcher);

  const getKnowledgeList = (query: QueryOf<'knowledge/get'> = {}) => {
    return client(api.knowledge.list, { method: 'GET', query });
  };

  const getKnowledge = ({ knowledgeUuid }: ParamsOf<'knowledge/:knowledgeUuid/get'>) => {
    return client(api.knowledge.detail, { method: 'GET', params: { knowledgeUuid } });
  };

  const deleteKnowledge = ({ knowledgeUuid }: ParamsOf<'knowledge/:knowledgeUuid/delete'>) => {
    return client(api.knowledge.delete, { method: 'DELETE', params: { knowledgeUuid } });
  };

  const createKnowledge = ({ body }: { body: BodyOf<'knowledge/post'> }) => {
    return client(api.knowledge.create, { method: 'POST', body });
  };

  const updateKnowledge = ({
    params,
    body,
  }: {
    params: ParamsOf<'knowledge/:knowledgeUuid/put'>;
    body: BodyOf<'knowledge/:knowledgeUuid/put'>;
  }) => {
    return client(api.knowledge.update, { method: 'PUT', params, body });
  };

  return {
    getKnowledgeList,
    getKnowledge,
    deleteKnowledge,
    createKnowledge,
    updateKnowledge,
  };
};

export type KnowledgeApi = ReturnType<typeof createKnowledgeApi>;
export type ApiKnowledge = ResponseOf<'knowledge/get'>[number];
export type ApiKnowledgeTypeDocument = Extract<ApiKnowledge, { type: 'document' }>;
export type ApiKnowledgeTypeClause = Extract<ApiKnowledge, { type: 'clause' }>;
