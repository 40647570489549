import { legalFlyConfig } from '@legalfly/config';

import type { BodyOf, ParamsOf, ResponseOf } from '../client';
import { createClient } from '../client';
import type { Fetcher } from '../fetcher/Fetcher';

export const createCollaborationApi = (fetcher: Fetcher) => {
  const client = createClient(fetcher);

  const api = legalFlyConfig.api.paths;

  const getPermissions = (params: ParamsOf<'collaboration/:entityType/:entityUuid/get'>) => {
    return client(api.collaboration.list, { method: 'GET', params });
  };

  const syncPermissions = ({
    entityType,
    entityUuid,
    body,
  }: ParamsOf<'collaboration/:entityType/:entityUuid/post'> & {
    body: BodyOf<'collaboration/:entityType/:entityUuid/post'>;
  }) => {
    return client(api.collaboration.sync, {
      method: 'POST',
      params: { entityType, entityUuid },
      body,
    });
  };

  return {
    getPermissions,
    syncPermissions,
  };
};

export type CollaborationApi = ReturnType<typeof createCollaborationApi>;
export type ApiPermissionEntityType =
  ParamsOf<'collaboration/:entityType/:entityUuid/get'>['entityType'];
export type ApiPermission = ResponseOf<'collaboration/:entityType/:entityUuid/get'>;
export type ApiPermissionType = keyof ApiPermission;
export type ApiTeamPermission = ApiPermission['teams'][number];
export type ApiUserPermission = ApiPermission['users'][number];
export type ApiOrganizationPermission = ApiPermission['organizations'][number];
export type ApiPermissionBody = BodyOf<'collaboration/:entityType/:entityUuid/post'>;
export type ApiPermissionBodyEntry = ApiPermissionBody[number];
